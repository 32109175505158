import { FC, useEffect, useState } from 'react';
import { TabPanelProps } from 'templates/template';

const TabPanel: FC<TabPanelProps> = (props) => {
	const {
		children, value, index, lazyLoad, ...other
	} = props;

	const [show, setShow] = useState(!lazyLoad);
	useEffect(() => { if (lazyLoad && value === index) setShow(lazyLoad); }, [lazyLoad, value, index, setShow]);

	return (
		<div
			role="tabpanel"
			hidden={ value !== index }
			style={{ width: '100%', flexGrow: 1 }}
			{ ...other }
		>
			{ show && children }
		</div>
	);
};

export { TabPanel };
