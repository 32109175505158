import { Box } from '@mui/material';
import { PublicCustomerFormular } from 'pages/customer/PublicCustomerFormular';

const PublicCustomerPage = () => (
	<Box style={{
		width: '100%', maxWidth: '1200px', margin: 'auto', padding: '1rem', paddingTop: '2rem',
	}}
	>
		<PublicCustomerFormular />
	</Box>
);

export { PublicCustomerPage };
