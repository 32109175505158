/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
// PACKAGES
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
// HOOKS
import { CancelException, useCancellablePromise } from 'hooks/useCancellablePromise';

const AWSAppSyncProvider = () => {
	const { cancellablePromise } = useCancellablePromise();
	const call = useCallback(
		async (query: string, variables?: object) => {
			const graphQlProcess = API.graphql(graphqlOperation(query, variables));
			if (graphQlProcess instanceof Promise) {
				const cPromise = cancellablePromise(
					graphQlProcess,
					(p: Promise<any>) => API.cancel(p, 'canceled request'),
				);
				return cPromise.catch((error: string) => {
					// If the error is because the request was cancelled we can confirm here.
					if (API.isCancel(error)) {
						// console.log(error.message); // "my message for cancellation"
						// handle user cancellation logic
						throw new CancelException('Canceled via API');
					}
					throw error;
				});
			}
			return graphQlProcess;
		},
		[cancellablePromise],
	);

	const clearSubItems = (item: any) => (_.isObject(item) ? _.mapValues(item, (i: any) => (_.has(i, 'items') ? i.items : i)) : item);
	const getData = (result: any) => result.data[Object.keys(result.data)[0]];

	const listItems = useCallback(
		async <T>(query: string, variables?: object, recursive?: boolean) => {
			const result = await call(query, variables);
			const data: T[] = _.map(getData(result)?.items, clearSubItems);
			if (recursive && getData(result)?.nextToken) {
				const newData: T[] = await listItems(query, { ...variables, nextToken: getData(result)?.nextToken }) ?? [];
				return [...data, ...newData];
			}
			return data;
		},
		[call],
	);

	const getItem = useCallback(async <T>(query: string, variables?: object) => {
		const result = await call(query, variables);
		const data: T = clearSubItems(getData(result));
		return data;
	}, [call]);

	return ({
		call,
		listItems,
		getItem,
		editItem: getItem,
	});
};

export { AWSAppSyncProvider };
