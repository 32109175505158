import { Amplify, Auth, I18n } from 'aws-amplify';
import { Authenticator, translations, useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from 'aws-exports';

import { CustomerDashboard } from 'pages/customer/CustomerDashboard';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import 'styles/style.scss';
import {
	Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { LayoutContainer } from 'templates/layout/LayoutContainer';
import { NotFound } from 'pages/NotFound';

import logo from 'images/logoStar.png';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { AdministrationPage } from 'pages/administration/AdministrationPage';
import _ from 'lodash';
import { Person, Settings } from '@mui/icons-material';
import { PharmaOfferRequestPage } from 'pages/log/microNutrientAnalysis/PharmaOfferRequestPage';
import { hasAccess } from 'helper/rightsManagement';

Amplify.configure(awsExports);

I18n.setLanguage('de');
I18n.putVocabularies(translations);
I18n.putVocabularies({
	de: {
		Submit: 'Zurücksetzen',
		Password: 'Passwort',
		Email: 'E-Mail Adresse',
		'Back to Sign In': 'Zurück zur Anmeldung',
		'No account? ': 'Noch kein Konto?',
		'Forgot Password?': 'Passwort vergessen?',
		'Reset Password': 'Passwort zurücksetzen',
		'Username/client id combination not found.': 'Deine E-Mail Adresse konnte nicht zugeordnet werden.',
		'Custom auth lambda trigger is not configured for the user pool.': 'Entschuldigung, es ist ein Fehler aufgetreten.',
		'Enter your email': 'E-Mail Adresse',
		'Send code': 'Code zusenden',
		'Resend code': 'Code erneut zusenden',
		'User does not exist.': 'Deine Anmeldeinformationen waren leider nicht korrekt.',
		'Incorrect username or password.': 'Deine Anmeldeinformationen waren leider nicht korrekt.',
		'Your passwords must match': 'Die Passwörter stimmen nicht überein.',
		'Reset your password': 'Passwort zurücksetzen',
		'User password cannot be reset in the current state.': 'Das Passwort kann im aktuellen Status nicht zurückgesetzt werden.',
		'Temporary password has expired and must be reset by an administrator.': 'Dein temporäres Passwort ist leider nicht mehr gültig.',
	},

});

const App = () => {
	// get the 'authenticated' status from useAuthenticator
	const { route, user } = useAuthenticator((context) => [context.route, context.user]);
	const navigate = useNavigate();
	const location = useLocation();

	const signOut = async () => {
		await Auth.signOut();
	};

	const isSettings = useMemo(() => _.includes(location?.pathname, '/settings'), [location?.pathname]);

	const additionalHeaderComponent = useMemo(() => (
		<>
			<Button
				startIcon={ <Person /> }
				variant={ isSettings ? 'outlined' : 'contained' }
				onClick={ () => navigate('/customer') }
			>
				Kunden

			</Button>
			<Button
				startIcon={ <Settings /> }
				variant={ isSettings ? 'contained' : 'outlined' }
				onClick={ () => navigate('/administration') }
				disabled={ !hasAccess(user?.attributes?.email, 'administration') }
			>
				Backoffice
			</Button>
		</>
	), [isSettings, user?.attributes?.email, navigate]);
	return (
		<>
			{ route === 'authenticated' && (
				<Routes>
					<Route path="/" element={ <LayoutContainer signOut={ signOut } additionalHeaderComponent={ additionalHeaderComponent } /> }>
						<Route
							index
							element={ <CustomerDashboard /> }
						/>
						<Route
							path="/customer/:id?/:logId?"
							element={ <CustomerDashboard /> }
						/>
						<Route
							path="/customer/:id?/:logId?/por/:pharmaOfferRequestId"
							element={ <PharmaOfferRequestPage /> }
						/>
						<Route
							path="/administration"
							element={ <AdministrationPage /> }
						/>
						<Route
							path="/"
							element={ <NotFound /> }
						/>
					</Route>
				</Routes>

			) }
			{ route !== 'authenticated' && (
				<div className="loginPage">
					<div>
						<div className="loginHeader">
							<img src={ logo } alt="logo" className="loginLogo" />
						</div>
						<Authenticator hideSignUp className="login" />
					</div>
				</div>
			) }
		</>

	);
};

export { App };
