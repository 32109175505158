const getInvoicePaymentOption = (value) => {
	switch (value) {
		case 'cash': return { label: 'Bar', value };
		case 'transfer': return { label: 'Überweisung', value };
		default: return null;
	}
};

const getCustomerTypeOption = (value) => {
	switch (value) {
		case 'dog': return { label: 'Hund', value };
		case 'horse': return { label: 'Pferd', value };
		case 'human': return { label: 'Mensch', value };
		default: return null;
	}
};

const getCountryOption = (value) => {
	switch (value) {
		case 'Österreich': return { label: 'Österreich', value: 'Österreich' };
		case 'Deutschland': return { label: 'Deutschland', value: 'Deutschland' };
		case 'Ungarn': return { label: 'Ungarn', value: 'Ungarn' };
		default: return null;
	}
};

/**
 *
 * @param {'kps' | 'plv' | 'tbl'} value
 * @returns {{label: string, value: 'kps' | 'plv' | 'tbl'}}
 */
const getProductTypeOption = (value) => {
	switch (value) {
		case 'kps': return { label: 'Kapseln', value };
		case 'plv': return { label: 'Pulver', value };
		case 'tbl': return { label: 'Tabletten', value };
		default: return null;
	}
};

export {
	getInvoicePaymentOption, getCustomerTypeOption, getCountryOption, getProductTypeOption,
};
