import _ from 'lodash';
import {
	Box, Button, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {
	ArrowRight, Close, Menu,
} from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { InvoiceFormular } from 'pages/administration/InvoiceFormular';
import { API, graphqlOperation } from 'aws-amplify';
import { useMessage } from 'hooks/useMessage';
import { Exceptions } from 'messages/Exceptions';
import { getInvoice, listInvoices } from 'graphql/customQueries';
import { formatDateTimeString } from 'helper/formatDate';

/** @type {import('types/invoice').IInvoice[]} */
const initialInvoices = [];

/** @type {import('types/invoice').IInvoice} */
const initialInvoice = {
	invoiceCustomerId: '',
	number: '',
	date: '',
	dueDate: '',
	positions: [],
};

/**
     *
     * @param {import('types/invoice').IInvoice} invoice
     * @returns {"disabled" | "error" | "success" | "warning" | "info" | "action" | "inherit" | "primary" | "secondary"}
     */
const invoiceStateColor = (invoice) => {
	if (!!invoice?.dueDate && new Date(invoice?.dueDate) < new Date() && !invoice?.paid) {
		return 'error';
	}
	if (!!invoice?.submittedAt && !invoice?.paid) {
		return 'warning';
	}
	if (invoice?.paid) {
		return 'success';
	}
	return 'inherit';
};

const InvoicesAdministrationFormular = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [invoices, setInvoices] = useState(initialInvoices);
	const [currentInvoice, setCurrentInvoice] = useState(initialInvoice);
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueMessage } = useMessage();

	const iOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		/**
         * @param {String} [nextToken]
         * @param {Partial<import('types/invoice').IInvoice>[]} [prevResponse]
         */
		const load = async (nextToken, prevResponse) => {
			try {
				const ret = await API.graphql(graphqlOperation(listInvoices, { nextToken }));
				if (ret.data.listInvoices.nextToken) {
					load(ret.data.listInvoices.nextToken, [...(prevResponse ?? []), ...ret.data.listInvoices.items]);
					return;
				}
				// set the products in order
				setInvoices(_.orderBy([...(prevResponse ?? []), ...ret.data.listInvoices.items], ['number'], ['desc']));
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				enqueueMessage('InvoicesAdministrationFormular', Exceptions.API_LOAD_ERROR);
			}
		};
		load();
	}, [listInvoices, enqueueMessage, setInvoices]);

	// eslint-disable-next-line function-paren-newline
	const loadInvoice = useCallback(
		/**
         * @param {import('types/invoice').IInvoice} invoice
         */
		async (invoice) => {
			if (currentInvoice.id === invoice.id) return;
			const loadedInvoice = _.find(invoices, (inv) => inv.id === invoice.id);
			if (loadedInvoice.positions) {
				setCurrentInvoice(loadedInvoice);
				return;
			}
			setIsLoading(true);
			try {
				const ret = await API.graphql(graphqlOperation(getInvoice, { id: invoice.id, number: invoice.number }));
				setCurrentInvoice(ret.data.getInvoice);
				setInvoices((current) => _.map(current, (i) => (i.id === invoice.id ? ret.data.getInvoice : i)));
			} catch (err) {
				enqueueMessage(`Invoice_${invoice.id}`, Exceptions.API_LOAD_ERROR);
				setCurrentInvoice(initialInvoice);
			} finally {
				setIsLoading(false);
			}
		}, [currentInvoice.id, invoices, getInvoice, initialInvoice, Exceptions.API_LOAD_ERROR, setCurrentInvoice, setIsLoading]);

	const invoiceList = (
		<List
			dense
			style={{ minWidth: '350px',
				width: isMobile ? '100%' : '350px',
				maxHeight: isDesktop ? 'calc(100vH - 15rem)' : 'inerhit',
				overflow: 'scroll',
			}}
		>
			{ invoices.map((invoice) => (
				<ListItem key={ invoice.number } disablePadding>
					<ListItemButton
						onClick={ () => { loadInvoice(invoice); setDrawerOpen(false); } }
						sx={{
							color: `${invoiceStateColor(invoice)}.main`,
							backgroundColor: (invoice.number === currentInvoice.number) ? 'primary.main' : 'unset',
						}}
					>
						<ListItemText primary={ `${invoice.number} ${formatDateTimeString(invoice.date, 'dd.MM.yyyy')}` } />
						<ListItemIcon>
							<ArrowRight />
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
			)) }
		</List>
	);

	return (
		<Box>
			{ (!isDesktop) && (
				<Button
					onClick={ () => setDrawerOpen(true) }
					style={{ marginBottom: '2rem' }}
					startIcon={ <Menu color="primary" /> }
				>
					Liste öffnen
				</Button>
			) }
			<Box style={{
				minHeight: '50vH', display: 'flex',
			}}
			>

				{ (!isDesktop && drawerOpen) && (
					<SwipeableDrawer
						anchor="left"
						open={ drawerOpen }
						onClose={ () => setDrawerOpen(false) }
						onOpen={ () => setDrawerOpen(true) }
						disableBackdropTransition={ !iOS }
						disableDiscovery={ iOS }
						PaperProps={{ sx: { width: isMobile ? '100%' : 'inerhit' } }}
					>
						<Box style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
							<Button onClick={ () => setDrawerOpen(false) } startIcon={ <Close /> }>Liste schließen</Button>
							{ invoiceList }
						</Box>
					</SwipeableDrawer>
				) }
				{ isDesktop && invoiceList }
				<Box style={{
					flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '2rem', paddingLeft: isDesktop ? '2rem' : '0rem',
				}}
				>
					{ isLoading && <CircularProgress /> }
					{ (!isLoading && currentInvoice === initialInvoice) && (
						<Typography component="h2">Bitte eine Rechnung aus der Liste auswählen</Typography>
					) }
					{ (!isLoading && currentInvoice !== initialInvoice)
                && <InvoiceFormular currentInvoice={ currentInvoice } /> }
				</Box>
			</Box>
		</Box>
	);
};

export { InvoicesAdministrationFormular };
