import React, {
	useState, useEffect, useMemo, useCallback, FC,
} from 'react';
import {
	Alert,
	Autocomplete,
	Button, FormControlLabel, Grid, Switch, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers';
import { Add, Edit, PictureAsPdf, Send } from '@mui/icons-material';
import { randomId } from '@mui/x-data-grid-generator';
import {
	DataGrid, GridActionsCellItem, GridColDef, GridRowEditStopParams, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, MuiBaseEvent, MuiEvent,
} from '@mui/x-data-grid';

import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import moment from 'moment';

import {
	createInvoice, deleteInvoice, submitInvoice, updateInvoice,
} from 'graphql/customMutations';
import { getInvoice } from 'graphql/customQueries';

import { formatDateTimeString } from 'helper/formatDate';
import { downloadBase64File } from 'helper/download';
import { getInvoicePaymentOption } from 'helper/typeHelper';
import { useMessage } from 'hooks/useMessage';
import { Exceptions } from 'messages/Exceptions';
import { Messages } from 'messages/Messages';

import { IInvoice, InvoiceFormularProps, InvoiceListItem } from 'types/invoice';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';

const EditToolbar: React.FC<{
	setRows: React.Dispatch<React.SetStateAction<readonly (InvoiceListItem & {id: string; isNew: boolean})[]>>,
	setRowModesModel: React.Dispatch<React.SetStateAction<object>>,
	isEditable: boolean
}> = (props) => {
	const {
		setRows, setRowModesModel, isEditable,
	} = props;

	const handleClick = (template: InvoiceListItem, autoSave?: boolean) => {
		const id = randomId();
		setRows((oldRows) => [
			...oldRows, {
				id,
				order: _.size(oldRows) + 1,
				isNew: true,
				...template,
			}]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: autoSave ? GridRowModes.View : GridRowModes.Edit, fieldToFocus: 'supplementId' },
		}));
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={ <Add /> } onClick={ () => handleClick({ description: '', price: 0, quantity: 1, unit: 'pauschal' }) } disabled={ !isEditable }>
				Position hinzufügen
			</Button>
			<Button
				color="primary"
				startIcon={ <Add /> }
				onClick={ () => handleClick({
					description: 'Haaranalyse', price: 50, quantity: 1, unit: 'pauschal',
				}, true) }
				disabled={ !isEditable }
			>
				Haaranalyse hinzufügen
			</Button>
		</GridToolbarContainer>
	);
};

const InvoiceFormular: FC<InvoiceFormularProps> = ({
	currentLog, customer, saveLog, disabled,
}) => {
	const initialInvoice = {
		invoiceCustomerId: customer.id ?? '',
		date: moment(new Date()).toISOString(),
		dueDate: moment(new Date()).add(1, 'month').toISOString(),
		positions: [],
	};

	const [initialized, setInitialized] = useState(false);
	const [invoice, setInvoice] = useState<IInvoice>(initialInvoice);
	const [invoiceBackup, setInvoiceBackup] = useState<IInvoice>(initialInvoice);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [rows, setRows] = useState<GridRowsProp<InvoiceListItem & {id: string; isNew: boolean}>>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

	const { enqueueMessage } = useMessage();
	const { getItem, editItem } = AWSAppSyncProvider();

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	const isNew = useMemo(() => !currentLog?.logInvoiceId, [currentLog?.logInvoiceId]);
	const positionOpen = useMemo(
		() => _.some(rowModesModel, (r) => r?.mode === GridRowModes.Edit),
		[rowModesModel],
	);
	const isEditable = useMemo(() => (!invoice?.submittedAt), [invoice?.submittedAt]);
	const customerInformationProvided = useMemo(() => (customer?.name || customer?.nameOwner) && customer?.email
    && customer?.address && customer?.zip && customer?.city && customer?.country && customer?.consent, [customer]);

	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			try {
				const nextStateInvoice: IInvoice = await getItem(getInvoice, { id: currentLog.logInvoiceId, number: currentLog.logInvoiceNumber });
				setInvoice(nextStateInvoice);
				setInvoiceBackup(nextStateInvoice);
				setInitialized(false);
				setIsLoading(false);
			} catch (err) {
				enqueueMessage(`Invoice_${currentLog.logInvoiceId}`, Exceptions.API_LOAD_ERROR);
				setInvoice(initialInvoice);
				setInvoiceBackup(initialInvoice);
			}
		};
		if (!isNew && !isLoading && currentLog?.logInvoiceId && currentLog?.logInvoiceId !== invoice?.id) {
			load();
		}
		if (!currentLog?.logInvoiceId && invoice?.id) {
			setInvoice(initialInvoice);
			setInvoiceBackup(initialInvoice);
			setInitialized(false);
		}
	}, [invoice?.id, currentLog?.logInvoiceId, initialInvoice, isNew, setInvoice, setInvoiceBackup, setIsLoading, enqueueMessage, Exceptions.API_LOAD_ERROR]);

	const handleChange = useCallback((data: Partial<IInvoice>) => {
		setInvoice((current) => ({
			...current,
			...data,
		}));
	}, [setInvoice]);

	const handleRowEditStop = (params: GridRowEditStopParams<any>, event: MuiEvent<MuiBaseEvent>) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			// eslint-disable-next-line no-param-reassign
			event.defaultMuiPrevented = true;
		}
	};
	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel((current) => ({ ...current, [id]: { mode: GridRowModes.View } }));
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows((current) => current.filter((row) => row.id !== id));
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow?.isNew) {
			setRows((current) => current.filter((row) => row.id !== id));
		}
	};
	const processRowUpdate = React.useCallback((newRow: InvoiceListItem & {id: string; isNew: boolean}) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows((current) => current.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	}, []);

	const downloadInvoice = useCallback(() => {
		downloadBase64File(invoice.pdf, `${invoice.number}_TDTH.pdf`);
	}, [invoice?.pdf, downloadBase64File]);

	const submitInvoiceFunction = useCallback(async () => {
		if (!invoice.id || !invoice.number) {
			return;
		}
		setIsSaving(true);
		try {
			const nextStateInvoice: IInvoice = await editItem(submitInvoice, { input: { id: invoice.id, number: invoice.number } });
			const { submittedAt, pdf } = nextStateInvoice;
			if (!submittedAt) {
				throw new Error('Invoice was possibly not submitted');
			}
			handleChange({ submittedAt, pdf });
			enqueueMessage(`Invoice_${invoice.id}`, Messages.INVOICE_SUBMITTED_SUCCESSFULLY);
		} catch (err) {
			enqueueMessage(`Invoice_${invoice.id}`, Exceptions.GENERAL);
		} finally {
			setIsSaving(false);
		}
	}, [invoice, submitInvoice, Messages.INVOICE_SUBMITTED_SUCCESSFULLY, Exceptions.GENERAL, handleChange, enqueueMessage]);

	const removeInvoice = useCallback(async () => {
		if (!invoice.id) {
			return;
		}
		setIsSaving(true);
		try {
			await API.graphql(graphqlOperation(deleteInvoice, { input: { id: invoice.id, number: invoice.number } }));
			enqueueMessage(`Invoice_${invoice.id}`, Messages.API_DELETE_SUCCESSFUL);
			setRows([]);
			setRowModesModel({});
			setInvoice(initialInvoice);
			setInvoiceBackup(initialInvoice);
		} catch (err) {
			enqueueMessage(`Invoice_${invoice.id}`, Exceptions.API_DELETE_ERROR);
		} finally {
			setIsSaving(false);
		}
	}, [invoice, deleteInvoice, Messages.API_DELETE_SUCCESSFUL, Exceptions.API_DELETE_ERROR, setInvoice, setInvoiceBackup, handleChange, enqueueMessage]);

	const handleSave = useCallback(async () => {
		if (isSaving) {
			return;
		}

		let prepareInvoice: Partial<IInvoice> = _.mapValues(_.pick(invoice, 'id', 'invoiceCustomerId', 'number', 'date', 'dueDate', 'submittedAt', 'bottomNotice', 'pdf'), (v) => {
			if (v) return v;
			return undefined;
		});
		prepareInvoice.paid = invoice.paid ?? false;
		prepareInvoice.paymentMethod = invoice.paymentMethod ?? 'transfer';
		prepareInvoice.positions = invoice.positions || undefined;

		if (!currentLog?.customerLogsId) {
			enqueueMessage(`InvoiceFormular${currentLog?.id}`, Exceptions.API_SAVE_ERROR);
			return;
		}

		if (!prepareInvoice.invoiceCustomerId) {
			prepareInvoice.invoiceCustomerId = currentLog?.customerLogsId;
		}

		if (!invoice.id) {
			prepareInvoice = _.omit(prepareInvoice, 'id');
		}

		setIsSaving(true);
		try {
			const nextStateInvoice: IInvoice = await editItem(invoice.id ? updateInvoice : createInvoice, { input: prepareInvoice });
			setInvoice(nextStateInvoice);
			saveLog({
				...currentLog,
				logInvoiceId: nextStateInvoice.id,
				logInvoiceNumber: nextStateInvoice.number,
			});
			enqueueMessage(`InvoiceFormular${prepareInvoice.id}`, Messages.API_SAVE_SUCCESSFUL);
		} catch (err) {
			enqueueMessage(`InvoiceFormular${prepareInvoice.id}`, Exceptions.API_SAVE_ERROR);
		} finally {
			setIsSaving(false);
		}
	}, [isSaving, invoice, currentLog?.customerLogsId, createInvoice, updateInvoice, Messages.API_SAVE_SUCCESSFUL, Exceptions.API_SAVE_ERROR, enqueueMessage, setInvoice, setIsSaving]);

	const initialize = useCallback(() => {
		setRows(_.map(invoice?.positions, (r, i) => ({
			...r,
			id: `${i}-${Math.floor(Math.random() * 1000)}`,
			order: i + 1,
			isNew: false,
		})));
		setInitialized(true);
	}, [invoice?.positions, setRows]);

	useEffect(() => {
		if (initialized) return;
		initialize();
	}, [initialized, initialize]);

	useEffect(() => {
		if (isEditable) {
			if (rows.length && customerInformationProvided) {
				/** @type {import('../../types/invoice').InvoiceListItem[]} */
				const positions = _.map(rows, (row) => ({
					quantity: row.quantity ?? 1,
					description: row.description ?? '-',
					price: row.price ?? 0,
					unit: row.unit,
				}));
				if (!_.isEqual(positions, invoice?.positions)) {
					// console.log(positions);
					handleChange(({ positions }));
				}
			} else {
				handleChange({ positions: [] });
			}
		}
	}, [isEditable, rows, customer, customerInformationProvided, handleChange]);

	// const customerInformationProvided = useMemo(() => (customer?.email || customer?.phone) && customer?.address && customer?.weight, [customer]);

	const columns: GridColDef[] = [
		{
			field: 'order',
			headerName: 'Pos.',
			width: 50,
			type: 'number',
		},
		{
			field: 'description',
			headerName: 'Leistungsbeschreibung',
			width: 300,
			editable: isEditable,
			type: 'string',
		},
		{
			field: 'price',
			headerName: 'Preis/Einheit',
			width: 90,
			editable: isEditable,
			type: 'number',
		},
		{
			field: 'quantity',
			headerName: 'Anzahl',
			width: 90,
			editable: isEditable,
			type: 'number',
		},
		{
			field: 'unit',
			headerName: 'Einheit',
			width: 120,
			align: 'center',
			headerAlign: 'center',
			editable: isEditable,
			type: 'singleSelect',
			valueOptions: ['h', 'stk', 'pauschal'],
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			width: 100,
			getActions: ({ id }: {id: GridRowId}) => {
				if (!isEditable) {
					return [];
				}
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							key={ id }
							icon={ <SaveIcon /> }
							label="Speichern"
							sx={{
								color: 'primary.main',
							}}
							onClick={ handleSaveClick(id) }
						/>,
						<GridActionsCellItem
							key={ id }
							icon={ <CancelIcon /> }
							label="Abbrechen"
							className="textPrimary"
							onClick={ handleCancelClick(id) }
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						key={ id }
						icon={ <Edit /> }
						label="Edit"
						className="textPrimary"
						onClick={ handleEditClick(id) }
						color="inherit"
					/>,
					<GridActionsCellItem
						key={ id }
						icon={ <DeleteIcon /> }
						label="Delete"
						onClick={ handleDeleteClick(id) }
						color="inherit"
					/>,
				];
			},
		},
	];

	return (
		<>
			{ (!customerInformationProvided) && (
				<Alert severity="warning">
					<Typography variant="h5">Es fehlen wesentliche Kundeninformationen</Typography>
					<ul>
						{ !(customer?.name || customer?.nameOwner) && <li>Name</li> }
						{ !(customer?.email) && <li>E-Mail Adresse</li> }
						{ !customer?.address && <li>Adresse</li> }
						{ !customer?.zip && <li>Postleitzahl</li> }
						{ !customer?.city && <li>Ort</li> }
						{ !customer?.country && <li>Land</li> }
						{ !customer?.consent && <li>Einwilligung</li> }
					</ul>
				</Alert>
			) }
			{ (customerInformationProvided) && (
				<Grid container spacing={ 2 }>
					<Grid item xs={ 12 } sm={ 6 }>
						<TextField
							variant="standard"
							value={ invoice?.number ?? '' }
							// onChange={(event) => handleChange((current) => ({...current, number: event.target.value}))}
							disabled
							helperText="Rechnungsnummer (wird automatisch vergeben)"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 }>
						<TextField
							helperText="Rechnung gesendet am"
							variant="standard"
							type="datetime-local"
							value={ _.replace(formatDateTimeString(invoice?.submittedAt, 'yyyy-MM-dd#HH:mm'), '#', 'T') ?? '' }
							disabled
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 }>
						<DatePicker
							// label="Rechnungsdatum *"
							value={ invoice?.date ? moment(invoice?.date) : null }
							onChange={ (date) => {
								if (date === null) {
									handleChange({ date: undefined });
									return;
								}
								if (date.isValid() && !_.isNaN(date.milliseconds())) {
									handleChange({ date: date.toISOString() });
									return;
								}
								handleChange({ date: undefined });
							} }
							slotProps={{ actionBar: { actions: ['accept', 'today', 'clear'] }, textField: { variant: 'standard', helperText: 'Rechnungsdatum *', fullWidth: true } }}
							disabled={ !isEditable || isSaving }
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 }>
						<DatePicker
							// label="Zahlungsziel *"
							value={ invoice?.dueDate ? moment(invoice?.dueDate) : null }
							onChange={ (date) => {
								if (date === null) {
									handleChange({ dueDate: undefined });
									return;
								}
								if (date.isValid() && !_.isNaN(date.milliseconds())) {
									handleChange({ dueDate: date.toISOString() });
									return;
								}
								handleChange({ dueDate: undefined });
							} }
							slotProps={{ actionBar: { actions: ['accept', 'today', 'clear'] }, textField: { variant: 'standard', helperText: 'Zahlungsziel *', fullWidth: true } }}
							disabled={ !isEditable || isSaving }
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 }>
						<Autocomplete
							disablePortal
							options={ [
								getInvoicePaymentOption('transfer'),
								getInvoicePaymentOption('cash'),
							] }
							value={ getInvoicePaymentOption(invoice?.paymentMethod) }
							renderInput={ (params) => (
								<TextField
									{ ...params }
									label="Zahlungsart"
									variant="standard"
								/>
							) }
							isOptionEqualToValue={ (option, value) => value?.value === invoice?.paymentMethod }
							onChange={ (event, selected) => handleChange({ paymentMethod: selected?.value ?? 'transfer' }) }
							style={{ maxWidth: 'unset' }}
							disabled={ !isEditable || isSaving }
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 }>
						<FormControlLabel
							control={ (
								<Switch
									disabled={ isSaving || disabled || !invoice?.number || !invoice?.submittedAt }
									checked={ invoice?.paid ?? false }
									onChange={ (e, checked) => handleChange({ paid: checked }) }
								/>
							) }
							label="Bezahlt"
						/>
					</Grid>
					<Grid item xs={ 12 } width="100%">
						<DataGrid
							rows={ rows }
							columns={ columns }
							editMode="row"
							rowModesModel={ rowModesModel }
							onRowModesModelChange={ setRowModesModel }
							onRowEditStop={ handleRowEditStop }
							processRowUpdate={ processRowUpdate }
							slots={{
								toolbar: EditToolbar,
							}}
							slotProps={{
								toolbar: {
									setRows, setRowModesModel, isEditable: isEditable && !disabled, customer,
								},
							}}
							hideFooter
							autoHeight
							style={{ maxWidth: isDesktop ? 'calc(100vw - 350px - 6rem)' : 'calc(100vw - 4rem)' }}
						/>
					</Grid>
					<Grid item xs={ 12 }>
						<TextField
							helperText="Notiz auf der Rechnung"
							variant="standard"
							value={ invoice?.bottomNotice ?? '' }
							onChange={ (event) => handleChange({ bottomNotice: event.target.value }) }
							multiline
							fullWidth
							disabled={ !isEditable || isSaving }
						/>
					</Grid>
					<Grid item xs={ 12 } md={ 6 } lg={ 4 }>
						<Button
							variant="contained"
							startIcon={ <SaveIcon /> }
							onClick={ handleSave }
							disabled={ disabled || !customerInformationProvided || isSaving || positionOpen || !invoice?.date || _.isEqual(invoice, invoiceBackup) }
							fullWidth
						>
							Speichern
						</Button>
					</Grid>
					<Grid item xs={ 12 } md={ 6 } lg={ 4 }>
						<Button
							variant="outlined"
							startIcon={ <CancelIcon /> }
							onClick={ () => {
								setInvoice(invoiceBackup);
								setInitialized(false);
							} }
							disabled={ isNew || disabled || !customerInformationProvided || isSaving || _.isEqual(invoice, invoiceBackup) || !invoice?.id }
							fullWidth
						>
							Zurücksetzen
						</Button>
					</Grid>
					<Grid item xs={ 12 } md={ 6 } lg={ 4 }>
						<Button
							variant="outlined"
							startIcon={ <DeleteIcon /> }
							onClick={ removeInvoice }
							disabled={ isNew || disabled || !!invoice?.submittedAt || !invoice?.id }
							fullWidth
						>
							Löschen
						</Button>
					</Grid>
					<Grid item xs={ 12 } md={ 6 }>
						<Button
							variant="contained"
							disabled={ !invoice?.pdf }
							onClick={ downloadInvoice }
							startIcon={ <PictureAsPdf /> }
							fullWidth
						>
							herunterladen
						</Button>
					</Grid>
					<Grid item xs={ 12 } md={ 6 }>
						<Button
							variant="contained"
							disabled={ !customerInformationProvided || isSaving || !invoice?.id }
							onClick={ submitInvoiceFunction }
							startIcon={ <Send /> }
							color="info"
							fullWidth
						>
							{ !!invoice?.submittedAt && <>erneut senden</> }
							{ !invoice?.submittedAt && <>senden</> }
						</Button>
					</Grid>
				</Grid>
			) }
		</>
	);
};

export { InvoiceFormular };
