import {
	Grid, TextField, Autocomplete, FormControlLabel, Checkbox, Alert, Button, Box, Typography,
} from '@mui/material';
import { Storage } from 'aws-amplify';
import { DatePicker } from '@mui/x-date-pickers';
import { Avatar } from 'components/Avatar';
import _ from 'lodash';
import moment from 'moment';
import { getCountryOption, getCustomerTypeOption } from 'helper/typeHelper';
import { v4 as uuid } from 'uuid';

import styles from 'styles/customer.module.scss';
import { Divider } from 'components/Divider/Divider';
import { getCustomerNameLabel } from 'pages/customer/CustomerDashboard';

/**
 * @param {object} props - props for the component
 * @param {import('types/customer').ICustomer} props.currentCustomer - the current customer
 * @param {(object)=>void} props.handleChangeCustomer - the current customer
 * @param {Partial<Record<
 * "name"|"type"|"weight"|"birthdate"|"nameOwner"|"address"|"zip"|"city"|"country"|
 * "phone"|"email"|"vaccination"|"disease"|"treatment"|"notice",string>>} [props.labelOverrides] - the current customer
* @param {boolean} [props.askConsent] - the current customer
* @param {boolean} [props.disabled] -disable the formular
 * @returns {React.ReactComponentElement} component to be shown
 */
const CustomerFormular = ({
	currentCustomer, handleChangeCustomer, labelOverrides, askConsent, disabled,
}) => {
	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			// get the file extension
			const fileExtensionRegEx = /(?:\.([^.]+))?$/;
			// put the file in the storage bucket
			const res = await Storage.put(`${uuid()}.${fileExtensionRegEx.exec(file.name)[1]}`, file, { level: 'private' });
			// update the customer record
			handleChangeCustomer({
				avatar: res.key,
			});
		}
	};

	return (
		<>
			<Box style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
				<Box className={ styles.customerAvatar }>
					<Avatar
						customer={ currentCustomer }
						handleFileUpload={ handleFileUpload }
						disabled={ disabled }
					/>
				</Box>
				<Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<Typography fontWeight="bold" fontSize="1.6rem" marginBottom="0.5rem">{ getCustomerNameLabel(currentCustomer) }</Typography>
					<Typography>
						{ `${getCustomerTypeOption(currentCustomer?.type)?.label ?? 'unbekannter Typ'}, ` }
						{ currentCustomer?.weight ? `${currentCustomer?.weight} kg` : 'kein Gewicht angegeben' }
					</Typography>
					<Typography>{ currentCustomer?.vaccination ?? 'keine Impfungen bekannt' }</Typography>
					<Typography>{ currentCustomer?.disease ?? 'keine Vorerkrankungen bekannt' }</Typography>
				</Box>
			</Box>
			<Divider />
			<Grid container spacing={ 2 }>
				<Grid item xs={ 12 } md={ 5 }>
					<TextField
						variant="standard"
						label={ labelOverrides?.name ?? 'Name (Anwendungsempfänger) *' }
						value={ currentCustomer?.name ?? '' }
						onChange={ (event) => handleChangeCustomer({ name: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 6 } md={ 3 }>
					<Autocomplete
						disablePortal
						options={ [
							getCustomerTypeOption('dog'),
							getCustomerTypeOption('horse'),
							getCustomerTypeOption('human'),
						] }
						value={ getCustomerTypeOption(currentCustomer?.type) }
						renderInput={ (params) => (
							<TextField
								{ ...params }
								label={ labelOverrides?.type ?? 'Anwendungsempfänger' }
								variant="standard"
							/>
						) }
						isOptionEqualToValue={ ({ value }) => value === currentCustomer?.type }
						onChange={ (event, selected) => handleChangeCustomer({ type: selected?.value ?? 'human' }) }
						style={{ maxWidth: 'unset' }}
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 6 } md={ 1 }>
					<TextField
						label={ labelOverrides?.weight ?? 'Gewicht (kg)' }
						variant="standard"
						type="number"
						value={ currentCustomer?.weight ?? 0 }
						onChange={ (event) => handleChangeCustomer({ weight: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } md={ 3 }>
					<DatePicker
						label={ labelOverrides?.birthdate ?? 'Geburtsdatum' }
						value={ currentCustomer?.birthdate ? moment(currentCustomer?.birthdate) : null }
						slotProps={{ actionBar: { actions: ['clear'] }, textField: { variant: 'standard', fullWidth: true, style: { width: '100%' } } }}
						onChange={ (date) => {
							if (date === null) {
								handleChangeCustomer({ birthdate: null });
								return;
							}
							if (date.isValid() && !_.isNaN(date.milliseconds())) {
								handleChangeCustomer({ birthdate: date.toISOString() });
								return;
							}
							handleChangeCustomer({ birthdate: undefined });
						} }
						disabled={ disabled }
					/>
				</Grid>
				{ currentCustomer?.type !== 'human' && (
					<Grid item xs={ 12 } sm={ 8 } md={ 12 }>
						<TextField
							label={ labelOverrides?.nameOwner ?? 'Name (Besitzer)' }
							variant="standard"
							value={ currentCustomer?.nameOwner ?? '' }
							onChange={ (event) => handleChangeCustomer({ nameOwner: event.target.value }) }
							fullWidth
							disabled={ disabled }
						/>
					</Grid>
				) }
				<Grid item xs={ 12 } md={ 4 } lg={ 6 }>
					<TextField
						label={ labelOverrides?.address ?? 'Anschrift' }
						variant="standard"
						multiline
						value={ currentCustomer?.address ?? '' }
						onChange={ (event) => handleChangeCustomer({ address: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 4 } md={ 2 }>
					<TextField
						label={ labelOverrides?.zip ?? 'Postleitzahl' }
						variant="standard"
						value={ currentCustomer?.zip ?? '' }
						onChange={ (event) => handleChangeCustomer({ zip: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 8 } md={ 3 } lg={ 2 }>
					<TextField
						label={ labelOverrides?.city ?? 'Stadt' }
						variant="standard"
						value={ currentCustomer?.city ?? '' }
						onChange={ (event) => handleChangeCustomer({ city: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } md={ 3 } lg={ 2 }>
					<Autocomplete
						disablePortal
						options={ [
							getCountryOption('Österreich'),
							getCountryOption('Deutschland'),
							getCountryOption('Ungarn'),
						] }
						value={ getCountryOption(currentCustomer?.country) }
						renderInput={ (params) => (
							<TextField
								{ ...params }
								label={ labelOverrides?.country ?? 'Land' }
								variant="standard"
							/>
						) }
						isOptionEqualToValue={ ({ value }) => value === currentCustomer?.country }
						onChange={ (event, selected) => handleChangeCustomer({ country: selected?.value ?? 'Österreich' }) }
						style={{ maxWidth: 'unset' }}
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label={ labelOverrides?.phone ?? 'Telefonnummer' }
						variant="standard"
						value={ currentCustomer?.phone ?? '' }
						onChange={ (event) => handleChangeCustomer({ phone: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label={ labelOverrides?.email ?? 'E-Mail Adresse' }
						variant="standard"
						value={ currentCustomer?.email ?? '' }
						onChange={ (event) => handleChangeCustomer({ email: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label={ labelOverrides?.vaccination ?? 'Impfungen' }
						variant="standard"
						multiline
						value={ currentCustomer?.vaccination ?? '' }
						onChange={ (event) => handleChangeCustomer({ vaccination: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label={ labelOverrides?.disease ?? 'Erkrankungen' }
						variant="standard"
						multiline
						value={ currentCustomer?.disease ?? '' }
						onChange={ (event) => handleChangeCustomer({ disease: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label={ labelOverrides?.treatment ?? 'Derzeitige Anwendungen / Behandlungen' }
						variant="standard"
						multiline
						value={ currentCustomer?.treatment ?? '' }
						onChange={ (event) => handleChangeCustomer({ treatment: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label={ labelOverrides?.notice ?? 'Sonstige Notizen' }
						variant="standard"
						multiline
						value={ currentCustomer?.notice ?? '' }
						onChange={ (event) => handleChangeCustomer({ notice: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				{ askConsent && (
					<Grid item xs={ 12 }>
						<FormControlLabel
							control={ (
								<Checkbox
									checked={ currentCustomer?.consent ?? false }
									onChange={ (event) => handleChangeCustomer({ consent: event.target.checked }) }
									disabled={ disabled }
								/>
							) }
							label={
								'Ich akzeptiere, dass meine Daten elektronisch verarbeitet werden und meine Informationen '
                            + 'zum Zweck der Analyse und Anwendung an Dritte weitergegeben werden dürfen.'
                            + 'Zudem bin ich mit der Zusendung einer elektronischen Rechnung einverstanden!'
							}
						/>
					</Grid>
				) }
				{ (!askConsent && currentCustomer?.id) && (
					<Grid item xs={ 12 }>
						{ currentCustomer?.consent
						&& <Alert severity="success">Der Kunde hat akzeptiert, dass seine Daten elektronisch verarbeitet werden und seine Informationen zum Zweck der Analyse und Anwendung an Dritte weitergegeben werden dürfen.</Alert> }
						{ !currentCustomer?.consent && (
							<Alert severity="warning" action={ <Button disabled={ disabled } onClick={ () => handleChangeCustomer({ consent: true }) }>Zustimung erteilen</Button> }>
								Der Kunde hat NICHT akzeptiert, dass seine Daten elektronisch verarbeitet werden und seine Informationen zum Zweck der
								Analyse und Anwendung an Dritte weitergegeben werden dürfen. Ggf. das Anamneseformular erneut schicken! Bei manueller Zustimmung muss gespeichert werden!
							</Alert>
						) }
					</Grid>
				) }
			</Grid>
		</>
	);
};

export { CustomerFormular };
