import format from 'date-fns/format';
import { deAT } from 'date-fns/locale';

const formatDateTimeString = (dateString, formatString = 'dd.MM.yyyy HH:mm') => {
	const value = Date.parse(dateString);
	if (!Number.isNaN(value)) {
		return format(value, formatString, { locale: deAT });
	}
	return dateString;
};

const formatDate = (date) => formatDateTimeString(date, 'yyyy-MM-dd');

export { formatDate, formatDateTimeString };
