import React, { createRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { Storage } from 'aws-amplify';

import MUIAvatar from '@mui/material/Avatar';

import styles from 'styles/avatar.module.scss';

const Avatar = (props) => {
	const { customer, handleFileUpload } = props;
	const [avatar, setAvatar] = useState('');

	Storage.configure({ track: true, level: 'private' });

	const fileInput = createRef();

	const openFileDialog = () => {
		fileInput.current.click();
	};

	// downloads the avatar from S3 and sets it as the avatar
	useEffect(() => {
		const getAvatar = async () => {
			// download the avatar from S3
			const response = await Storage.get(customer?.avatar);
			// set the avatar
			setAvatar(response);
		};

		if (!_.isNil(customer?.avatar)) {
			// if the customer has an avatar
			getAvatar();
		} else {
			// if the customer does not have an avatar
			setAvatar('');
		}
	}, [customer?.avatar]);

	return (
		<>
			<input
				className={ styles.fileInput }
				accept="image/*"
				id="raised-button-file"
				type="file"
				ref={ fileInput }
				onChange={ handleFileUpload }
				disabled={ props?.disabled }
			/>
			<MUIAvatar
				className={ styles.avatarButton }
				variant="square"
				component="button"
				alt={ customer?.name ?? 'Avatar' }
				src={ avatar }
				onClick={ openFileDialog }
			/>
		</>
	);
};

export { Avatar };
