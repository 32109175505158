/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitAnamnesis = /* GraphQL */ `
  mutation SubmitAnamnesis($input: SubmitAnamnesisInput!) {
    submitAnamnesis(input: $input)
  }
`;
export const submitCustomerInformation = /* GraphQL */ `
  mutation SubmitCustomerInformation($input: SubmitCustomerInformationInput!) {
    submitCustomerInformation(input: $input)
  }
`;
export const submitInvoice = /* GraphQL */ `
  mutation SubmitInvoice($input: SubmitInvoiceInput!) {
    submitInvoice(input: $input) {
      id
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      number
      date
      dueDate
      submittedAt
      positions {
        quantity
        description
        price
        unit
        __typename
      }
      bottomNotice
      pdf
      createdAt
      updatedAt
      invoiceCustomerId
      __typename
    }
  }
`;
export const submitMicroNutrientAnalysis = /* GraphQL */ `
  mutation SubmitMicroNutrientAnalysis(
    $input: SubmitMicroNutrientAnalysisInput!
  ) {
    submitMicroNutrientAnalysis(input: $input)
  }
`;
export const submitMicroNutrientAnalysisAnamnesisLink = /* GraphQL */ `
  mutation SubmitMicroNutrientAnalysisAnamnesisLink($input: LinkInput!) {
    submitMicroNutrientAnalysisAnamnesisLink(input: $input)
  }
`;
export const submitCustomerInformationLink = /* GraphQL */ `
  mutation SubmitCustomerInformationLink($input: LinkInput!) {
    submitCustomerInformationLink(input: $input)
  }
`;
export const customCreateInvoice = /* GraphQL */ `
  mutation CustomCreateInvoice($input: CustomCreateInvoiceInput!) {
    customCreateInvoice(input: $input) {
      id
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      number
      date
      dueDate
      submittedAt
      positions {
        quantity
        description
        price
        unit
        __typename
      }
      bottomNotice
      pdf
      createdAt
      updatedAt
      invoiceCustomerId
      __typename
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      settings
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      settings
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      settings
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      nameOwner
      type
      address
      zip
      city
      country
      phone
      email
      birthdate
      weight
      vaccination
      disease
      treatment
      notice
      avatar
      logs {
        nextToken
        __typename
      }
      consent
      approvalEnlightenmentHumanApprovedAt
      approvalEnlightenmentHumanText
      approvalEnlightenmentHumanSignature
      approvalEnlightenmentAnimalApprovedAt
      approvalEnlightenmentAnimalText
      approvalEnlightenmentAnimalSignature
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      nameOwner
      type
      address
      zip
      city
      country
      phone
      email
      birthdate
      weight
      vaccination
      disease
      treatment
      notice
      avatar
      logs {
        nextToken
        __typename
      }
      consent
      approvalEnlightenmentHumanApprovedAt
      approvalEnlightenmentHumanText
      approvalEnlightenmentHumanSignature
      approvalEnlightenmentAnimalApprovedAt
      approvalEnlightenmentAnimalText
      approvalEnlightenmentAnimalSignature
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      nameOwner
      type
      address
      zip
      city
      country
      phone
      email
      birthdate
      weight
      vaccination
      disease
      treatment
      notice
      avatar
      logs {
        nextToken
        __typename
      }
      consent
      approvalEnlightenmentHumanApprovedAt
      approvalEnlightenmentHumanText
      approvalEnlightenmentHumanSignature
      approvalEnlightenmentAnimalApprovedAt
      approvalEnlightenmentAnimalText
      approvalEnlightenmentAnimalSignature
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      number
      date
      dueDate
      submittedAt
      positions {
        quantity
        description
        price
        unit
        __typename
      }
      bottomNotice
      pdf
      createdAt
      updatedAt
      invoiceCustomerId
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      number
      date
      dueDate
      submittedAt
      positions {
        quantity
        description
        price
        unit
        __typename
      }
      bottomNotice
      pdf
      createdAt
      updatedAt
      invoiceCustomerId
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      number
      date
      dueDate
      submittedAt
      positions {
        quantity
        description
        price
        unit
        __typename
      }
      bottomNotice
      pdf
      createdAt
      updatedAt
      invoiceCustomerId
      __typename
    }
  }
`;
export const createSupplementProduct = /* GraphQL */ `
  mutation CreateSupplementProduct(
    $input: CreateSupplementProductInput!
    $condition: ModelSupplementProductConditionInput
  ) {
    createSupplementProduct(input: $input, condition: $condition) {
      pcn
      type
      quantity
      mgCore
      notice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSupplementProduct = /* GraphQL */ `
  mutation UpdateSupplementProduct(
    $input: UpdateSupplementProductInput!
    $condition: ModelSupplementProductConditionInput
  ) {
    updateSupplementProduct(input: $input, condition: $condition) {
      pcn
      type
      quantity
      mgCore
      notice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSupplementProduct = /* GraphQL */ `
  mutation DeleteSupplementProduct(
    $input: DeleteSupplementProductInput!
    $condition: ModelSupplementProductConditionInput
  ) {
    deleteSupplementProduct(input: $input, condition: $condition) {
      pcn
      type
      quantity
      mgCore
      notice
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupplement = /* GraphQL */ `
  mutation CreateSupplement(
    $input: CreateSupplementInput!
    $condition: ModelSupplementConditionInput
  ) {
    createSupplement(input: $input, condition: $condition) {
      id
      names
      dosages {
        base
        mgDayKg100
        pcn
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSupplement = /* GraphQL */ `
  mutation UpdateSupplement(
    $input: UpdateSupplementInput!
    $condition: ModelSupplementConditionInput
  ) {
    updateSupplement(input: $input, condition: $condition) {
      id
      names
      dosages {
        base
        mgDayKg100
        pcn
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSupplement = /* GraphQL */ `
  mutation DeleteSupplement(
    $input: DeleteSupplementInput!
    $condition: ModelSupplementConditionInput
  ) {
    deleteSupplement(input: $input, condition: $condition) {
      id
      names
      dosages {
        base
        mgDayKg100
        pcn
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPharmOrderListItem = /* GraphQL */ `
  mutation CreatePharmOrderListItem(
    $input: CreatePharmOrderListItemInput!
    $condition: ModelPharmOrderListItemConditionInput
  ) {
    createPharmOrderListItem(input: $input, condition: $condition) {
      supplement {
        id
        names
        createdAt
        updatedAt
        __typename
      }
      base
      requirement
      pharmOrder {
        intakeDays
        minimumRequirement
        dateOfOrder
        dateOfSubmission
        submission
        weight
        notes
        id
        createdAt
        updatedAt
        pharmOrderLogId
        __typename
      }
      id
      createdAt
      updatedAt
      pharmOrderListId
      pharmOrderListItemSupplementId
      __typename
    }
  }
`;
export const updatePharmOrderListItem = /* GraphQL */ `
  mutation UpdatePharmOrderListItem(
    $input: UpdatePharmOrderListItemInput!
    $condition: ModelPharmOrderListItemConditionInput
  ) {
    updatePharmOrderListItem(input: $input, condition: $condition) {
      supplement {
        id
        names
        createdAt
        updatedAt
        __typename
      }
      base
      requirement
      pharmOrder {
        intakeDays
        minimumRequirement
        dateOfOrder
        dateOfSubmission
        submission
        weight
        notes
        id
        createdAt
        updatedAt
        pharmOrderLogId
        __typename
      }
      id
      createdAt
      updatedAt
      pharmOrderListId
      pharmOrderListItemSupplementId
      __typename
    }
  }
`;
export const deletePharmOrderListItem = /* GraphQL */ `
  mutation DeletePharmOrderListItem(
    $input: DeletePharmOrderListItemInput!
    $condition: ModelPharmOrderListItemConditionInput
  ) {
    deletePharmOrderListItem(input: $input, condition: $condition) {
      supplement {
        id
        names
        createdAt
        updatedAt
        __typename
      }
      base
      requirement
      pharmOrder {
        intakeDays
        minimumRequirement
        dateOfOrder
        dateOfSubmission
        submission
        weight
        notes
        id
        createdAt
        updatedAt
        pharmOrderLogId
        __typename
      }
      id
      createdAt
      updatedAt
      pharmOrderListId
      pharmOrderListItemSupplementId
      __typename
    }
  }
`;
export const createPharmOrder = /* GraphQL */ `
  mutation CreatePharmOrder(
    $input: CreatePharmOrderInput!
    $condition: ModelPharmOrderConditionInput
  ) {
    createPharmOrder(input: $input, condition: $condition) {
      intakeDays
      minimumRequirement
      dateOfOrder
      dateOfSubmission
      submission
      list {
        nextToken
        __typename
      }
      weight
      notes
      log {
        id
        subject
        date
        notice
        anamnesis
        images
        createdAt
        updatedAt
        customerLogsId
        logInvoiceId
        logInvoiceNumber
        logPharmOrderId
        __typename
      }
      id
      createdAt
      updatedAt
      pharmOrderLogId
      __typename
    }
  }
`;
export const updatePharmOrder = /* GraphQL */ `
  mutation UpdatePharmOrder(
    $input: UpdatePharmOrderInput!
    $condition: ModelPharmOrderConditionInput
  ) {
    updatePharmOrder(input: $input, condition: $condition) {
      intakeDays
      minimumRequirement
      dateOfOrder
      dateOfSubmission
      submission
      list {
        nextToken
        __typename
      }
      weight
      notes
      log {
        id
        subject
        date
        notice
        anamnesis
        images
        createdAt
        updatedAt
        customerLogsId
        logInvoiceId
        logInvoiceNumber
        logPharmOrderId
        __typename
      }
      id
      createdAt
      updatedAt
      pharmOrderLogId
      __typename
    }
  }
`;
export const deletePharmOrder = /* GraphQL */ `
  mutation DeletePharmOrder(
    $input: DeletePharmOrderInput!
    $condition: ModelPharmOrderConditionInput
  ) {
    deletePharmOrder(input: $input, condition: $condition) {
      intakeDays
      minimumRequirement
      dateOfOrder
      dateOfSubmission
      submission
      list {
        nextToken
        __typename
      }
      weight
      notes
      log {
        id
        subject
        date
        notice
        anamnesis
        images
        createdAt
        updatedAt
        customerLogsId
        logInvoiceId
        logInvoiceNumber
        logPharmOrderId
        __typename
      }
      id
      createdAt
      updatedAt
      pharmOrderLogId
      __typename
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      subject
      date
      notice
      anamnesis
      images
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      invoice {
        id
        number
        date
        dueDate
        submittedAt
        bottomNotice
        pdf
        createdAt
        updatedAt
        invoiceCustomerId
        __typename
      }
      pharmOrder {
        intakeDays
        minimumRequirement
        dateOfOrder
        dateOfSubmission
        submission
        weight
        notes
        id
        createdAt
        updatedAt
        pharmOrderLogId
        __typename
      }
      createdAt
      updatedAt
      customerLogsId
      logInvoiceId
      logInvoiceNumber
      logPharmOrderId
      __typename
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      subject
      date
      notice
      anamnesis
      images
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      invoice {
        id
        number
        date
        dueDate
        submittedAt
        bottomNotice
        pdf
        createdAt
        updatedAt
        invoiceCustomerId
        __typename
      }
      pharmOrder {
        intakeDays
        minimumRequirement
        dateOfOrder
        dateOfSubmission
        submission
        weight
        notes
        id
        createdAt
        updatedAt
        pharmOrderLogId
        __typename
      }
      createdAt
      updatedAt
      customerLogsId
      logInvoiceId
      logInvoiceNumber
      logPharmOrderId
      __typename
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      subject
      date
      notice
      anamnesis
      images
      customer {
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
        birthdate
        weight
        vaccination
        disease
        treatment
        notice
        avatar
        consent
        approvalEnlightenmentHumanApprovedAt
        approvalEnlightenmentHumanText
        approvalEnlightenmentHumanSignature
        approvalEnlightenmentAnimalApprovedAt
        approvalEnlightenmentAnimalText
        approvalEnlightenmentAnimalSignature
        createdAt
        updatedAt
        __typename
      }
      invoice {
        id
        number
        date
        dueDate
        submittedAt
        bottomNotice
        pdf
        createdAt
        updatedAt
        invoiceCustomerId
        __typename
      }
      pharmOrder {
        intakeDays
        minimumRequirement
        dateOfOrder
        dateOfSubmission
        submission
        weight
        notes
        id
        createdAt
        updatedAt
        pharmOrderLogId
        __typename
      }
      createdAt
      updatedAt
      customerLogsId
      logInvoiceId
      logInvoiceNumber
      logPharmOrderId
      __typename
    }
  }
`;
