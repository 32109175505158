/* eslint-disable max-len */
import {
	Box, ImageList, ImageListItem,
} from '@mui/material';
import logoEqology from 'images/eqology/eqology_logo.jpg';
import pureArticOilLemon from 'images/eqology/pureArticOilLemon.jpg';
import pureArticOilGold from 'images/eqology/pureArticOilGold.jpg';
import marineCollagenPremium from 'images/eqology/marineCollagenPremium.jpg';
import { Link } from 'react-router-dom';

/**
 * @returns {React.ReactComponentElement} component to be shown
 */
const EqologyPage = () => (
	<>
		<Box component="img" src={ logoEqology } height="40px" />
		<ImageList cols={ 3 }>
			<ImageListItem><Link to="https://eqology.com/pH6U6a" target="_blank"><img height="250px" src={ pureArticOilLemon } alt="Eqology Pure Arctic Oil – Omega-3" /></Link></ImageListItem>
			<ImageListItem><Link to="https://eqology.com/pt3RKl" target="_blank"><img height="250px" src={ pureArticOilGold } alt="Eqology Pure Arctic Oil Gold" /></Link></ImageListItem>
			<ImageListItem><Link to="https://eqology.com/p2sDNs" target="_blank"><img height="250px" src={ marineCollagenPremium } alt="Eqology Marine Collagen Premium+" /></Link></ImageListItem>
		</ImageList>
	</>
);

export { EqologyPage };
