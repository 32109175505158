import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Storage } from 'aws-amplify';

import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from 'styles/imageList.module.scss';
import { Box, ImageList } from '@mui/material';

const StandardImageList = (props) => {
	const {
		currentLog, handleDeleteFile,
		isEditable,
	} = props;
	const [fetchedImages, setFetchedImages] = useState([]);

	Storage.configure({ track: true, level: 'private' });

	useEffect(() => {
		const fetchImages = async () => {
			const images = await Promise.all(
				_.map(currentLog.images, async (key) => {
					const url = await Storage.get(key);
					return { url, key };
				}),
			);
			setFetchedImages(images);
		};
		if (!_.isNil(currentLog?.images)) {
			fetchImages();
		} else {
			setFetchedImages([]);
		}
	}, [currentLog?.images]);

	return (
		<Box>
			<ImageList
				// className={styles.imageList}
				rowHeight={ 164 }
				variant="masonry"
				cols={ 3 }
				gap={ 8 }
			>
				{
					fetchedImages.map((image) => (
						<ImageListItem
							key={ image.key }
						>
							<img
								// className={styles.avatar}
								// component="button"
								src={ image.url }
								// srcSet={image.url}
								alt={ image.key }
								// onClick={(e) => handleClick(e)}
								// onKeyDown={(e) => handleClick(e)}
								// variant="square"
								// style={{maxWidth: '250px', maxHeight: '250px'}}
								loading="lazy"
							/>
							{ isEditable && (
								<ImageListItemBar
									className={ styles.imageListItemBar }
									position="top"
									actionIcon={ (
										<IconButton
											className={ styles.iconButton }
										>
											<DeleteIcon fontSize="medium" />
										</IconButton>
									) }
									onClick={ () => handleDeleteFile(image.key) }
								/>
							) }
						</ImageListItem>
					))
				}
			</ImageList>
		</Box>
	);
};

export { StandardImageList };
