import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SnackbarMessage } from 'types/message';

/**
 * The hook to show/queue a snackbar.
 */
const useMessage = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const enqueueMessage = useCallback(
		(messageKey: string, message: SnackbarMessage) => {
			if (!message) {
				throw new Error('Snackbar: No message was provided');
			}
			// if (!messageKey) {
			//     throw new Error('Snackbar: No messageKey was provided');
			// }
			const mKey = messageKey ?? 'defaultMsgKey';

			enqueueSnackbar(<span id={ mKey ?? 'GENERAL' }>{ message?.message.de }</span>, {
				autoHideDuration: 2000,
				...message.options,
				// add a unique id to the snackbar to prevent duplicate messages errors
				key: `${mKey}####${uuidv4()}`,
				action: (key) => (
					<IconButton
						size="large"
						ria-label="Close"
						color="inherit"
						data-test={ `${mKey}_close` }
						onClick={ () => closeSnackbar(key) }
					>
						<Close />
					</IconButton>
				),
			});
		},
		[enqueueSnackbar],

	);

	return {
		enqueueMessage,
	};
};
export { useMessage };
