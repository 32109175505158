import {
	Box, TextField, Autocomplete, Grid, Button, FormControlLabel, Switch,
} from '@mui/material';

import { FC, useEffect, useState } from 'react';
import { getProductTypeOption } from 'helper/typeHelper';
import { Cancel, OpenInBrowser, Save } from '@mui/icons-material';
import { ISupplementProduct, ProductFormularProps } from 'types/microNutrientAnalysis';

const getHerbaPointURL = (pcn: string) => {
	if (!pcn) return undefined;
	let pcnClean = pcn.toLocaleLowerCase();
	const isDE = pcnClean.includes('/de');
	if (isDE) pcnClean = pcnClean.replace('/de', '');
	return `https://herba-point.at/einkauf/artikelsuche-und-bestellung?searchterm=${pcnClean}&country=${isDE ? 'DE' : 'AT'}`;
};

const ProductFormular: FC<ProductFormularProps> = ({
	currentProduct, handleSaveProduct, disabled,
}) => {
	const [product, setProduct] = useState<ISupplementProduct>();

	const _handleChange = (newValue: Partial<ISupplementProduct>) => {
		setProduct((current) => ({ ...currentProduct, ...current, ...newValue }));
	};

	const handleSave = () => {
		if (product) {
			handleSaveProduct(product);
		}
	};

	useEffect(() => {
		setProduct(currentProduct);
	}, [currentProduct, setProduct]);

	return (
		<Box style={{
			flexGrow: 1, display: 'flex', width: '100%', flexDirection: 'column', gap: '2rem',
		}}
		>
			<Grid container spacing={ 2 }>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label="PZN*"
						variant="standard"
						value={ product?.pcn ?? '' }
						onChange={ (event) => _handleChange({ pcn: event.target.value }) }
						fullWidth
						disabled={ disabled || currentProduct.pcn !== '' }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<Autocomplete
						disablePortal
						options={ [
							getProductTypeOption('kps'),
							getProductTypeOption('plv'),
							getProductTypeOption('tbl'),
						] }
						value={ product?.type ? getProductTypeOption(product?.type) : null }
						renderInput={ (params) => (
							<TextField
								{ ...params }
								label="Typ*"
								variant="standard"
							/>
						) }
						isOptionEqualToValue={ ({ value }) => value === product?.type }
						onChange={ (event, selected) => _handleChange({ type: selected?.value ?? 'kps' }) }
						style={{ maxWidth: 'unset' }}
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label="Inhalt* (gesamte Packung in g oder Stk.)"
						variant="standard"
						type="number"
						value={ product?.quantity ? product?.quantity : '' }
						onChange={ (event) => _handleChange({ quantity: Number(event.target.value) }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label="Kerninhalt in mg* (des Wirkstoffs pro Packung)"
						variant="standard"
						type="number"
						inputProps={{ step: 0.01 }}
						value={ product?.mgCore ? product?.mgCore : '' }
						onChange={ (event) => _handleChange({ mgCore: Number(event.target.value) }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<TextField
						label="Notizen (z.B. Wirkstoff)"
						variant="standard"
						value={ product?.notice ?? '' }
						onChange={ (event) => _handleChange({ notice: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 4 }>
					<TextField
						label="VP pro Verpackungseinheit"
						variant="standard"
						type="number"
						inputProps={{ step: 0.01 }}
						value={ product?.recommendedPrice ?? 0.0 }
						onChange={ (event) => _handleChange({ recommendedPrice: Number(event.target.value) }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 3 }>
					<FormControlLabel control={ <Switch disabled={ disabled } checked={ product?.active ?? false } onChange={ (e, checked) => _handleChange({ active: checked }) } /> } label="Aktiv" />
				</Grid>
				<Grid item xs={ 12 } sm={ 5 }>
					<Button
						startIcon={ <OpenInBrowser /> }
						onClick={ () => {
							if (!product?.pcn) return;
							window.open(getHerbaPointURL(product.pcn), '_blank');
						} }
						disabled={ !product?.pcn }
					>
						Herba-Point

					</Button>
				</Grid>
				<Grid item xs={ 12 }>
					<Button variant="contained" onClick={ handleSave } startIcon={ <Save /> } disabled={ disabled } fullWidth>Speichern</Button>
				</Grid>
				<Grid item xs={ 12 }>
					<Button
						variant="contained"
						onClick={ () => setProduct(currentProduct) }
						startIcon={ <Cancel /> }
						disabled={ product === currentProduct }
						fullWidth
					>
						Zurücksetzen
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export { ProductFormular };
