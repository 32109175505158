import { OptionsObject } from 'notistack';
import { SnackbarMessages } from 'types/message';

const options: OptionsObject = {
	variant: 'error',
};

const Exceptions: SnackbarMessages = {
	GENERAL: {
		message: {
			de: 'OOPS! Es ist ein Fehler aufgetreten :(',
		},
		options,
	},
	MISSING_INFORMATION: {
		message: {
			de: 'Leider sind nicht alle Informationen zum Speichern verfügbar!',
		},
		options,
	},
	DUPLICATE_ENTRY: {
		message: {
			de: 'Sie versuchen ein Duplikat anzulegen!',
		},
		options,
	},
	API_LOAD_ERROR: {
		message: {
			de: 'Die Daten konnten nicht geladen werden :(',
		},
		options,
	},
	API_SAVE_ERROR: {
		message: {
			de: 'Der Eintrag konnte nicht gespeichert werden :(',
		},
		options,
	},
	API_DELETE_NOT_POSSIBLE: {
		message: {
			de: 'Der Eintrag kann nicht gelöscht werden :(',
		},
		options,
	},
	API_DELETE_ERROR: {
		message: {
			de: 'Der Eintrag konnte nicht gelöscht werden :(',
		},
		options,
	},
};

export { Exceptions };
