import download from 'downloadjs';

// const urlToFile = (url, filename) => fetch(url)
//     .then((res) => res.arrayBuffer())
//     .then((buf) => new File([buf], filename));

const downloadBase64File = (fileData, fileName) => {
	// download(fileData, fileName, 'application/pdf');
	const fileUrl = `data:application/pdf;base64,${fileData}`;
	download(fileUrl, fileName, 'application/pdf');

	// urlToFile(fileUrl, fileName).then((file) => {
	//     const blob = new Blob([file], {type: 'application/pdf'});
	//     const blobURL = window.URL.createObjectURL(blob);
	//     const link = document.createElement('a');
	//     link.href = blobURL;
	//     link.download = fileName;
	//     link.setAttribute('download', fileName);
	//     document.body.appendChild(link);
	//     link.click();
	//     link.remove();
	// });
};

// /**
//  *
//  * @param {string} contentType The content type of your file. its like application/pdf or application/msword or image/jpeg or image/png and so on
//  * @param {string} base64Data Its your actual base64 data
//  * @param {string} fileName Its the file name of the file which will be downloaded.
//  */
// function downloadBase64File(contentType, base64Data, fileName) {
//     const linkSource = `data:${contentType};base64,${base64Data}`;
//     const downloadLink = document.createElement('a');
//     downloadLink.href = linkSource;
//     downloadLink.download = fileName;
//     downloadLink.click();
// }

export { downloadBase64File };
