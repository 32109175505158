import {
	Box, Grid, TextField, Autocomplete, Chip, Tabs, Tab, CircularProgress, IconButton, Button, FormControlLabel, Switch,
} from '@mui/material';

import {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { TabPanel } from 'templates/components/Tabs';
import { listSupplementProducts } from 'graphql/customQueries';
import { useMessage } from 'hooks/useMessage';
import { API, graphqlOperation } from 'aws-amplify';
import { Exceptions } from 'messages/Exceptions';
import {
	Cancel, Delete, Refresh, Save,
} from '@mui/icons-material';

/** @type {import('types/microNutrientAnalysis').ISupplement} */
const initialSupplement = {
	id: '', names: [], noProductAvailable: true, dosages: [],
};

/** @type {import('types/microNutrientAnalysis').ISupplementProduct[]} */
const initialProducts = [];

/**
 * @param {object} props - props for the component
 * @param {import('types/microNutrientAnalysis').ISupplementDosage} props.dosage the current dosage
 * @param {boolean} props.disabled indicates, the form is disabled
 * @param {import('types/microNutrientAnalysis').ISupplementProduct[]} props.products all available products
 * @param {boolean} props.loading indicates a loading or saving action
 * @param {()=>Promise<void>} props.loadProducts function for reload the products
 * @param {(newValue: Partial<import('types/microNutrientAnalysis').ISupplementDosage>)=>void} props.handleChange function for reload the products
 * @returns
 */
const DosageFormular = ({
	dosage, disabled, products, loading, loadProducts, handleChange,
}) => (
	<Grid container spacing={ 2 }>
		<Grid item xs={ 12 } display="flex">
			<Autocomplete
				disablePortal
				options={ products }
				getOptionLabel={ (p) => p.pcn }
				renderInput={ (params) => (
					<TextField
						{ ...params }
						label="PZN"
						variant="standard"
					/>
				) }
				onChange={ (event, selected) => handleChange({ pcn: selected?.pcn ?? '' }) }
				isOptionEqualToValue={ (option, value) => option.pcn === value?.pcn }
				// set value to null to avoid "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."
				value={ _.find(products, (p) => p.pcn === dosage?.pcn) ?? null }
				style={{ maxWidth: 'unset', flexGrow: 1 }}
				disabled={ disabled }
			/>
			<IconButton
				style={{ width: '3rem', height: '3rem' }}
				disabled={ disabled }
				onClick={ loadProducts }
			>
				{ loading ? (
					<Box
						display="grid"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress size="1rem" color="inherit" />
					</Box>
				) : <Refresh /> }
			</IconButton>
		</Grid>
		<Grid item xs={ 12 }>
			<TextField
				label="MG/d(100)"
				variant="standard"
				type="number"
				inputProps={{ step: 0.01 }}
				value={ dosage?.mgDayKg100 ?? '' }
				onChange={ (event) => handleChange({ mgDayKg100: Number(event.target.value) }) }
				fullWidth
				disabled={ disabled }
			/>
		</Grid>
	</Grid>
);

/**
 * @param {object} props - props for the component
 * @param {import('types/microNutrientAnalysis').ISupplement} props.currentSupplement - the current supplement
 * @param {(product: import('types/microNutrientAnalysis').ISupplement)=>void} props.handleSaveSupplement save the supplement function
 * @param {(product: import('types/microNutrientAnalysis').ISupplement)=>
 * Promise<Partial<import('types/microNutrientAnalysis').IPharmaOfferRequest>[]>} props.handleDeleteSupplement delete the supplement function
 * @param {boolean} [props.disabled] - disable the formular
 * @returns {React.ReactComponentElement} component to be shown
 */
const SupplementFormular = ({
	currentSupplement, handleSaveSupplement, handleDeleteSupplement, disabled,
}) => {
	const [supplement, setSupplement] = useState(initialSupplement);
	const [dosageTab, setDosageTab] = useState('human');
	const [loading, setIsLoading] = useState(false);
	const [products, setProducts] = useState(initialProducts);
	const { enqueueMessage } = useMessage();

	const handleChange = (newValue) => {
		setSupplement((current) => ({ ...current, ...newValue }));
	};

	const handleSave = useCallback(() => {
		handleSaveSupplement(supplement);
	}, [supplement, handleSaveSupplement]);

	const handleDelete = useCallback(() => {
		handleDeleteSupplement(supplement);
	}, [supplement, handleDeleteSupplement]);

	useEffect(() => {
		// console.log(currentSupplement);
		setSupplement(currentSupplement);
	}, [currentSupplement, setSupplement]);

	/**
     *
     * @param {Partial<import('types/microNutrientAnalysis').ISupplementDosage>} newValue
     * @param {'human' | 'dog' | 'horse'} base
     */
	const handleChangeDosage = (newValue, base) => {
		setSupplement((current) => {
			const newSup = current;
			if (!_.find(newSup?.dosages, (dosage) => dosage.base === base)) {
				if (!newSup.dosages) {
					newSup.dosages = [];
				}
				newSup.dosages.push({
					base,
					mgDayKg100: 0,
				});
			}
			return ({
				...newSup,
				dosages: [
					...(_.map(newSup?.dosages, (dosage) => {
						if (dosage.base !== base) return dosage;
						return {
							...dosage,
							...newValue,
						};
					}) ?? []),
				],
			});
		});
	};

	useEffect(() => {
		setSupplement(currentSupplement);
	}, [currentSupplement, setSupplement]);

	const dosages = useMemo(() => _.fromPairs(_.map(supplement?.dosages, (dosage) => [dosage.base, dosage])), [supplement?.dosages]);

	const loadProducts = useCallback(async () => {
		setIsLoading(true);
		try {
			const ret = await API.graphql(graphqlOperation(listSupplementProducts, { filter: { active: { eq: 1 } }, limit: 1000 }));
			// set the products in order
			setProducts(_.orderBy(ret.data.listSupplementProducts.items, ['pcn'], ['asc']));
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
			enqueueMessage('SupplementAdministrationFormular', Exceptions.API_LOAD_ERROR);
		} finally {
			setIsLoading(false);
		}
	}, [listSupplementProducts, enqueueMessage, graphqlOperation, setIsLoading, setProducts]);

	useEffect(() => {
		if (currentSupplement.id) loadProducts();
	}, [currentSupplement, loadProducts]);

	return (
		<Box style={{
			flexGrow: 1, display: 'flex', width: '100%', flexDirection: 'column', gap: '2rem',
		}}
		>
			<Grid container spacing={ 2 }>
				<Grid item xs={ 12 }>
					<Autocomplete
						options={ [] }
						value={ supplement?.names }
						onChange={ (event, value) => {
							handleChange({ names: value });
						} }
						fullWidth
						multiple
						freeSolo
						disabled={ disabled }
						renderTags={ (value, getTagProps) => value.map((option, index) => (
							<Chip key={ option } variant="outlined" label={ option } { ...getTagProps({ index }) } />
						)) }
						renderInput={ (params) => (
							<TextField
								{ ...params }
								variant="standard"
								label="Namen* (bitte mit Enter bestätigen)"
								placeholder="Namen"
							/>
						) }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<FormControlLabel
						control={ (
							<Switch
								disabled={ disabled }
								checked={ !supplement?.noProductAvailable ?? true }
								onChange={ (e, checked) => handleChange({ noProductAvailable: !checked }) }
							/>
						) }
						label="Produkt verfügbar"
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<FormControlLabel
						control={ (
							<Switch
								disabled={ disabled }
								checked={ supplement?.active ?? false }
								onChange={ (e, checked) => handleChange({ active: checked }) }
							/>
						) }
						label="Aktiv"
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<TextField
						label="Beschreibung (für den Kunden - z.B. Wirkung)"
						variant="standard"
						value={ supplement?.description ?? '' }
						onChange={ (event) => handleChange({ description: event.target.value }) }
						fullWidth
						disabled={ disabled }
					/>
				</Grid>
			</Grid>
			{ !supplement?.noProductAvailable && (
				<Box>
					<Box sx={{
						width: '100%', borderBottom: 1, borderColor: 'divider',
					}}
					>
						<Tabs
							value={ dosageTab }
							onChange={ (event, newValue) => setDosageTab(newValue) }
							indicatorColor="secondary"
							textColor="inherit"
							variant="fullWidth"
						>
							<Tab label="Mensch" value="human" style={{ color: _.get(dosages, 'human')?.pcn ? 'green' : 'inherit' }} />
							<Tab label="Hund" value="dog" style={{ color: _.get(dosages, 'dog')?.pcn ? 'green' : 'inherit' }} />
							<Tab label="Pferd" value="horse" style={{ color: _.get(dosages, 'horse')?.pcn ? 'green' : 'inherit' }} />
						</Tabs>
					</Box>
					<TabPanel value="human" index={ dosageTab }>
						<DosageFormular
							loading={ loading }
							loadProducts={ loadProducts }
							products={ products }
							dosage={ _.get(dosages, 'human') }
							disabled={ disabled }
							handleChange={ (newValue) => handleChangeDosage(newValue, 'human') }
						/>
					</TabPanel>
					<TabPanel value="horse" index={ dosageTab }>
						<DosageFormular
							loading={ loading }
							loadProducts={ loadProducts }
							products={ products }
							dosage={ _.get(dosages, 'horse') }
							disabled={ disabled }
							handleChange={ (newValue) => handleChangeDosage(newValue, 'horse') }
						/>
					</TabPanel>
					<TabPanel value="dog" index={ dosageTab }>
						<DosageFormular
							loading={ loading }
							loadProducts={ loadProducts }
							products={ products }
							dosage={ _.get(dosages, 'dog') }
							disabled={ disabled }
							handleChange={ (newValue) => handleChangeDosage(newValue, 'dog') }
						/>
					</TabPanel>
				</Box>
			) }
			<Button variant="contained" startIcon={ <Save /> } disabled={ disabled } onClick={ handleSave }>Speichern</Button>
			<Button
				variant="contained"
				onClick={ () => setSupplement(currentSupplement) }
				startIcon={ <Cancel /> }
				disabled={ _.isEqual(supplement, currentSupplement) }
			>
				Zurücksetzen
			</Button>
			<Button
				variant="contained"
				color="warning"
				startIcon={ <Delete /> }
				disabled={ disabled }
				onClick={ handleDelete }
			>
				Löschen versuchen
			</Button>
		</Box>
	);
};

export { SupplementFormular };
