import {
	Autocomplete, Box, CircularProgress, IconButton, TextField,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { listCustomers } from 'graphql/customQueries';
import { useMessage } from 'hooks/useMessage';
import { Exceptions } from 'messages/Exceptions';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styles from 'pages/customer/customerDashboard.module.scss';
import { Divider } from 'components/Divider/Divider';
import { CustomerPage } from 'pages/customer/CustomerPage';

const getCustomerTypeLabel = (type) => {
	switch (type) {
		case 'dog': return 'Hund';
		case 'horse': return 'Pferd';
		default: return 'Mensch';
	}
};

/**
 *
 * @param {import('types/customer').ICustomer} customer
 * @returns {string} the name
 */
const getCustomerNameLabel = (customer) => {
	let name = '';
	if (customer?.type !== 'human' && customer?.nameOwner) {
		name += `${customer.nameOwner} / `;
	}
	if (customer?.name) {
		name += customer.name;
	}
	if (customer?.type !== 'human') {
		name += ` (${getCustomerTypeLabel(customer?.type)})`;
	}
	return name;
};

/** @type {import('types/customer').ICustomer[]} */
const initialCustomers = [];

const CustomerDashboard = () => {
	const [customers, setCustomers] = useState(initialCustomers);
	const [selectedCustomerId, setSelectedCustomerId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueMessage } = useMessage();
	const navigate = useNavigate();
	const urlParams = useParams();

	useEffect(() => {
		const loadCustomers = async () => {
			setIsLoading(true);
			try {
				const ret = await API.graphql(graphqlOperation(listCustomers, { limit: 100000 }));
				setCustomers(ret.data.listCustomers.items);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				enqueueMessage('CustomerDashboard', Exceptions.API_LOAD_ERROR);
			} finally {
				setIsLoading(false);
			}
		};
		if (customers === initialCustomers) {
			loadCustomers();
		}
	}, [listCustomers, enqueueMessage]);

	useEffect(() => {
		if (customers.length && urlParams?.id && selectedCustomerId !== urlParams?.id) {
			setSelectedCustomerId(urlParams?.id);
		}
	}, [urlParams, customers, setSelectedCustomerId]);

	const handleSelectCustomer = useCallback((event, value) => {
		if (value?.id) {
			navigate(`${generatePath('/customer/:id', { id: value?.id })}`);
		} else {
			navigate('/customer');
		}
		setSelectedCustomerId(value?.id);
	}, [navigate, setSelectedCustomerId]);

	// adds or updates a customer in the list
	const putCustomer = (customer) => {
		const index = _.find(customers, ['id', customer.id]);

		if (index) { // update
			setCustomers(_.map(customers, (c) => (c.id === customer.id ? customer : c)));
		} else { // add
			setCustomers([...customers, customer]);
			setSelectedCustomerId(customer.id);
		}
	};

	// removes a customer from the list
	const removeCustomer = useCallback((id) => {
		setCustomers(_.filter(customers, (c) => (c.id !== id)));
		setSelectedCustomerId(null);
		navigate('/customer');
	}, [navigate]);

	return (
		<>
			<Box className={ styles.customerSelectSection }>
				<Box className={ styles.sectionContent }>
					{ !isLoading && (
						<>
							<Autocomplete
								disablePortal
								options={ customers }
								// eslint-disable-next-line max-len
								getOptionLabel={ getCustomerNameLabel }
								sx={{ maxWidth: '300px', width: '80%' }}
								renderInput={ (p) => <TextField { ...p } variant="standard" label="Kunde" /> }
								renderOption={ (props, option) => (
									<Box component="li" { ...props } key={ option.id }>
										{ getCustomerNameLabel(option) }
									</Box>
								) }
								onChange={ handleSelectCustomer }
								isOptionEqualToValue={ (option, value) => option.id === value?.id }
								// set value to null to avoid "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."
								value={ _.find(customers, (c) => c.id === selectedCustomerId) ?? null }
							/>
							<IconButton onClick={ () => { handleSelectCustomer(null, { id: 'new' }); } }>
								<PersonAddAlt1Icon style={{ color: 'white' }} />
							</IconButton>
						</>
					) }
					{ isLoading && (
						<CircularProgress color="success" />
					) }
				</Box>
			</Box>
			{ selectedCustomerId && (
				<>
					<Divider />
					<CustomerPage id={ selectedCustomerId } putCustomer={ putCustomer } removeCustomer={ removeCustomer } />
				</>
			) }
		</>
	);
};

export { CustomerDashboard, getCustomerNameLabel };
