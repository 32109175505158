import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
	useCallback,
	useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	Tab,
	Tabs,
	TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import SignatureCanvas from 'react-signature-canvas';
import {
	CopyAll, Email, ExpandMoreRounded, Info, InfoOutlined, Spa, SpaOutlined,
} from '@mui/icons-material';
import {
	createCustomer, updateCustomer, deleteCustomer,
} from 'graphql/customMutations';
import { formatDate } from 'helper/formatDate';

import { Modal } from 'Modal';

import styles from 'styles/customer.module.scss';
import { CustomerFormular } from 'pages/customer/CustomerFormular';
import { useClipboard } from 'hooks/useClipboard';
import { CUSTOMER_URL_PARAMS } from 'pages/customer/PublicCustomerFormular';
import { useMessage } from 'hooks/useMessage';
import { Messages } from 'messages/Messages';
import { Exceptions } from 'messages/Exceptions';
import { getCustomer } from 'graphql/customQueries';
import { CustomerLogsPage } from 'pages/log/CustomerLogsPage';
import { ContentContainer } from 'templates/layout/ContentContainer';
import moment from 'moment';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { TabPanel } from 'templates/components/Tabs';
import { submitCustomerInformationLink } from 'graphql/mutations';

/** @type {import('types/customer').ICustomer} */
const initialCustomer = {};

/**
 *
 * @param {object} props - props for the component
 *  * @param {string} props.id - id of the customer
 * @param {(customer: import('types/customer').ICustomer) => void} props.putCustomer - adds or updates a customer in the list
 * @param {(id: string) => void} props.removeCustomer - removes the customer from the list
 * @returns
 */
const CustomerPage = ({
	id, putCustomer, removeCustomer,
}) => {
	const [customer, setCustomer] = useState(initialCustomer);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [tabsValue, setTabsValue] = useState('info');
	const approvalEnlightenmentHumanSignatureRef = useRef();
	const approvalEnlightenmentAnimalSignatureRef = useRef();
	const { enqueueMessage } = useMessage();
	const { copyToClipboard } = useClipboard();
	const navigate = useNavigate();
	const urlParams = useParams();

	const theme = useTheme();
	// const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	Storage.configure({ track: true, level: 'private' });

	const isNew = useMemo(() => id === 'new', [id]);
	const isInfoTab = useMemo(() => tabsValue === 'info', [tabsValue]);

	const modalMessage = useMemo(
		() => `Möchten Sie den Kunden "${customer?.nameOwner ?? customer?.name ?? ''}" wirklich löschen?`,
		[customer?.name, customer?.nameOwner],
	);

	const publicCustomerInformationURL = useMemo(() => {
		if (!customer) {
			return '';
		}
		let url = `${window.location.origin}/public/customer?id=${customer?.id}`;
		const add = (key) => {
			if (customer[key]) {
				url += `&${key}=${encodeURIComponent(customer[key])}`;
			}
		};
		_.forEach(_.omit(CUSTOMER_URL_PARAMS, 0), add);
		return url;
	}, [customer]);

	useEffect(() => {
		const loadCustomer = async () => {
			setIsLoading(true);
			try {
				const ret = await API.graphql(graphqlOperation(getCustomer, { id }));
				setCustomer(ret.data.getCustomer);
			} catch (err) {
				enqueueMessage(`Customer_${id}`, Exceptions.API_LOAD_ERROR);
			} finally {
				setIsLoading(false);
			}
		};
		setCustomer(null);
		if (!isNew) {
			loadCustomer();
		} else {
			setTabsValue('info');
		}
	}, [id, isNew, setCustomer, setTabsValue, enqueueMessage]);

	const handleSendCustomerInformationLink = useCallback(async () => {
		try {
			const ret = await API.graphql(graphqlOperation(submitCustomerInformationLink, { input: { link: publicCustomerInformationURL, email: customer?.email } }));
			if (ret?.data?.submitCustomerInformationLink) {
				enqueueMessage('submit_customer_information_link', Messages.DATA_SUBMITTED_SUCCESSFULLY);
			} else {
				enqueueMessage('submit_customer_information_link', Exceptions.GENERAL);
			}
		} catch (err) {
			enqueueMessage('submit_customer_information_link', Exceptions.GENERAL);
		}
	}, [customer?.email, publicCustomerInformationURL, submitCustomerInformationLink, Messages.DATA_SUBMITTED_SUCCESSFULLY, Exceptions.GENERAL, setIsSaving, enqueueMessage]);

	const handleChangeCustomer = (data) => {
		// update customer state
		setCustomer((current) => ({
			...current,
			...data,
		}));
	};

	const handleSaveCustomer = useCallback(async () => {
		// replace empty values with nulls
		const preparedCustomer = _.mapValues(customer, (v) => {
			if (v) return v;
			return null;
		});

		// remove id if empty
		if (!preparedCustomer.id) { delete preparedCustomer.id; }

		// if (preparedCustomer.birthdate) {
		//     preparedCustomer.birthdate = formatDate(preparedCustomer.birthdate);
		// }

		if (preparedCustomer.approvalEnlightenmentHumanSignature) {
			if (!preparedCustomer.approvalEnlightenmentHumanApprovedAt) {
				Object.assign(preparedCustomer, {
					approvalEnlightenmentHumanApprovedAt: moment(new Date()).toISOString(),
				});
			}
		} else {
			Object.assign(preparedCustomer, {
				approvalEnlightenmentHumanSignature: null,
				approvalEnlightenmentHumanApprovedAt: null,
			});
		}

		if (preparedCustomer.approvalEnlightenmentAnimalSignature) {
			if (!preparedCustomer.approvalEnlightenmentAnimalApprovedAt) {
				Object.assign(preparedCustomer, {
					approvalEnlightenmentAnimalApprovedAt: moment(new Date()).toISOString(),
				});
			}
		} else {
			Object.assign(preparedCustomer, {
				approvalEnlightenmentAnimalSignature: null,
				approvalEnlightenmentAnimalApprovedAt: null,
			});
		}

		setIsSaving(true);
		try {
			let newCustomer;

			// update or create record
			if (customer.id) { // update
				const ret = await API.graphql(graphqlOperation(updateCustomer, { input: preparedCustomer }));
				newCustomer = ret.data.updateCustomer;
			} else { // create
				const ret = await API.graphql(graphqlOperation(createCustomer, { input: preparedCustomer }));

				newCustomer = ret.data.createCustomer;
			}

			putCustomer(newCustomer);
			enqueueMessage(`Customer_${preparedCustomer.id ?? 'new'}`, Messages.API_SAVE_SUCCESSFUL);

			navigate(`${generatePath('/customer/:id', { id: newCustomer.id })}`);
		} catch (err) {
			enqueueMessage(`Customer_${preparedCustomer.id ?? 'new'}`, Exceptions.API_SAVE_ERROR);
		} finally {
			setIsSaving(false);
		}
	}, [customer, updateCustomer, createCustomer, Messages.API_SAVE_SUCCESSFUL, Exceptions.API_SAVE_ERROR, formatDate, putCustomer, enqueueMessage]);

	const handleDeleteCustomer = async () => {
		setIsSaving(true);
		try {
			await API.graphql(graphqlOperation(deleteCustomer, { input: { id: customer.id } }));
			removeCustomer(customer.id);
			setOpenModal(false);
			enqueueMessage(`Customer_${customer.id}`, Messages.API_DELETE_SUCCESSFUL);
		} catch (err) {
			enqueueMessage(`Customer_${customer.id}`, Exceptions.API_DELETE_ERROR);
		} finally {
			setIsSaving(false);
		}
	};

	const handleOpenModal = () => setOpenModal(true);

	useEffect(() => {
		if (urlParams?.logId) {
			setTabsValue('logs');
		}
	}, [urlParams?.logId, setTabsValue]);

	if (isLoading) {
		return (
			<Box style={{ width: '100%', textAlign: 'center', marginTop: '2rem' }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<>
			<Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={ tabsValue }
					onChange={ (event, newValue) => setTabsValue(newValue) }
					indicatorColor="secondary"
					textColor="inherit"
					variant="fullWidth"
					aria-label="Kundenmenü"
				>
					<Tab label={ !isMobile ? 'Kundeninformation' : '' } value="info" icon={ isInfoTab ? <Info /> : <InfoOutlined /> } iconPosition="start" />
					<Tab label={ !isMobile ? 'Anwendungen' : '' } value="logs" icon={ isInfoTab ? <SpaOutlined /> : <Spa /> } iconPosition="start" disabled={ isNew } />
				</Tabs>
				{ /* <Divider height="0.3rem" /> */ }
			</Box>
			<ContentContainer>
				<TabPanel value={ tabsValue } index="info">
					<Box className={ styles.form }>
						<CustomerFormular currentCustomer={ customer } handleChangeCustomer={ handleChangeCustomer } disabled={ isSaving } />
						<Accordion style={{ width: '100%' }}>
							<AccordionSummary expandIcon={ <ExpandMoreRounded /> }>
								<Typography fontWeight="bold">Aufklärung (Humanenergetik)</Typography>
								{ customer?.approvalEnlightenmentHumanApprovedAt && (
									<Typography fontStyle="italic" marginLeft="0.3rem">
										{ `unterfertigt ${new Date(customer?.approvalEnlightenmentHumanApprovedAt).toLocaleDateString('de-de')}` }
									</Typography>
								) }
							</AccordionSummary>
							<AccordionDetails>
								<Box display="flex" flexDirection="column" width="100%" alignItems="center">
									<h3 className={ styles.costumerName }>
										AUFKLÄRUNG
									</h3>
									<Typography>[Humanenergetik]</Typography>
									<Typography align="justify" margin="1rem">
										Die energetische Hilfestellung beschäftigt sich ausschließlich mit
										der Aktivierung und Harmonisierung körpereigener Energiefelder
										(Lebensenergie). Ich wurde darüber informiert und nehme zur Kenntnis,
										dass ich ausnahmslos energetische Beratung erhalte, die unter Zuhilfenahme
										von nachstehenden oder ähnlichen gewerblich erlaubten Methoden durchgeführt wird.
									</Typography>

									<TextField
										fullWidth
										label="Methoden"
										variant="standard"
										multiline
										value={ customer?.approvalEnlightenmentHumanText ?? '' }
										onChange={ (event) => handleChangeCustomer({
											approvalEnlightenmentHumanText: event.target.value,
											approvalEnlightenmentHumanApprovedAt: null,
											approvalEnlightenmentHumanSignature: null,
										}) }
									/>
									<Typography align="justify" margin="1rem">
										Da diese Maßnahmen der Wiederherstellung und Harmonisierung der körpereigenen
										Energiefelder dienen, stellen sie keine Heilbehandlung dar. Die Wirkungsweise
										und der Erfolg der energetischen Behandlung ist naturwissenschaftlich nicht
										belegt bzw. bei bestimmten Methoden widerlegt.
									</Typography>
									<Typography align="justify" margin="1rem">
										Dementsprechend stellt die energetische Hilfestellung keinerlei Ersatz für
										ärztliche Diagnose und Behandlung dar, auch keinerlei Ersatz für psychologische
										oder psychotherapeutische Behandlung oder Untersuchung. Sämtliche Aussagen und
										Ratschläge sind keine Diagnosen, sondern stellen reine energetische Zustandsbeschreibungen dar.
									</Typography>
									<Typography align="justify" margin="1rem">
										Ich wurde darüber informiert, dass ich mich für die Diagnoseerstellung und
										Therapie an meinen Arzt/meine Ärztin zu wenden habe.
									</Typography>
									<Typography align="justify" margin="1rem">
										Ich habe vor Unterschriftsleistung obigen Inhalt genauestens gelesen, vollinhaltlich verstanden und gutgeheißen.
									</Typography>
									{ !customer?.approvalEnlightenmentHumanApprovedAt
										? (
											<SignatureCanvas
												penColor="magenta"
												onEnd={ () => approvalEnlightenmentHumanSignatureRef.current && handleChangeCustomer(
													{
														approvalEnlightenmentHumanSignature: approvalEnlightenmentHumanSignatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
													},
												) }
												canvasProps={{
													width: 300,
													height: 200,
													style: {
														border: 'solid 1px #000',
													},
												}}
												ref={ approvalEnlightenmentHumanSignatureRef }
											/>
										)
										: <Box component="img" src={ customer.approvalEnlightenmentHumanSignature } /> }
									<Button
										variant="text"
										component="label"
										onClick={ () => {
											approvalEnlightenmentHumanSignatureRef?.current?.clear();
											handleChangeCustomer({
												approvalEnlightenmentHumanApprovedAt: null,
												approvalEnlightenmentHumanSignature: null,
											});
										} }
										startIcon={ <DeleteIcon /> }
									>
										Unterschrift löschen
									</Button>
								</Box>
							</AccordionDetails>
						</Accordion>
						<Accordion style={{ width: '100%' }}>
							<AccordionSummary expandIcon={ <ExpandMoreRounded /> }>
								<Typography fontWeight="bold">Aufklärung (Tierenergetik)</Typography>
								{ customer?.approvalEnlightenmentAnimalApprovedAt && (
									<Typography fontStyle="italic" marginLeft="0.3rem">
										{ `unterfertigt ${new Date(customer?.approvalEnlightenmentAnimalApprovedAt).toLocaleDateString('de-de')}` }
									</Typography>
								) }
							</AccordionSummary>
							<AccordionDetails>
								<Box display="flex" flexDirection="column" width="100%" alignItems="center">
									<h3 className={ styles.costumerName }>
										AUFKLÄRUNG
									</h3>
									<Typography>[Tierenergetik]</Typography>
									<Typography align="justify" margin="1rem">
										Die energetische Hilfestellung beschäftigt sich ausschließlich mit
										der Aktivierung und Harmonisierung körpereigener Energiefelder
										(Lebensenergie). Ich wurde darüber informiert und nehme zur Kenntnis,
										dass ich ausnahmslos energetische Beratung erhalte, die unter Zuhilfenahme
										von nachstehenden oder ähnlichen gewerblich erlaubten Methoden durchgeführt wird.
									</Typography>

									<TextField
										fullWidth
										label="Methoden"
										variant="standard"
										multiline
										value={ customer?.approvalEnlightenmentAnimalText ?? '' }
										onChange={ (event) => handleChangeCustomer({
											approvalEnlightenmentAnimalText: event.target.value,
											approvalEnlightenmentAnimalApprovedAt: null,
											approvalEnlightenmentAnimalSignature: null,
										}) }
									/>
									<Typography align="justify" margin="1rem">
										Da diese Maßnahmen der Wiederherstellung und Harmonisierung der körpereigenen
										Energiefelder dienen, stellen sie keine Heilbehandlung dar. Die Wirkungsweise
										und der Erfolg der energetischen Behandlung ist naturwissenschaftlich nicht
										belegt bzw. bei bestimmten Methoden widerlegt.
									</Typography>
									<Typography align="justify" margin="1rem">
										Dementsprechend stellt die energetische Hilfestellung keinerlei Ersatz für
										tierärztliche Diagnose und Behandlung dar, auch keinerlei Ersatz für psychologische
										oder psychotherapeutische Behandlung oder Untersuchung. Sämtliche Aussagen und
										Ratschläge sind keine Diagnosen, sondern stellen reine energetische Zustandsbeschreibungen dar.
									</Typography>
									<Typography align="justify" margin="1rem">
										Ich wurde darüber informiert, dass ich mich für die Diagnoseerstellung und
										Therapie an meinen Tierarzt/meine Tierärztin zu wenden habe.
									</Typography>
									<Typography align="justify" margin="1rem">
										Ich habe vor Unterschriftsleistung obigen Inhalt genauestens gelesen, vollinhaltlich verstanden und gutgeheißen.
									</Typography>
									{ !customer?.approvalEnlightenmentAnimalApprovedAt
										? (
											<SignatureCanvas
												penColor="magenta"
												onEnd={ () => handleChangeCustomer(
													{
														approvalEnlightenmentAnimalSignature:
                                                    approvalEnlightenmentAnimalSignatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
													},
												) }
												canvasProps={{
													width: 300,
													height: 200,
													style: {
														border: 'solid 1px #000',
													},
												}}
												ref={ approvalEnlightenmentAnimalSignatureRef }
											/>
										)
										: <Box component="img" src={ customer.approvalEnlightenmentAnimalSignature } /> }
									<Button
										variant="text"
										component="label"
										onClick={ () => {
											approvalEnlightenmentAnimalSignatureRef?.current?.clear();
											handleChangeCustomer({
												approvalEnlightenmentAnimalApprovedAt: null,
												approvalEnlightenmentAnimalSignature: null,
											});
										} }
										startIcon={ <DeleteIcon /> }
									>
										Unterschrift löschen
									</Button>
								</Box>
							</AccordionDetails>
						</Accordion>
						<Button variant="contained" onClick={ handleSaveCustomer } startIcon={ <SaveIcon /> } disabled={ isSaving }>Speichern</Button>
						{ !isNew && (
							<>
								<Button
									variant="contained"
									startIcon={ <Email /> }
									color="info"
									onClick={ handleSendCustomerInformationLink }
									disabled={ !customer?.email || isSaving }
								>
									Informationsanfrage senden
								</Button>
								<Button
									variant="contained"
									startIcon={ <CopyAll /> }
									color="info"
									onClick={ () => copyToClipboard(publicCustomerInformationURL, true) }
								>
									Link (IA)
								</Button>
							</>
						) }
						<Button variant="contained" onClick={ handleOpenModal } startIcon={ <DeleteIcon /> } disabled={ isSaving }>Löschen</Button>
						<Modal
							open={ openModal }
							openModal={ openModal }
							setOpenModal={ setOpenModal }
							handleDelete={ handleDeleteCustomer }
							message={ modalMessage }
						/>
					</Box>
				</TabPanel>
				<TabPanel value={ tabsValue } index="logs">
					<CustomerLogsPage customer={ customer } />
				</TabPanel>
			</ContentContainer>
		</>
	);
};

export { CustomerPage };
