/* eslint-disable max-len */
import { Amplify } from 'aws-amplify';
import awsExports from 'aws-exports';
import { Send as SendIcon } from '@mui/icons-material';
import {
	Alert, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography,
} from '@mui/material';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from 'styles/customer.module.scss';
import { HairAnalysisInformationPage } from 'pages/log/microNutrientAnalysis/HairAnalysisInformationPage';
import { submitAnamnesis } from 'graphql/mutations';
import { useMessage } from 'hooks/useMessage';
import { Messages } from 'messages/Messages';
import { Exceptions } from 'messages/Exceptions';
import { Divider } from 'components/Divider/Divider';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';

const initial = {
	id: '00000000-0000-0000-0000-000000000000',
	customerLogsId: undefined,
	anamnesis: undefined,
};

const PublicAnamnesisFormular: FC<{ id: string, customerLogsId: string }> = ({ id, customerLogsId }) => {
	const [currentFormData, setCurrentFormData] = useState({ id: '', customerLogsId: '', anamnesis: '', consent: false });
	const [isLoading, setIsLoading] = useState(false);
	const [sent, setSent] = useState(false);
	// getting the URL parameters
	const [searchParams] = useSearchParams();

	const { editItem } = AWSAppSyncProvider();
	const { enqueueMessage } = useMessage();

	const handleChangeFormular = (data: Partial<{ id: string, customerLogsId: string, anamnesis: string | undefined, consent: boolean }>) => {
		setCurrentFormData((curr) => ({ ...curr, ...data }));
	};

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			const submitAnamnesisLogId: string = await editItem(submitAnamnesis, { input: _.pick(currentFormData, 'id', 'customerLogsId', 'anamnesis') });
			if (submitAnamnesisLogId === currentFormData?.id || (currentFormData?.id === '00000000-0000-0000-0000-000000000000' && submitAnamnesisLogId !== currentFormData?.id)) {
				enqueueMessage('submit_anamnesis', Messages.DATA_SUBMITTED_SUCCESSFULLY);
				setSent(true);
			} else {
				enqueueMessage('submit_anamnesis', Exceptions.GENERAL);
			}
		} catch (err) {
			enqueueMessage('submit_anamnesis', Exceptions.GENERAL);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		// remove cognito to allow api key
		Amplify.configure({ ..._.pickBy(awsExports, (v, key) => !key.startsWith('aws_cognito')), aws_appsync_authenticationType: 'API_KEY' });
	}, []);

	useEffect(() => {
		handleChangeFormular({
			id: id ?? (searchParams.get('id') ?? initial.id),
			customerLogsId: customerLogsId ?? searchParams.get('customerLogsId'),
		});
	}, [id, customerLogsId, searchParams, initial]);

	const formularReady = useMemo(() => currentFormData?.id && currentFormData?.customerLogsId
    && currentFormData?.anamnesis && !!currentFormData?.consent, [currentFormData]);

	return (
		<>
			<HairAnalysisInformationPage />
			<Divider />

			{ sent && (
				<Box>
					<Alert severity="success" action={ <Button onClick={ () => setSent(false) }>Formular weiter bearbeiten</Button> }>
						Der Anamnesebogen wurde übermittelt!
					</Alert>
					<Box marginTop="2rem">
						<Typography fontWeight="bold" display="inline" marginRight="0.7rem">Bitte senden Sie eine beliebige Haarprobe an nachstehende Adresse!</Typography>
						<Typography display="inline">Nachdem wir die Haarprobe erhalten haben, werden wir umgehend eine Analyse vornehmen und Sie elektronisch über das Ergenis informieren.</Typography>
					</Box>
					<Box sx={{ marginY: '2rem' }}>
						the dogs⭐️the horses
						<br />
						Obere Hauptstrasse 36
						<br />
						Ellen Fuhrmann
						<br />
						7410, Loipersdorf
					</Box>
				</Box>
			) }
			{ (!sent && currentFormData.customerLogsId) && (
				<>
					<h2 style={{ marginTop: '2rem' }}>Anamnesebogen</h2>
					<Box className={ styles.form } style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
						<Grid container spacing={ 4 }>
							<Grid item xs={ 12 }>
								<TextField
									label="Anamnese"
									variant="standard"
									multiline
									value={ currentFormData?.anamnesis ?? '' }
									onChange={ (event) => handleChangeFormular({ anamnesis: event.target.value }) }
									fullWidth
								/>
							</Grid>
							<Grid item xs={ 12 }>
								<FormControlLabel
									control={ (
										<Checkbox
											checked={ currentFormData?.consent ?? false }
											onChange={ (event) => handleChangeFormular({ consent: event.target.checked }) }
										/>
									) }
									label="Ich akzeptiere, dass meine Daten elektronisch verarbeitet werden und meine Informationen zum Zweck der Analyse und Anwendung an Dritte weitergegeben werden dürfen. Zudem bin ich mit der Zusendung einer elektronischen Rechnung einverstanden!"
								/>
							</Grid>
						</Grid>
						Die Kosten für eine Mikronährstoffanalyse belaufen sich auf 60€ (inkl. MwSt.). Mit dem Absenden des Formulars und der Zusendung einer geeigneten Probengrundlage (z.B. Haare) wird diese Dienstleistung kostenpflichtig bestellt.
						<Button variant="contained" onClick={ handleSubmit } startIcon={ <SendIcon /> } disabled={ isLoading || !formularReady }>Kostenpflichtig bestellen</Button>
					</Box>
				</>
			) }
			{ !currentFormData.customerLogsId && (
				<Alert severity="warning">
					Die übergebenen Informationen sind ungültig. Bitte kontaktieren Sie mich via
					{ ' ' }
					<a href="mailto:office@the-dogs.at">office@the-dogs.at</a>
				</Alert>
			) }
		</>
	);
};

export { PublicAnamnesisFormular };
