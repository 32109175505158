import {
	useState, useEffect, useCallback, FC,
} from 'react';
import {
	Box,
	Button,
	CircularProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import _ from 'lodash';
import { useMessage } from 'hooks/useMessage';
import { Exceptions } from 'messages/Exceptions';
import { listLogs } from 'graphql/customQueries';
import { LogPage } from 'pages/log/LogPage';
import { formatDateTimeString } from 'helper/formatDate';
import {
	Add, ArrowRight, Close, Menu,
} from '@mui/icons-material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ILog } from 'types/log';
import { ICustomer } from 'types/customer';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';

/**
 *
 * @param {object} props - props for the component
 * @param {import('types/customer').ICustomer} props.customer - the customer object
 * @returns
 */
const CustomerLogsPage: FC<{customer: ICustomer}> = ({ customer }) => {
	const [logs, setLogs] = useState<ILog[]>();
	const [currentLogId, setCurrentLogId] = useState<string>();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const urlParams = useParams();
	const navigate = useNavigate();

	const { enqueueMessage } = useMessage();
	const { listItems } = AWSAppSyncProvider();

	const iOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	// sets the logs in descendig order
	const _setLogsOrdered = useCallback((unorderedLogs: ILog[]) => {
		setLogs(_.orderBy(unorderedLogs, ['date'], ['desc']));
	}, [setLogs]);

	// adds or updates a customer in the list
	const putLog = (log: ILog) => {
		if (log && customer.id && log.id) {
			const index = _.find(logs, ['id', log.id]);

			if (index) { // update
				_setLogsOrdered(_.map(logs, (c) => (c.id === log.id ? log : c)));
			} else { // add
				_setLogsOrdered([...logs ?? [], log]);
			}
			if (currentLogId !== log.id) {
				setCurrentLogId(log.id);
				navigate(`${generatePath('/customer/:id/:logId', { id: customer.id, logId: log.id })}`);
			}
		}
	};

	// removes a customer from the list
	const removeLog = (id: string) => {
		_setLogsOrdered(_.filter(logs, (c) => (c.id !== id)));
		setCurrentLogId(undefined);
		if (customer.id) {
			navigate(`${generatePath('/customer/:id/', { id: customer.id })}`);
		}
	};

	useEffect(() => {
		setCurrentLogId(urlParams?.logId);
	}, [urlParams?.logId]);

	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			try {
				// set the logs of the selected customer
				_setLogsOrdered(await listItems(listLogs, { filter: { customerLogsId: { eq: customer?.id } }, limit: 1000 }));
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				enqueueMessage('CustomerLogsPage', Exceptions.API_LOAD_ERROR);
			} finally {
				setIsLoading(false);
			}
		};
		if (customer?.id) {
			load();
		}
	}, [customer?.id, listLogs, Exceptions.API_LOAD_ERROR, enqueueMessage, _setLogsOrdered]);

	if (isLoading) {
		return (
			<Box style={{ width: '100%', textAlign: 'center', marginTop: '2rem' }}>
				<CircularProgress />
			</Box>
		);
	}

	const logsList = (
		<List dense style={{ minWidth: '350px', width: isMobile ? '100%' : '350px' }}>
			<ListItem key="new" disablePadding>
				<ListItemButton
					onClick={ () => {
						if (!customer.id) return;
						navigate(`${generatePath('/customer/:id/:logId', { id: customer.id, logId: '00000000-0000-0000-0000-000000000000' })}`);
						setDrawerOpen(false);
					} }
					sx={{ backgroundColor: (currentLogId === '00000000-0000-0000-0000-000000000000') ? 'primary.main' : 'unset' }}
				>
					<ListItemText primary="Neue Anwendung anlegen" />
					<ListItemIcon>
						<Add />
					</ListItemIcon>
				</ListItemButton>
			</ListItem>
			{ logs?.map((log) => (
				<ListItem key={ log.id } disablePadding>
					<ListItemButton
						onClick={ () => {
							if (!customer.id || !log.id) return;
							navigate(`${generatePath('/customer/:id/:logId', { id: customer.id, logId: log.id })}`);
							setDrawerOpen(false);
						} }
						sx={{

							color: log.closed ? 'success.main' : 'inherit',
							backgroundColor: (log.id === currentLogId) ? 'primary.main' : 'unset',
						}}
					>
						<ListItemText primary={ `${formatDateTimeString(log.date, 'dd.MM.yyyy')} ${log.subject ?? ''}` } />
						<ListItemIcon>
							<ArrowRight />
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
			)) }
		</List>
	);

	return (
		<Box>
			{ (!isDesktop) && (
				<Button
					onClick={ () => setDrawerOpen(true) }
					style={{ marginBottom: '2rem' }}
					startIcon={ <Menu color="primary" /> }
				>
					Liste öffnen
				</Button>
			) }
			<Box style={{
				minHeight: '50vH', display: 'flex',
			}}
			>

				{ (!isDesktop && drawerOpen) && (
					<SwipeableDrawer
						anchor="left"
						open={ drawerOpen }
						onClose={ () => setDrawerOpen(false) }
						onOpen={ () => setDrawerOpen(true) }
						disableBackdropTransition={ !iOS }
						disableDiscovery={ iOS }
						PaperProps={{ sx: { width: isMobile ? '100%' : 'inerhit' } }}
					>
						<Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
							<Button onClick={ () => setDrawerOpen(false) } startIcon={ <Close /> }>Liste schließen</Button>
							{ logsList }
						</Box>
					</SwipeableDrawer>
				) }
				{ isDesktop && logsList }
				<Box style={{
					flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '2rem', paddingLeft: isDesktop ? '2rem' : '0rem',
				}}
				>
					{ !currentLogId && (
						<Typography component="h2">Bitte eine Anwendung aus der Liste auswählen oder eine neue anlegen</Typography>
					) }
					{ !!currentLogId && (
						<LogPage
							id={ currentLogId }
							customer={ customer }
							putLog={ putLog }
							removeLog={ removeLog }
						/>
					) }
				</Box>
			</Box>
		</Box>
	);
};

export { CustomerLogsPage };
