import _ from 'lodash';
import {
	Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, TextField, useMediaQuery, useTheme,
} from '@mui/material';
import {
	Add,
	ArrowRight,
	Close,
	Menu,
} from '@mui/icons-material';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { SupplementFormular } from 'pages/administration/SupplementFormular';
import { API, graphqlOperation } from 'aws-amplify';
import { useMessage } from 'hooks/useMessage';
import { Messages } from 'messages/Messages';
import { Exceptions } from 'messages/Exceptions';
import { listLogs, listMicroNutrientAnalysisPositionItems, listSupplements } from 'graphql/customQueries';
import { createSupplement, updateSupplement } from 'graphql/customMutations';
import { deleteSupplement } from 'graphql/mutations';
import { IMicroNutrientAnalysisPositionItem, ISupplement } from 'types/microNutrientAnalysis';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';

const supplementColors = {
	notActive: '#80817E',
	notAvailable: '#A106A6',
	notFound: '#AB2306',
	notRequired: '#1A6600',
};

const SupplementsAdministrationFormular: FC = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [supplements, setSupplements] = useState<ISupplement[]>();
	const [supplementsFiltered, setSupplementsFiltered] = useState<ISupplement[]>();
	const [currentSupplement, setCurrentSupplement] = useState<ISupplement>();

	const { enqueueMessage } = useMessage();
	const { listItems, editItem } = AWSAppSyncProvider();

	const iOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	/**
     *
     * @param {import('types/microNutrientAnalysis').ISupplement} supplement
     */
	const handleSaveSupplement = useCallback(async (supplement: ISupplement) => {
		if (!supplement) return;

		let err = false;
		if (!supplement.names?.length) {
			err = true;
			enqueueMessage('SupplementAdministrationFormular', Exceptions.MISSING_INFORMATION);
		}

		if (_.some(supplement.names, (newName) => _.some(supplements, (sup) => (sup.id !== supplement.id && _.some(sup.names, (name) => name === newName))))) {
			err = true;
			enqueueMessage('SupplementAdministrationFormular', Exceptions.DUPLICATE_ENTRY);
		}

		if (err) return;

		// replace empty values with nulls
		let prepareSupplement: Partial<ISupplement> = _.pick(supplement, 'id', 'pcn', 'names', 'dosages', 'active', 'noProductAvailable', 'description');

		if (prepareSupplement.id === '00000000-0000-0000-0000-000000000000') {
			prepareSupplement = _.omit(prepareSupplement, 'id');
		}

		if (prepareSupplement.noProductAvailable) {
			prepareSupplement.dosages = [];
		}

		if ((_.some(prepareSupplement.dosages, (d) => !d.mgDayKg100))) {
			enqueueMessage(`Supplement_${prepareSupplement.id}`, Exceptions.MISSING_INFORMATION);
			return;
		}

		setIsLoading(true);
		try {
			let sup: ISupplement;

			// update or create record
			if (prepareSupplement.id) { // update
				sup = await editItem(updateSupplement, { input: prepareSupplement });
				setSupplements((current) => _.map(current, (s) => {
					if (s.id === supplement.id) {
						return { ...s, ...sup };
					}
					return s;
				}));
			} else { // create
				sup = await editItem(createSupplement, { input: prepareSupplement });
				setSupplements((current) => [sup, ...current ?? []]);
			}

			setCurrentSupplement((current) => ({ ...current, ...sup }));

			enqueueMessage(`Supplement_${prepareSupplement.id}`, Messages.API_SAVE_SUCCESSFUL);
		} catch {
			enqueueMessage(`Supplement_${prepareSupplement.id}`, Exceptions.API_SAVE_ERROR);
		} finally {
			setIsLoading(false);
		}
	}, [updateSupplement, createSupplement, supplements,
		Messages.API_SAVE_SUCCESSFUL, Exceptions.API_SAVE_ERROR, Exceptions.DUPLICATE_ENTRY, Exceptions.MISSING_INFORMATION,
		setIsLoading, enqueueMessage]);

	/**
     * @param {import('types/microNutrientAnalysis').ISupplement} supplement
     * @param {String} [nextToken]
     * @param {import('types/microNutrientAnalysis').IMicroNutrientAnalysisPositionItem[]} [prevResponse]
     * @returns {Promise<import('types/microNutrientAnalysis').IMicroNutrientAnalysisPositionItem[]>}
     */
	const loadMicroNutrientAnalysisPositionItems = async (supplement: ISupplement) => {
		// eslint-disable-next-line max-len
		const mnapo: IMicroNutrientAnalysisPositionItem[] = await listItems(listMicroNutrientAnalysisPositionItems, { filter: { microNutrientAnalysisPositionItemSupplementId: { eq: supplement.id } }, limit: 100 }, true);
		return mnapo;
	};

	const _filterSupplements = useCallback((filter: string) => {
		if (!filter) {
			setSupplements(supplements);
		}
		const filterString = filter.toLocaleLowerCase();
		const filtered: ISupplement[] = _.filter(supplements, (supplement) => {
			if (_.some(supplement.names, (n) => n.toLowerCase().includes(filterString))) {
				return true;
			}
			if (_.some(supplement.dosages, (dosage) => dosage.pcn.toLocaleLowerCase().includes(filterString))) {
				return true;
			}
			if (supplement.description?.toLocaleLowerCase().includes(filterString)) {
				return true;
			}
			return false;
		});

		setSupplementsFiltered(filtered);
	}, [supplements]);

	const _filter = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
		_filterSupplements(event.target.value);
	}, 200);

	// eslint-disable-next-line function-paren-newline
	const _handleDeleteSupplement = useCallback(async (supplement: ISupplement) => {
		if (!supplement?.id || supplement.id === '00000000-0000-0000-0000-000000000000') {
			throw new Error('Cannot delete the initial supplement');
		}

		setIsLoading(true);
		try {
			const ret = await loadMicroNutrientAnalysisPositionItems(supplement);
			if (!_.isEmpty(ret)) {
				enqueueMessage(`Supplement_${supplement.id}`, Exceptions.API_DELETE_NOT_POSSIBLE);
				return ret;
			}

			await API.graphql(graphqlOperation(deleteSupplement, { input: { id: supplement.id } }));
			setSupplements((current) => _.filter(current, (s) => s.id !== supplement.id));
			setCurrentSupplement(undefined);
			enqueueMessage(`Supplement_${supplement.id}`, Messages.API_DELETE_SUCCESSFUL);
		} catch {
			enqueueMessage(`Supplement_${supplement.id}`, Exceptions.API_DELETE_ERROR);
		} finally {
			setIsLoading(false);
		}
		return [];
	}, [deleteSupplement, listLogs,
		Messages.API_DELETE_SUCCESSFUL, Exceptions.API_DELETE_ERROR,
		setIsLoading, enqueueMessage]);

	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			try {
				const supps: ISupplement[] = _.orderBy(await listItems(listSupplements, {}, true), (sup) => _.first(sup.names), ['asc']);
				setSupplements(supps);
				setSupplementsFiltered(supps);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				enqueueMessage('SupplementsAdministrationFormular', Exceptions.API_LOAD_ERROR);
			} finally {
				setIsLoading(false);
			}
		};
		load();
	}, [listSupplements, enqueueMessage, graphqlOperation, setIsLoading, setSupplements]);

	const supplementList = (
		<Box>
			<TextField
				label="Suche / Filter"
				variant="standard"
				onChange={ _filter }
				fullWidth
			/>
			<List
				dense
				style={{ minWidth: '350px',
					width: isMobile ? '100%' : '350px',
					maxHeight: isDesktop ? 'calc(100vH - 15rem)' : 'inerhit',
					overflow: 'scroll',
				}}
			>
				<ListItem key="new" disablePadding>
					<ListItemButton
						onClick={ () => {
							setCurrentSupplement({
								id: '00000000-0000-0000-0000-000000000000',
								names: [],
								dosages: [],
								noProductAvailable: true,
							}); setDrawerOpen(false);
						} }
						sx={{ backgroundColor: (currentSupplement && !currentSupplement.id) ? 'primary.main' : 'unset' }}
					>
						<ListItemText primary="Neues Supplement anlegen" />
						<ListItemIcon>
							<Add />
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
				{ supplementsFiltered?.map((supplement) => (
					<ListItem key={ supplement.id } disablePadding>
						<ListItemButton
							onClick={ () => { setCurrentSupplement(supplement); setDrawerOpen(false); } }
							sx={{
							// eslint-disable-next-line no-nested-ternary
								color: !supplement.active ? supplementColors.notActive
									: (supplement.noProductAvailable ? supplementColors.notAvailable : 'inherit'),
								backgroundColor: (supplement.id === currentSupplement?.id) ? 'primary.main' : 'unset',
							}}
						>
							<ListItemText primary={ _.join(supplement.names, ', ') } />
							<ListItemIcon>
								<ArrowRight />
							</ListItemIcon>
						</ListItemButton>
					</ListItem>
				)) }
			</List>
		</Box>
	);

	return (
		<Box>
			{ (!isDesktop) && (
				<Button
					onClick={ () => setDrawerOpen(true) }
					style={{ marginBottom: '2rem' }}
					startIcon={ <Menu color="primary" /> }
				>
					Liste öffnen
				</Button>
			) }
			<Box style={{
				minHeight: '50vH', display: 'flex',
			}}
			>

				{ (!isDesktop && drawerOpen) && (
					<SwipeableDrawer
						anchor="left"
						open={ drawerOpen }
						onClose={ () => setDrawerOpen(false) }
						onOpen={ () => setDrawerOpen(true) }
						disableBackdropTransition={ !iOS }
						disableDiscovery={ iOS }
						PaperProps={{ sx: { width: isMobile ? '100%' : 'inerhit' } }}
					>
						<Box style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
							<Button onClick={ () => setDrawerOpen(false) } startIcon={ <Close /> }>Liste schließen</Button>
							{ supplementList }
						</Box>
					</SwipeableDrawer>
				) }
				{ isDesktop && supplementList }
				<Box style={{
					flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '2rem', paddingLeft: isDesktop ? '2rem' : '0rem',
				}}
				>
					{ !currentSupplement && <>Bitte ein Supplement aus der Liste auswählen oder ein neues anlegen</> }
					{ currentSupplement && (
						<SupplementFormular
							disabled={ loading }
							currentSupplement={ currentSupplement }
							handleSaveSupplement={ handleSaveSupplement }
							handleDeleteSupplement={ _handleDeleteSupplement }
						/>
					) }
				</Box>
			</Box>
		</Box>
	);
};

export { SupplementsAdministrationFormular };
