/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://kk2dyamvufc2xiwqvy5yqhl5jm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-btqgtcbmyrbilm2f2jz73n74ue",
    "aws_cognito_identity_pool_id": "eu-central-1:d59806f0-fd65-400e-ad71-647477c63c27",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_73Na9itm5",
    "aws_user_pools_web_client_id": "5gqdm2sjdt3nhb5bd5t9ql9oaj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "crma991bbf2a15444fd84a17307d1f38d2181723-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
