export const getCustomerBaseValue = (type: 'human' | 'dog' | 'horse') => {
	switch (type) {
		case 'dog': return 'Hund';
		case 'horse': return 'Pferd';
		default: return 'Mensch';
	}
};

export const getCustomerBaseKey = (base: 'Mensch' | 'Hund' | 'Pferd') => {
	switch (base) {
		case 'Hund': return 'dog';
		case 'Pferd': return 'horse';
		default: return 'human';
	}
};
