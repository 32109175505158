/* eslint-disable max-len */
import {
	Alert, Box, Button, Checkbox, Grid, LinearProgress, Slider, TextField, Typography,
} from '@mui/material';
import _ from 'lodash';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IPharmaOfferRequest, IPharmaOfferRequestPositionItem, PublicPharmaOfferRequestFormularProps, supplementBaseType } from 'types/microNutrientAnalysis';
import { formatDateTimeString } from 'helper/formatDate';
import { Divider } from 'components/Divider/Divider';
import { useSearchParams } from 'react-router-dom';

const defaults = {
	intakeDaysRecommendation: 30,
	recommendedPrice: 15,
	surchargeFaktor: 1.3,
	workSurcharge: (weight: number) => {
		if (weight < 1000) return 0.67 * (weight / 10);
		return 11;
	},
};

// prices per day that are acceptable (spot) to high
const priceSpotPerDay: Record<supplementBaseType, {spot: number, mid: number, high: number}> = {
	human: { spot: 1.6, mid: 2.6, high: 2.9 },
	dog: { spot: 2.6, mid: 4, high: 5 },
	horse: { spot: 5, mid: 7.6, high: 9.6 },
};

const PublicPharmaOfferRequestFormular: FC<PublicPharmaOfferRequestFormularProps> = ({ microNutrientAnalysis, pharmaOfferRequest, supplementProducts, handleSubmit }) => {
	const [currentPharmaOfferRequest, setCurrentPharmaOfferRequest] = useState<IPharmaOfferRequest|undefined>(pharmaOfferRequest);
	const [relevantPositionItems, setRelevantPositionItems] = useState<string[]>([]);
	const [isSending, setIsSending] = useState(false);
	const [searchParams] = useSearchParams();

	const [sent, setSent] = useState(false);

	const log = useMemo(() => microNutrientAnalysis.log, [microNutrientAnalysis]);
	const customer = useMemo(() => log?.customer, [log]);

	const _handleChange = useCallback((newValue: Partial<IPharmaOfferRequest>) => {
		setCurrentPharmaOfferRequest((current) => ({
			microNutrientAnalysisPharmaOfferRequestsId: microNutrientAnalysis.id,
			intakeDaysRecommendation: defaults.intakeDaysRecommendation, // necessary props
			...current, // current state
			...newValue, // new partial state
		}));
	}, []);

	const _handleChangePosition = useCallback((positionItem: IPharmaOfferRequestPositionItem) => {
		_handleChange({
			positions: _.map(currentPharmaOfferRequest?.positions, (currentPositionItem) => {
				if (currentPositionItem.pharmaOfferRequestPositionItemSupplementId === positionItem.pharmaOfferRequestPositionItemSupplementId) return positionItem;
				return currentPositionItem;
			}),
		});
	}, [currentPharmaOfferRequest?.positions, _handleChange]);

	const _isRelevant = (positionItem: IPharmaOfferRequestPositionItem) => _.includes(relevantPositionItems, positionItem.pharmaOfferRequestPositionItemSupplementId);
	const _addRelevance = (positionItem: IPharmaOfferRequestPositionItem) => {
		setRelevantPositionItems((current) => [...current, positionItem.pharmaOfferRequestPositionItemSupplementId]);
	};
	const _removeRelevance = (positionItem: IPharmaOfferRequestPositionItem) => {
		setRelevantPositionItems((current) => _.filter(current, (sid) => sid !== positionItem.pharmaOfferRequestPositionItemSupplementId));
	};

	const _handleSubmit = async () => {
		if (!currentPharmaOfferRequest) return;
		setIsSending(true);
		setSent(await handleSubmit({
			...currentPharmaOfferRequest,
			positions: _.filter(currentPharmaOfferRequest.positions, _isRelevant),
		}));
		setIsSending(false);
	};

	const calculateEstimatedPrice = (positionItem: IPharmaOfferRequestPositionItem) => {
		if (
			!currentPharmaOfferRequest
			|| !currentPharmaOfferRequest?.weight
			|| !currentPharmaOfferRequest?.intakeDaysRecommendation
			|| !positionItem.requirement
		) return { requiredSupplementAmount: 0, estimatedPrice: 0 };

		const dosage = _.find(positionItem?.supplement?.dosages, (d) => d.base === positionItem.base);
		if (dosage) {
			const product = _.find(supplementProducts, (pro) => pro.pcn === dosage?.pcn);
			if (product) {
				const requirementMGDay = (
					dosage.mgDayKg100
					* (positionItem.requirement / 100)
					* currentPharmaOfferRequest.weight);

				const requiredSupplementAmount = Math.ceil((requirementMGDay / (product.mgCore / product.quantity)) * currentPharmaOfferRequest.intakeDaysRecommendation);
				return {
					requiredSupplementAmount,
					estimatedPrice: Math.ceil(((requiredSupplementAmount / product.quantity) * (product.recommendedPrice ?? defaults.recommendedPrice)) * defaults.surchargeFaktor),
				};
			}
		}
		return { requiredSupplementAmount: 0, estimatedPrice: 0 };
	};

	const priceColor = useCallback((estimatedPrice: number) => {
		if (!customer?.type) return 'success';
		if (estimatedPrice > priceSpotPerDay[customer?.type].mid) return 'error';
		if (estimatedPrice > priceSpotPerDay[customer?.type].spot) return 'warning';
		return 'success';
	}, [customer?.type]);

	const positionPrices = Object.fromEntries(
		_.compact(
			_.map(
				currentPharmaOfferRequest?.positions,
				(currentPositionItem) => {
					if (
						_isRelevant(currentPositionItem)
						&& currentPositionItem.pharmaOfferRequestPositionItemSupplementId
					) {
						return [currentPositionItem.pharmaOfferRequestPositionItemSupplementId, calculateEstimatedPrice(currentPositionItem)];
					}
					return false;
				},
			),
		),
	);
	const supplementImpactFactor = (currentPharmaOfferRequest?.positions?.length ?? 1 * 0.25);
	const weightSum = _.sum(_.values(_.map(positionPrices, (p) => p.requiredSupplementAmount)));
	const priceSum = _.sum(_.values(_.map(positionPrices, (p) => p.estimatedPrice))) + defaults.workSurcharge(weightSum);
	const overallColor = priceColor(priceSum / (currentPharmaOfferRequest?.intakeDaysRecommendation ?? 1));

	const priceProgressValue = useMemo(() => {
		if (!currentPharmaOfferRequest?.intakeDaysRecommendation || !customer?.type) return 0;
		const estimatedPrice = priceSum / currentPharmaOfferRequest.intakeDaysRecommendation;
		const calc = (s: number) => Math.min(100, (estimatedPrice / s) * 100);
		if (estimatedPrice > priceSpotPerDay[customer?.type].mid) return calc(priceSpotPerDay[customer?.type].high);
		if (estimatedPrice > priceSpotPerDay[customer?.type].spot) return calc(priceSpotPerDay[customer?.type].mid);
		return calc(priceSpotPerDay[customer?.type].spot);
	}, [priceSum, customer?.type]);

	useEffect(() => {
		const positions: IPharmaOfferRequestPositionItem[] = _.map(
			microNutrientAnalysis.positions,
			(position) => ({
				id: '00000000-0000-0000-0000-000000000000',
				supplement: position.supplement,
				pharmaOfferRequestPositionItemSupplementId: position.microNutrientAnalysisPositionItemSupplementId,
				base: position.base,
				requirement: position.requirement,
				requirementRecommendation: position.requirement,
				pharmaOfferRequestPositionsId: 'new',
			}),
		);
		_.forEach(positions, (pos) => {
			if (pos.requirementRecommendation > 70) {
				_addRelevance(pos);
			}
		});
		setCurrentPharmaOfferRequest((current) => ({
			...current,
			intakeDaysRecommendation: current?.intakeDaysRecommendation ?? microNutrientAnalysis.intakeDaysRecommendation,
			weight: customer?.weight,
			notes: current?.notes ?? log?.anamnesis,
			positions: current?.positions ?? _.orderBy(positions, 'requirementRecommendation', 'desc'),
		}));
	}, [microNutrientAnalysis]);

	if (!customer) {
		return (
			<Box>
				<Alert severity="error">
					Leider fehlen essenzielle Informationen! Sie können sich an Ellen Fuhrmann / office@the-dogs.at wenden
				</Alert>
			</Box>
		);
	}

	if (!customer.consent) {
		return (
			<Box>
				<Alert severity="error">
					Sie haben einer Weitergabe der Daten nicht zugestimmt! Somit ist es nicht möglich eine Angebot von unserer Partnerapotheke zu erhalten.
				</Alert>
			</Box>
		);
	}

	const submitButton = (
		<>
			<Alert
				severity={ (priceSum >= 5) ? overallColor : 'info' }
			>
				{ (priceSum >= 5) && (
					<>
						Das Angebot wird im
						{ overallColor === 'error' && ' hohen ' }
						{ overallColor === 'warning' && ' erhöhten ' }
						{ overallColor === 'success' && ' normalen ' }
						Preissegment liegen
					</>
				) }
				{ (priceSum < 5) && (
					<>
						Nicht bestellbar
					</>
				) }
			</Alert>
			<Button
				fullWidth
				variant="contained"
				color="success"
				onClick={ _handleSubmit }
				disabled={ isSending || priceSum < 5 }
			>
				unverb. Angebot anfordern
			</Button>
		</>
	);

	return (
		<>
			{ sent && (
				<Box>
					<Alert severity="success" action={ <Button onClick={ () => setSent(false) }>Angebot bearbeiten</Button> }>
						Der Anfrage wurde an die Apotheke übermittelt!
					</Alert>
					<Box marginTop="2rem">
						<Typography fontWeight="bold" display="inline" marginRight="0.7rem">Sie werden ein unverbindliches Angebot telefonisch oder via E-Mail erhalten.</Typography>
					</Box>
				</Box>
			) }
			{ (!sent) && (
				<>
					<h2 style={{ marginBottom: '2rem' }}>Angebot anfordern</h2>
					<Alert severity="info">Unsere Partnerapotheke ist die Graben-Apotheke Mag.pharm. Sigrid Derflinger KG., 1010 Wien</Alert>
					<Divider />
					<h3 style={{ marginTop: '2rem' }}>{ `Hallo ${customer?.nameOwner ?? customer?.name},` }</h3>
					<Box style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
						<Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
							Im nachstehenden Formular sehen Sie die relevanten Ergebnisse Ihrer Mikronährstoffanalyse
							{ log?.date && ` vom ${formatDateTimeString(log.date, 'dd.MM.yyyy')}` }
							. Durch etwaige Anpassung der Dosierungen und Einnahmetage, können Sie bestimmen, was von der Apotheke angeboten werden soll.
						</Typography>
						<Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
							Ziehen Sie den Schieberegler nach links oder rechts um mehr oder weniger von dem empfohlenen Supplement anzufordern.
							Die empfohlene Dosis wird am Ende des Schiebereglers angezeigt und kann per Klick zurückgesetzt werden.
						</Typography>
						<TextField
							variant="standard"
							multiline
							value={ currentPharmaOfferRequest?.notes ?? '' }
							onChange={ (event) => _handleChange({ notes: event.target.value }) }
							disabled={ isSending }
							helperText="Sonstige Anmerkungen an die Apotheke (z.B. Allergien, Unverträglichkeiten)"
							fullWidth
						/>
						{ !!microNutrientAnalysis.notesCustomer
						&& (
							<Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
								{ microNutrientAnalysis.notesCustomer }
							</Typography>
						) }
					</Box>
					{ currentPharmaOfferRequest?.positions && (
						<Box sx={{ marginY: '2rem' }}>
							<Alert severity="success">
								Der Balken nimmt zu, je günstiger der zu erwartende Preis!
								{ searchParams?.get('ep') && <Typography width="30px">{ Math.round((priceSum * 100)) / 100 }</Typography> }
							</Alert>
							<LinearProgress
								value={ 100 - priceProgressValue }
								variant="determinate"
								style={{ marginBottom: '2rem', height: '1rem' }}
								color={ overallColor }
							/>
							<Grid container rowSpacing="1rem" columnSpacing="0.5rem">
								<Grid item xs={ 12 } sm={ 4 }><Typography width="200px" noWrap>Einnahmetage</Typography></Grid>
								<Grid item xs={ 12 } sm={ 8 } style={{ display: 'flex', flexWrap: 'nowrap', gap: '2rem' }}>
									<Slider
										max={ 90 }
										min={ 14 }
										value={ currentPharmaOfferRequest?.intakeDaysRecommendation }
										onChange={ (event, value) => _handleChange({ intakeDaysRecommendation: Number(value) }) }
										valueLabelDisplay="auto"
										disabled={ isSending }
									/>
									<Button
										style={{ width: '50px' }}
										variant="outlined"
										onClick={ () => _handleChange({ intakeDaysRecommendation: Number(microNutrientAnalysis.intakeDaysRecommendation) }) }
										disabled={ isSending }
									>
										{ microNutrientAnalysis.intakeDaysRecommendation }
									</Button>
								</Grid>
								<Grid item xs={ 12 }><Divider style={{ height: '0.2rem' }} /></Grid>
								{ _.map(currentPharmaOfferRequest.positions, (position) => {
									const c: 'error' | 'warning' | 'success' | 'secondary' = position.pharmaOfferRequestPositionItemSupplementId
										? priceColor(((positionPrices[position.pharmaOfferRequestPositionItemSupplementId]?.estimatedPrice ?? 0) / currentPharmaOfferRequest.intakeDaysRecommendation) * supplementImpactFactor)
										: 'secondary';
									return (
										<Fragment key={ position.pharmaOfferRequestPositionItemSupplementId }>
											<Grid item xs={ 4 } sm={ 1 }>
												<Checkbox
													style={{ bottom: '0.4rem' }}
													checked={ _isRelevant(position) }
													onClick={ () => (_isRelevant(position)
														? _removeRelevance(position)
														: _addRelevance(position)) }
												/>
											</Grid>
											<Grid item xs={ 8 } sm={ 3 }>
												<Typography
													noWrap
													color={ _isRelevant(position) ? 'inherit' : 'secondary' }
												>
													{ _.join(position.supplement?.names, ',') }
												</Typography>
											</Grid>
											<Grid item xs={ 12 } sm={ 8 } style={{ display: 'flex', flexWrap: 'nowrap', gap: '2rem' }}>
												<Slider
													max={ 100 }
													min={ 0 }
													value={ position?.requirement }
													color={ c }
													onChange={ (event, value) => _handleChangePosition({
														...position,
														requirement: Number(value),
													}) }
													valueLabelDisplay="auto"
													disabled={ isSending || !_isRelevant(position) }
												/>
												{ searchParams?.get('ep') && <Typography width="30px">{ Math.round(((positionPrices[position.pharmaOfferRequestPositionItemSupplementId]?.estimatedPrice ?? 0) * 100)) / 100 }</Typography> }
												<Button
													style={{ width: '50px' }}
													variant="outlined"
													onClick={ () => _handleChangePosition({
														...position,
														requirement: position.requirementRecommendation,
													}) }
													disabled={ isSending || !_isRelevant(position) }
												>
													{ position.requirementRecommendation }
												</Button>
											</Grid>
											{ position.supplement?.description && <Grid item xs={ 12 }><Typography fontStyle="italic" fontSize="0.9rem" sx={{ marginX: '2rem' }}>{ position.supplement?.description }</Typography></Grid> }
										</Fragment>
									);
								}) }
							</Grid>
						</Box>
					) }
					{ submitButton }
				</>
			) }
		</>
	);
};

export { PublicPharmaOfferRequestFormular };
