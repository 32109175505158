import React from 'react';

// PACKAGES
import _ from 'lodash';
// MATERIAL UI
import { Button, Slide } from '@mui/material';
import { Close } from '@mui/icons-material';
// SNACKBAR
import { SnackbarProvider, useSnackbar } from 'notistack';

/**
 * The SnackBarActions component.
 *
 * @memberof MessageProvider
 * @param {object} props - properties passed to the component.
 * @param {string} props.messageKey - key of the message to be displayed for the test.
 * @returns {React.ReactElement} The SnackBarActions component.
 */
const SnackBarActions = ({ messageKey }) => {
	const { closeSnackbar } = useSnackbar();
	return (
		<Button
			// split the key to get the messageKey to identify the button and trow away the id
			data-test={ `${_.split(messageKey, '####')[0]}_close` }
			onClick={ () => closeSnackbar(messageKey) }
		>
			<Close />
		</Button>
	);
};

const actionComp = (key) => <SnackBarActions messageKey={ key } />;

/**
 * The wrapper for the MessageProvider component.
 *
 * @memberof Frontend.Theme
 * @param {object} props - properties passed to the component.
 * @param {object} props.children - children of the MessageProvider component.
 * @returns {React.ReactElement} MessageProvider component.
 */
const MessageProvider = ({ children }) => (
	<SnackbarProvider
		// preventDuplicate // when ids are chosen to prove a save operation was good, the duplication prevention is not applicable
		maxSnack={ 5 }
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		TransitionComponent={ Slide }
		action={ actionComp }
		autoHideDuration={ 2000 }
	>
		{ children }

	</SnackbarProvider>
);

export { MessageProvider };
