import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoints = () => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return {
		isDesktop,
		isMobile,
	};
};
export { useBreakpoints };
