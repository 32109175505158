import { Grid } from '@mui/material';
import styles from 'components/Divider/divider.module.scss';

/**
 *
 * @param {import('@mui/material').GridProps} props
 * @returns
 */
const Divider = (props) => (
	<Grid container height="1rem" { ...props } className={ styles.dividerContainer }>
		<Grid item xs={ 3 } />
		<Grid item xs={ 3 } />
		<Grid item xs={ 3 } />
		<Grid item xs={ 3 } />
	</Grid>
);

export { Divider };
