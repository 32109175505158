import { Logout } from '@mui/icons-material';
import {
	Box, Button, useMediaQuery, useTheme,
} from '@mui/material';
import logo from 'images/AppLogo.png';

/**
 * @param {object} props - props for the component
 * @param {()=>void} [props.signOut] - Header component
 * @param {JSX.Element} [props.additionalHeaderComponent] - additional component to be rendered
 * @returns {React.ReactComponentElement} component to be shown
 */
const Header = ({ signOut, additionalHeaderComponent }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Box
			component="header"
			sx={{
				height: '4rem',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Box component="img" src={ logo } alt="logo" sx={{ height: '3rem', display: { xs: 'none', md: 'block' } }} />
			<Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1rem' }}>
				{ additionalHeaderComponent && additionalHeaderComponent }
				{ signOut && (
					<Button startIcon={ <Logout /> } className="textButton" variant="text" onClick={ signOut }>
						{ isMobile ? '' : 'Abmelden' }
					</Button>
				) }
			</Box>
		</Box>
	);
};

export { Header };
