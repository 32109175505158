/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-promise-reject-errors */
import {
	useRef, useEffect, useCallback,
} from 'react';
// PACKAGES
import _ from 'lodash';
import { ICancellablePromise } from 'types/cancellablePromise';

/**
 * ## Cancel Exception
 *
 * Indicates that a cancellation occurred
 */
class CancelException extends Error {
	constructor(message = 'Cancelled', options = {}) {
		super(message, options);
		this.name = 'CancelException';
	}
}

function makeCancelable(promise: Promise<any>, cancelFunction?: ((promise: Promise<any>) => void), finalFunction?: (() => void)) {
	let isCanceled = false;
	// create a new promise and wrap the passed promise inside
	const wrappedPromise = new Promise((resolve, reject) => {
		// when the isCanceled boolean turns to true, the promise will be canceled
		promise
			.then((val) => (isCanceled ? reject(new CancelException('Canceled as a Cancellable Promise')) : resolve(val)))
			.catch((error) => (isCanceled ? reject(new CancelException('Canceled as a Cancellable Promise')) : reject(error)))
			.finally(finalFunction);
	});
	const ret: ICancellablePromise = {
		promise: wrappedPromise,
		cancel() {
			isCanceled = true;
			if (_.isFunction(cancelFunction)) {
				// console.log('cancel promise');
				// process the passed cancelation function
				cancelFunction(promise);
			}
		},
	};
	return ret;
}

/**
 * This hook can be used to wrap promises, so they get canceled in case of a page change
 *
 * @returns {{cancellablePromise: (p: any, cancelFunction: any) => void}} The wrapped promises
 */

function useCancellablePromise() {
	// think of useRef as member variables inside a hook
	// you cannot define promises here as an array because
	// they will get initialized at every render refresh
	const promises = useRef<ICancellablePromise[]>([]);
	// useEffect initializes the promises array
	// and cleans up by calling cancel on every stored
	// promise.
	// Empty array as input to useEffect ensures that the hook is
	// called once during mount and the cancel() function called
	// once during unmount
	useEffect(() => {
		promises.current = promises.current || [];
		return () => {
			promises.current.forEach((p) => p.cancel());
			promises.current = [];
		};
	}, [promises]);

	// cancelablePromise remembers the promises that you
	// have called so far. It returns a wrapped cancelable
	// promise
	const cancellablePromise = useCallback((p: Promise<any>, cancelFunction?: ((promise: Promise<any>) => void)) => {
		const cPromise = makeCancelable(p, cancelFunction, () => _.remove(promises.current, (pro) => pro.promise === p));
		promises.current.push(cPromise);
		return cPromise.promise;
	}, [promises, makeCancelable]);
	return { cancellablePromise };
}

export { useCancellablePromise, makeCancelable, CancelException };
