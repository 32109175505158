import {
	Box, TextField, Grid, Button, useTheme, useMediaQuery,
} from '@mui/material';

import _ from 'lodash';
import { formatDateTimeString } from 'helper/formatDate';
import { Divider } from 'components/Divider/Divider';
import { DataGrid } from '@mui/x-data-grid';
import { PictureAsPdf } from '@mui/icons-material';
import { useCallback } from 'react';
import { downloadBase64File } from 'helper/download';
import { getInvoicePaymentOption } from 'helper/typeHelper';

/**
 * @param {object} props - props for the component
 * @param {import('types/invoice').IInvoice} props.currentInvoice - the current product
 * @returns {React.ReactComponentElement} component to be shown
 */
const InvoiceFormular = ({ currentInvoice }) => {
	const downloadInvoice = useCallback(() => {
		downloadBase64File(currentInvoice.pdf, `${currentInvoice.number}_TDTH.pdf`);
	}, [currentInvoice?.pdf, downloadBase64File]);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	/** @type {import('@mui/x-data-grid').GridColDef[]} */
	const columns = [
		{
			field: 'order',
			headerName: 'Pos.',
			width: 50,
			type: 'number',
		},
		{
			field: 'description',
			headerName: 'Leistungsbeschreibung',
			width: 350,
			editable: false,
			type: 'string',
		},
		{
			field: 'price',
			headerName: 'Preis/Einheit',
			width: 130,
			editable: false,
			type: 'number',
			renderCell: (params) => `${params.row.price} €`,
		},
		{
			field: 'quantity',
			headerName: 'Anzahl',
			width: 90,
			editable: false,
			type: 'number',
		},
		{
			field: 'unit',
			headerName: 'Einheit',
			width: 120,
			align: 'center',
			headerAlign: 'center',
			editable: false,
			type: 'singleSelect',
			valueOptions: ['h', 'stk', 'pauschal'],
		},
		{
			field: 'sum',
			headerName: 'Summe',
			headerAlign: 'right',
			width: 130,
			editable: false,
			type: 'string',
			align: 'right',
			renderCell: (params) => `${Number(params.row.price) * Number(params.row.quantity)} €`,
		},
	];

	return (
		<Box style={{
			flexGrow: 1, display: 'flex', width: '100%', flexDirection: 'column', gap: '2rem',
		}}
		>
			<Grid container spacing={ 2 }>
				<Grid item xs={ 12 }>
					<TextField
						label="Name"
						variant="standard"
						value={ currentInvoice.customer?.nameOwner ?? currentInvoice.customer?.name }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } lg={ 6 }>
					<TextField
						label="Anschrift"
						variant="standard"
						multiline
						value={ currentInvoice.customer?.address ?? '' }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 4 } lg={ 2 }>
					<TextField
						label="Postleitzahl"
						variant="standard"
						value={ currentInvoice.customer?.zip ?? '' }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 8 } lg={ 2 }>
					<TextField
						label="Stadt"
						variant="standard"
						multiline
						value={ currentInvoice.customer?.city ?? '' }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } lg={ 2 }>
					<TextField
						label="Land"
						variant="standard"
						multiline
						value={ currentInvoice.customer?.country ?? '' }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label="Telefonnummer"
						variant="standard"
						value={ currentInvoice.customer?.phone ?? '' }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						label="E-Mail Adresse"
						variant="standard"
						value={ currentInvoice.customer?.email ?? '' }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<Divider />
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						variant="standard"
						value={ currentInvoice.number ?? '' }
						fullWidth
						disabled
						helperText="Rechnungsnummer"
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						helperText="Rechnungsdatum"
						variant="standard"
						type="datetime-local"
						value={ _.replace(formatDateTimeString(currentInvoice.date, 'yyyy-MM-dd#HH:mm'), '#', 'T') ?? '' }
						disabled
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						helperText="Zahlungsziel"
						variant="standard"
						type="datetime-local"
						value={ _.replace(formatDateTimeString(currentInvoice.dueDate, 'yyyy-MM-dd#HH:mm'), '#', 'T') ?? '' }
						disabled
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						helperText="Rechnung gesendet am"
						variant="standard"
						type="datetime-local"
						value={ _.replace(formatDateTimeString(currentInvoice.submittedAt, 'yyyy-MM-dd#HH:mm'), '#', 'T') ?? '' }
						disabled
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						helperText="Zahlungsart"
						variant="standard"
						value={ (getInvoicePaymentOption(currentInvoice.paymentMethod ?? 'transfer')).label }
						disabled
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 }>
					<TextField
						helperText="Bezahlt"
						variant="standard"
						value={ currentInvoice.paid ? 'Rechnung wurde bezahlt' : 'Rechnung ausständig' }
						disabled
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } width="100%">
					<DataGrid
						rows={ _.map(currentInvoice?.positions, (r, i) => ({
							...r,
							id: `${i}-${Math.floor(Math.random() * 1000)}`,
							order: i + 1,
						})) }
						columns={ columns }
						hideFooter
						autoHeight
						style={{ maxWidth: isDesktop ? 'calc(100vw - 350px - 6rem)' : 'calc(100vw - 4rem)' }}
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<TextField
						helperText="Notiz auf der Rechnung"
						variant="standard"
						value={ currentInvoice.bottomNotice ?? '' }
						multiline
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<Button
						variant="contained"
						disabled={ !currentInvoice?.pdf }
						onClick={ downloadInvoice }
						startIcon={ <PictureAsPdf /> }
						fullWidth
					>
						herunterladen
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export { InvoiceFormular };
