import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
// extreme important
import 'moment/locale/de';
import { FC, ReactNode } from 'react';

const theme = createTheme(
	{
		palette: {
			primary: {
				main: '#a79581',
			},
			secondary: {
				main: '#ebdebe',
			},
		},
	},
	deDE, // x-date-pickers translations
	coreDeDE, // core translations
);

const TemplateProvider: FC<{children: ReactNode}> = ({ children }) => {
	if (moment.locale() !== 'de') {
		moment.locale('de');
	}
	return (
		<LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale="de">
			<ThemeProvider theme={ theme }>
				{ children }
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export { TemplateProvider };
