import { Box, Skeleton } from '@mui/material';
import { submitPharmaOfferRequest } from 'graphql/customMutations';
import { getPublicMicroNutrientAnalysisPharmOfferRequest, listSupplementProducts } from 'graphql/customQueries';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';
import { useMessage } from 'hooks/useMessage';
import _ from 'lodash';
import { Exceptions } from 'messages/Exceptions';
import { Messages } from 'messages/Messages';
import { PublicPharmaOfferRequestFormular } from 'pages/log/microNutrientAnalysis/PublicPharmaOfferRequestFormular';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMicroNutrientAnalysis, IMicroNutrientAnalysisPositionItem, IPharmaOfferRequest, IPharmaOfferRequestPositionItem, ISupplementProduct } from 'types/microNutrientAnalysis';

import { Amplify } from 'aws-amplify';
import awsExports from 'aws-exports';

const getPCNFromPositionItem = (positionItem: IMicroNutrientAnalysisPositionItem) => {
	if (positionItem?.supplement?.dosages) {
		const dosage = _.find(positionItem?.supplement?.dosages, (d) => d.base === positionItem.base);
		return dosage?.pcn;
	}
	return '';
};

const PublicPharmaOfferRequestPage = () => {
	const urlParams = useParams();
	const [microNutrientAnalysis, setMicroNutrientAnalysis] = useState<IMicroNutrientAnalysis>();
	const [supplementProducts, setSupplementProducts] = useState<ISupplementProduct[]>();
	const [isLoading, setIsLoading] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const microNutrientAnalysisId = urlParams?.mnsId;

	const { getItem, listItems, editItem } = AWSAppSyncProvider();
	const { enqueueMessage } = useMessage();

	const handleSubmit = async (pharmOffer: IPharmaOfferRequest) => {
		if (!pharmOffer || !microNutrientAnalysis || !pharmOffer.positions) {
			enqueueMessage('PublicPharOfferRequestPage', Exceptions.MISSING_INFORMATION);
			return false;
		}
		try {
			const preparePharmaOfferRequest = {
				microNutrientAnalysisId: microNutrientAnalysis.id,
				logId: microNutrientAnalysis.log?.id,
				customerId: microNutrientAnalysis.log?.customer?.id,
				intakeDays: pharmOffer.intakeDaysRecommendation,
				positions: JSON.stringify(
					_.map(
						pharmOffer.positions,
						(pos: IPharmaOfferRequestPositionItem) => _.pick(
							pos,
							'base',
							'requirement',
							'pharmaOfferRequestPositionItemSupplementId',
						),
					),
				),
				notes: pharmOffer.notes,
				weight: pharmOffer.weight,
			};
			// console.log(preparePharmaOfferRequest);

			await editItem(submitPharmaOfferRequest, { input: preparePharmaOfferRequest });
			enqueueMessage('submit_anamnesis', Messages.DATA_SUBMITTED_SUCCESSFULLY);
			return true;
		} catch (err) {
			enqueueMessage('submit_anamnesis', Exceptions.GENERAL);
		}
		return false;
	};

	useEffect(() => {
		const load = async () => {
			try {
				setIsLoading(true);
				setMicroNutrientAnalysis(await getItem(getPublicMicroNutrientAnalysisPharmOfferRequest, { id: microNutrientAnalysisId }));
				setIsLoading(false);
			} catch (err) {
				enqueueMessage('PublicPharOfferRequestPage', Exceptions.API_LOAD_ERROR);
			}
		};

		if (!microNutrientAnalysis && !isLoading && microNutrientAnalysisId && initialized) load();
	}, [isLoading, initialized, microNutrientAnalysisId, getPublicMicroNutrientAnalysisPharmOfferRequest, Exceptions.API_LOAD_ERROR, enqueueMessage]);

	useEffect(() => {
		// remove cognito to allow api key
		Amplify.configure({ ..._.pickBy(awsExports, (v, key) => !key.startsWith('aws_cognito')), aws_appsync_authenticationType: 'API_KEY' });
		setInitialized(true);
	}, []);

	useEffect(() => {
		const load = async () => {
			try {
				setIsLoading(true);

				const pcns = _.compact(_.map(microNutrientAnalysis?.positions, getPCNFromPositionItem));
				const pcnFilter = { filter: {
					or: _.map(pcns, (pcn) => ({ pcn: { eq: pcn } })) },
				};
				setSupplementProducts(await listItems(listSupplementProducts, pcnFilter));
				setIsLoading(false);
			} catch (err) {
				enqueueMessage('PublicPharOfferRequestPage', Exceptions.API_LOAD_ERROR);
			}
		};

		if (!supplementProducts && microNutrientAnalysis && !isLoading) load();
	}, [isLoading, supplementProducts, microNutrientAnalysis,
		Exceptions.API_LOAD_ERROR, enqueueMessage, listItems]);

	if (!microNutrientAnalysis || !supplementProducts || isLoading) {
		return (
			<>
				<Skeleton variant="rectangular" height="3rem" width="100%" style={{ marginBottom: '1rem' }} />
				<Skeleton variant="rectangular" height="8rem" width="100%" style={{ marginBottom: '1rem' }} />
				<Skeleton variant="rectangular" height="3rem" width="100%" />
			</>
		);
	}

	return (
		<Box style={{
			width: '100%', maxWidth: '1200px', margin: 'auto', padding: '1rem', paddingTop: '2rem',
		}}
		>
			{ microNutrientAnalysis && (
				<PublicPharmaOfferRequestFormular
					microNutrientAnalysis={ microNutrientAnalysis }
					supplementProducts={ supplementProducts }
					handleSubmit={ handleSubmit }
				/>
			) }
		</Box>
	);
};

export { PublicPharmaOfferRequestPage };
