import { useAuthenticator } from '@aws-amplify/ui-react';
import { Autocomplete, Grid, Skeleton, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { updatePharmaOfferRequest } from 'graphql/customMutations';
import { getPharmaOfferRequest } from 'graphql/customQueries';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';
import { getCustomerBaseValue } from 'helper/customerBase';
import { formatDateTimeString } from 'helper/formatDate';
import { hasAccess } from 'helper/rightsManagement';
import { useMessage } from 'hooks/useMessage';
import _ from 'lodash';
import { Exceptions } from 'messages/Exceptions';
import { Messages } from 'messages/Messages';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentContainer } from 'templates/layout/ContentContainer';
import { IPharmaOfferRequest } from 'types/microNutrientAnalysis';

const PharmaOfferRequestPage: FC = () => {
	const { user } = useAuthenticator((context) => [context.user]);
	const [currentPharmaOfferRequest, setCurrentPharmaOfferRequest] = useState<IPharmaOfferRequest>();
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { getItem, editItem } = AWSAppSyncProvider();
	const urlParams = useParams();
	const { enqueueMessage } = useMessage();

	const _handleChange = useCallback(async (change: Partial<IPharmaOfferRequest>) => {
		if (!currentPharmaOfferRequest?.id || isSaving) return;
		setIsSaving(true);
		try {
			await editItem(updatePharmaOfferRequest, { input: { id: currentPharmaOfferRequest?.id, ...change } });
			setCurrentPharmaOfferRequest((current) => (current ? { ...current, ...change } : undefined));
			enqueueMessage(`PharmaOfferRequestPage_${currentPharmaOfferRequest.id}`, Messages.INVOICE_SUBMITTED_SUCCESSFULLY);
		} catch (err) {
			enqueueMessage(`PharmaOfferRequestPage_${currentPharmaOfferRequest.id}`, Exceptions.GENERAL);
		} finally {
			setIsSaving(false);
		}
	}, [isSaving, currentPharmaOfferRequest?.id, Messages.INVOICE_SUBMITTED_SUCCESSFULLY, Exceptions.GENERAL, editItem]);

	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			try {
				setCurrentPharmaOfferRequest(await getItem(getPharmaOfferRequest, { id: urlParams.pharmaOfferRequestId }));
				setIsLoading(false);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				enqueueMessage('PharmaOfferRequestPage', Exceptions.API_LOAD_ERROR);
			}
		};
		if (!isLoading && urlParams.pharmaOfferRequestId && !currentPharmaOfferRequest) load();
	}, [urlParams, getPharmaOfferRequest, Exceptions.API_LOAD_ERROR, enqueueMessage]);

	if (isLoading) {
		return (
			<>
				<Skeleton variant="rectangular" height="3rem" width="100%" style={{ marginBottom: '1rem' }} />
				<Skeleton variant="rectangular" height="8rem" width="100%" style={{ marginBottom: '1rem' }} />
				<Skeleton variant="rectangular" height="3rem" width="100%" />
			</>
		);
	}

	const columns: GridColDef[] = [
		{
			field: 'names',
			headerName: 'Supplememt',
			width: 250,
			type: 'string',
			renderCell: (params) => _.join(params.row.supplement.names, ','),
		},
		{
			field: 'requirement',
			headerName: 'Bedarf',
			headerAlign: 'right',
			width: 80,
			type: 'number',
			align: 'right',
		},
		{
			field: 'base',
			headerName: 'Grundlage',
			width: 250,
			type: 'string',
			renderCell: (params) => getCustomerBaseValue(params.row.base),
		},
	];

	return (
		<ContentContainer>
			<Grid container spacing={ 2 }>
				<Grid item xs={ 12 } sm={ 6 } md={ 3 }>
					<TextField
						label="Datum der Anfrage"
						variant="standard"
						type="datetime-local"
						value={ _.replace(formatDateTimeString(currentPharmaOfferRequest?.dateOfRequest, 'yyyy-MM-dd#HH:mm'), '#', 'T') ?? '' }
						disabled
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 } md={ 3 }>
					<TextField
						label="Gewünschte Einnahmetage"
						variant="standard"
						value={ `${currentPharmaOfferRequest?.intakeDaysRecommendation} Tage` }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 4 } md={ 2 }>
					<TextField
						label="Gewicht des Einnehmenden"
						variant="standard"
						value={ `${currentPharmaOfferRequest?.weight} kg` }
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 4 } md={ 2 }>
					<Autocomplete
						disablePortal
						options={ [{ key: false, value: 'Nein' }, { key: true, value: 'Ja' }] }
						value={{ key: currentPharmaOfferRequest?.offerSubmittedToCustomer, value: currentPharmaOfferRequest?.offerSubmittedToCustomer ? 'Ja' : 'Nein' }}
						getOptionLabel={ (o) => o.value }
						renderInput={ (params) => (
							<TextField
								{ ...params }
								label="Angebot versendet"
								variant="standard"
							/>
						) }
						onChange={ (event, selected) => _handleChange({ offerSubmittedToCustomer: selected?.key }) }
						style={{ maxWidth: 'unset' }}
						disabled={ currentPharmaOfferRequest?.offerCommissionedByCustomer || !hasAccess(user?.attributes?.email, 'pharma') }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 4 } md={ 2 }>
					<Autocomplete
						disablePortal
						options={ [{ key: false, value: 'Nein' }, { key: true, value: 'Ja' }] }
						value={{ key: currentPharmaOfferRequest?.offerCommissionedByCustomer, value: currentPharmaOfferRequest?.offerCommissionedByCustomer ? 'Ja' : 'Nein' }}
						getOptionLabel={ (o) => o.value }
						renderInput={ (params) => (
							<TextField
								{ ...params }
								label="Angebot angenommen"
								variant="standard"
							/>
						) }
						onChange={ (event, selected) => _handleChange({ offerCommissionedByCustomer: selected?.key }) }
						style={{ maxWidth: 'unset' }}
						disabled={ !currentPharmaOfferRequest?.offerSubmittedToCustomer || !hasAccess(user?.attributes?.email, 'pharma') }
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<DataGrid
						rows={ _.map(_.orderBy(currentPharmaOfferRequest?.positions, 'requirement', 'desc'), (r, i) => ({
							...r,
							id: `${i}-${Math.floor(Math.random() * 1000)}`,
							order: i + 1,
						})) }
						columns={ columns }
						hideFooter
						autoHeight
						style={{ maxWidth: 'calc(100vw - 4rem)' }}
					/>
				</Grid>
			</Grid>
		</ContentContainer>
	);
};

export { PharmaOfferRequestPage };
