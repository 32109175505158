export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      nameOwner
      type
      address
      zip
      city
      country
      phone
      email
      weight
      birthdate
      vaccination
      disease
      treatment
      notice
      avatar
      consent
      approvalEnlightenmentHumanApprovedAt
      approvalEnlightenmentHumanText
      approvalEnlightenmentHumanSignature
      approvalEnlightenmentAnimalApprovedAt
      approvalEnlightenmentAnimalText
      approvalEnlightenmentAnimalSignature
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      nameOwner
      type
      address
      zip
      city
      country
      phone
      email
      weight
      birthdate
      vaccination
      disease
      treatment
      notice
      avatar
      consent
      approvalEnlightenmentHumanApprovedAt
      approvalEnlightenmentHumanText
      approvalEnlightenmentHumanSignature
      approvalEnlightenmentAnimalApprovedAt
      approvalEnlightenmentAnimalText
      approvalEnlightenmentAnimalSignature
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      subject
      date
      notice
      anamnesis
      images
      customerLogsId
      closed
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      subject
      date
      notice
      anamnesis
      images
      customerLogsId
      logInvoiceId
      logInvoiceNumber
      logMicroNutrientAnalysisId
      closed
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CustomCreateInvoiceInput!
  ) {
    customCreateInvoice(input: $input) {
      id
      number
      date
      dueDate
      submittedAt
      bottomNotice
      positions {
        quantity
        description
        price
        unit
      }
      paid
      paymentMethod
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
  ) {
    updateInvoice(input: $input) {
      id
      number
      date
      dueDate
      submittedAt
      bottomNotice
      positions {
        quantity
        description
        price
        unit
      }
      paid
      paymentMethod
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
    }
  }
`;

export const submitInvoice = /* GraphQL */ `
  mutation SubmitInvoice(
    $input: SubmitInvoiceInput!
  ) {
    submitInvoice(input: $input) {
      id
      submittedAt
      pdf
    }
  }
`;

export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
  ) {
    deleteInvoice(input: $input) {
      id
    }
  }
`;

export const createSupplement = /* GraphQL */ `
  mutation CreateSupplement(
    $input: CreateSupplementInput!
    $condition: ModelSupplementConditionInput
  ) {
    createSupplement(input: $input, condition: $condition) {
      id
      names
      active
      noProductAvailable
      dosages {
        base
        mgDayKg100
        pcn
      }
      description
      createdAt
    }
  }
`;
export const updateSupplement = /* GraphQL */ `
  mutation UpdateSupplement(
    $input: UpdateSupplementInput!
    $condition: ModelSupplementConditionInput
  ) {
    updateSupplement(input: $input, condition: $condition) {
      id
      names
      active
      noProductAvailable
      dosages {
        base
        mgDayKg100
        pcn
      }
      description
      createdAt
    }
  }
`;

export const createSupplementProduct = /* GraphQL */ `
  mutation CreateSupplementProduct(
    $input: CreateSupplementProductInput!
    $condition: ModelSupplementProductConditionInput
  ) {
    createSupplementProduct(input: $input, condition: $condition) {
      pcn
      type
      quantity
      mgCore
      notice
      active
      recommendedPrice
    }
  }
`;
export const updateSupplementProduct = /* GraphQL */ `
  mutation UpdateSupplementProduct(
    $input: UpdateSupplementProductInput!
    $condition: ModelSupplementProductConditionInput
  ) {
    updateSupplementProduct(input: $input, condition: $condition) {
      pcn
      type
      quantity
      mgCore
      notice
      active
      recommendedPrice
    }
  }
`;

export const submitPharmaOfferRequest = /* GraphQL */ `
  mutation SubmitPharmaOfferRequest(
    $input: SubmitPharmaOfferRequestInput!
  ) {
    submitPharmaOfferRequest(input: $input) {
      id
      dateOfRequest
    }
  }
`;

export const updatePharmaOfferRequest = /* GraphQL */ `
  mutation UpdatePharmaOfferRequest(
    $input: UpdatePharmaOfferRequestInput!
    $condition: ModelPharmaOfferRequestConditionInput
  ) {
    updatePharmaOfferRequest(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createMicroNutrientAnalysis = /* GraphQL */ `
  mutation CreateMicroNutrientAnalysis(
    $input: CreateMicroNutrientAnalysisInput!
    $condition: ModelMicroNutrientAnalysisConditionInput
  ) {
    createMicroNutrientAnalysis(input: $input, condition: $condition) {
      id
      intakeDaysRecommendation
      dateOfLastSubmission
      positions {
        items {
          id
          microNutrientAnalysisPositionItemSupplementId
          base
          requirement
        }
      }
      notesCustomer
      notesPharma
    }
  }
`;
export const updateMicroNutrientAnalysis = /* GraphQL */ `
  mutation UpdateMicroNutrientAnalysis(
    $input: UpdateMicroNutrientAnalysisInput!
    $condition: ModelMicroNutrientAnalysisConditionInput
  ) {
    updateMicroNutrientAnalysis(input: $input, condition: $condition) {
      id
      intakeDaysRecommendation
      dateOfLastSubmission
      positions {
        items {
          id
          microNutrientAnalysisPositionItemSupplementId
          base
          requirement
        }
      }
      pharmaOfferRequests {
        items {
          id
        }
      }
      notesCustomer
      notesPharma
    }
  }
`;

export const createMicroNutrientAnalysisPositionItem = /* GraphQL */ `
  mutation CreateMicroNutrientAnalysisPositionItem(
    $input: CreateMicroNutrientAnalysisPositionItemInput!
    $condition: ModelMicroNutrientAnalysisPositionItemConditionInput
  ) {
    createMicroNutrientAnalysisPositionItem(input: $input, condition: $condition) {
      id
      microNutrientAnalysisPositionItemSupplementId
      base
      requirement
    }
  }
`;
export const updateMicroNutrientAnalysisPositionItem = /* GraphQL */ `
  mutation UpdateMicroNutrientAnalysisPositionItem(
    $input: UpdateMicroNutrientAnalysisPositionItemInput!
    $condition: ModelMicroNutrientAnalysisPositionItemConditionInput
  ) {
    updateMicroNutrientAnalysisPositionItem(input: $input,  condition: $condition) {
      id
      microNutrientAnalysisPositionItemSupplementId
      base
      requirement
    }
  }
`;
export const deleteMicroNutrientAnalysisPositionItem = /* GraphQL */ `
  mutation DeleteMicroNutrientAnalysisPositionItem(
    $input: DeleteMicroNutrientAnalysisPositionItemInput!
    $condition: ModelMicroNutrientAnalysisPositionItemConditionInput
  ) {
    deleteMicroNutrientAnalysisPositionItem(input: $input, condition: $condition) {
      id
    }
  }
`;

export const submitMicroNutrientAnalysis = /* GraphQL */ `
  mutation SubmitMicroNutrientAnalysis(
    $input: SubmitMicroNutrientAnalysisInput!
  ) {
    submitMicroNutrientAnalysis(input: $input) {
      dateOfLastSubmission
    }
  }
`;
