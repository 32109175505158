import { SnackbarMessages } from 'types/message';

const Messages: SnackbarMessages = {
	DATA_SUBMITTED_SUCCESSFULLY: {
		message: {
			de: 'Die Informationen wurden übermittelt.',
		},
		options: {
			variant: 'success',
			// persist: true,
		},
	},
	MICRO_NUTRIENT_ANALYSIS_SUBMITTED_SUCCESSFULLY: {
		message: {
			de: 'Die Analyseergebnisse wurde übermittelt.',
		},
		options: {
			variant: 'success',
			// persist: true,
		},
	},
	INVOICE_SUBMITTED_SUCCESSFULLY: {
		message: {
			de: 'Die Rechnung wurde übermittelt.',
		},
		options: {
			variant: 'success',
			// persist: true,
		},
	},
	API_SAVE_SUCCESSFUL: {
		message: {
			de: 'Der Eintrag wurde erfolgreich gespeichert.',
		},
		options: {
			variant: 'success',
			// persist: true,
		},
	},
	API_DELETE_SUCCESSFUL: {
		message: {
			de: 'Der Eintrag wurde erfolgreich gelöscht.',
		},
		options: {
			variant: 'success',
		},
	},
	COPYTOCLIPBOARD_SUCCESSFUL: {
		message: {
			de: 'Erfolgreich in die Zwischenablage kopiert',
		},
		options: {
			variant: 'success',
		},
	},
};

export { Messages };
