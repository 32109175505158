import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import notFoundImageLight from 'images/undraw_page_not_found_light.svg';

const NotFound: FC = () => (
	<Box sx={{
		marginTop: '1.5rem',
		height: 'calc(100% - 128px)',
	}}
	>
		<Box style={{
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			width: '100%',
		}}
		>
			<img src={ notFoundImageLight } alt="404" height="350rem" />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography
					variant="h1"
					align="center"
					sx={{
						fontSize: '2rem', marginTop: '1rem',
					}}
				>
					Ups, hier geht es nicht weiter...
				</Typography>
				<Typography variant="h3" align="center">
					Sollte hier etwas sein, dass Du gerne sehen möchtest?
				</Typography>
			</Box>
		</Box>
	</Box>
);

export { NotFound };
