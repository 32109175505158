import React, {
	useState, useEffect, useMemo, useCallback, FC, ChangeEvent,
} from 'react';
import {
	Button, FormControlLabel, Grid, Switch, TextField,
} from '@mui/material';
import { Storage } from 'aws-amplify';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDateTimeString } from 'helper/formatDate';
import { Modal } from 'Modal';

import moment from 'moment';
import { StandardImageList } from 'components/ImageList';
import { useMessage } from 'hooks/useMessage';
import { Exceptions } from 'messages/Exceptions';
import { Messages } from 'messages/Messages';
import { CopyAll, Email } from '@mui/icons-material';
import { useClipboard } from 'hooks/useClipboard';
import { submitMicroNutrientAnalysisAnamnesisLink } from 'graphql/mutations';
import { ILog, LogFormularProps } from 'types/log';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';

const LogFormular: FC<LogFormularProps> = ({
	currentLog, customer, saveLog, removeLog, disabled,
}) => {
	const [log, setLog] = useState(currentLog);
	const [openModal, setOpenModal] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const { enqueueMessage } = useMessage();
	const { copyToClipboard } = useClipboard();

	const { editItem } = AWSAppSyncProvider();

	const isNew = useMemo(() => currentLog?.id === '00000000-0000-0000-0000-000000000000', [currentLog?.id]);
	const dateValidationError = useMemo(() => !log?.date, [log?.date]);

	useEffect(() => setLog(currentLog), [currentLog, setLog]);

	const _handleChange = useCallback((newValue: Partial<ILog>) => {
		setLog((current) => ({ ...current, ...newValue }));
	}, [setLog]);

	const _handleSave = useCallback(async () => {
		saveLog(log);
	}, [log, saveLog]);

	// Handle Log Images
	Storage.configure({ track: true, level: 'private' });

	const _handleFileUpload = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		// gets the file extension
		const fileExtensionRegEx = /(?:\.([^.]+))?$/;

		setIsUploading(true);
		const images = _.compact(_.concat(
			log?.images,
			// resolve the promise for each file
			_.map(await Promise.all(
				// map each file to a promise
				_.map(files, (file) => Storage.put(
					// generate a unique name for the file
					`${uuid()}.${fileExtensionRegEx.exec(file.name)?.[1]}`,
					// the file itself
					file,
					// only the user can access the file
					{ level: 'private' },
				)),
				// get the key from the promise
			), (res) => res.key),
		));

		setIsUploading(false);

		// Once all the files are uploaded
		_handleChange({ images });
	}, [log?.images, _handleChange]);

	const _handleDeleteFile = useCallback(async (key: string) => {
		// delete the file from the local state
		setLog({
			// keep all the other images
			...currentLog,
			// remove the image with the given key
			images: _.filter(log?.images, (image) => image !== key),
		});
	}, [log?.images]);

	const publicAnamnesisURL = useMemo(() => {
		if (!customer || currentLog.id === '00000000-0000-0000-0000-000000000000') return '';
		return `${window.location.origin}/public/anamnesis?id=${currentLog.id}&customerLogsId=${currentLog.customerLogsId}`;
	}, [currentLog.id, currentLog.customerLogsId, window.location.origin]);

	const handleSendAnamnesisLink = useCallback(async () => {
		try {
			setIsSending(true);
			await editItem(submitMicroNutrientAnalysisAnamnesisLink, { input: { link: publicAnamnesisURL, email: customer?.email } });
			enqueueMessage('submit_micro_nutrient_analysis_anamnesis_link', Messages.DATA_SUBMITTED_SUCCESSFULLY);
		} catch (err) {
			enqueueMessage('submit_micro_nutrient_analysis_anamnesis_link', Exceptions.GENERAL);
		} finally {
			setIsSending(false);
		}
	}, [customer?.email, publicAnamnesisURL, submitMicroNutrientAnalysisAnamnesisLink, Messages.DATA_SUBMITTED_SUCCESSFULLY, Exceptions.GENERAL, setIsSending, enqueueMessage]);

	return (
		<>
			<Grid container spacing={ 2 }>
				<Grid item xs={ 12 } sm={ 4 } md={ 3 }>
					<DatePicker
						label="Datum der Anwendung *"
						value={ log?.date ? moment(log?.date) : null }
						onChange={ (date) => {
							if (date === null) {
								_handleChange({ date: undefined });
								return;
							}
							if (date.isValid() && !_.isNaN(date.milliseconds())) {
								_handleChange({ date: date.toISOString() });
								return;
							}
							_handleChange({ date: undefined });
						} }
						slotProps={{
							actionBar: { actions: ['accept', 'today', 'clear'] },
							textField: {
								variant: 'standard',
								helperText: dateValidationError ? 'Es muss ein Datum gewählt werden' : '',
							},
						}}
						disabled={ isSending }
					/>
				</Grid>
				<Grid item xs={ 12 } sm={ 8 } md={ 6 }>
					<TextField
						label="Anwendungsbereich"
						variant="standard"
						value={ log?.subject ?? '' }
						onChange={ (event) => _handleChange({ subject: event.target.value }) }
						disabled={ isSending }
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } md={ 3 }>
					<FormControlLabel
						control={ (
							<Switch
								disabled={ disabled }
								checked={ log?.closed ?? false }
								onChange={ (e, checked) => _handleChange({ closed: checked }) }
							/>
						) }
						label="Abgeschlossen"
					/>
				</Grid>
				<Grid item xs={ 12 } sx={{ display: _.isEmpty(log?.images) ? 'none' : 'inherit' }}>
					<StandardImageList
						currentLog={ log }
						handleDeleteFile={ _handleDeleteFile }
						isEditable={ !isSending || !disabled }
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<TextField
						label="Notizen zur Anwendung"
						variant="standard"
						multiline
						value={ log?.notice ?? '' }
						onChange={ (event) => _handleChange({ notice: event.target.value }) }
						disabled={ isSending }
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 }>
					<TextField
						label="Anamnese"
						variant="standard"
						multiline
						value={ log?.anamnesis ?? '' }
						onChange={ (event) => _handleChange({ anamnesis: event.target.value }) }
						disabled={ isSending }
						fullWidth
					/>
				</Grid>
				<Grid item xs={ 12 } md={ 6 } lg={ 3 }>
					<Button
						variant="contained"
						component="label"
						startIcon={ <CloudUploadIcon /> }
						disabled={ disabled || isUploading || isSending }
						fullWidth
					>
						Upload
						<input
							hidden
							accept="image/*"
							multiple
							type="file"
							onChange={ _handleFileUpload }
						/>
					</Button>
				</Grid>
				<Grid item xs={ 12 } md={ 6 } lg={ 3 }>
					<Button
						variant="contained"
						startIcon={ <SaveIcon /> }
						onClick={ _handleSave }
						disabled={ disabled || isUploading || isSending || dateValidationError }
						fullWidth
					>
						Speichern
					</Button>
				</Grid>
				<Grid item xs={ 12 } md={ 6 } lg={ 3 }>
					<Button
						variant="outlined"
						startIcon={ <CancelIcon /> }
						onClick={ () => setLog(currentLog) }
						disabled={ isNew || disabled || isUploading || _.isEqual(currentLog, log) }
						fullWidth
					>
						Zurücksetzen
					</Button>
				</Grid>
				<Grid item xs={ 12 } md={ 6 } lg={ 3 }>
					<Button
						variant="outlined"
						startIcon={ <DeleteIcon /> }
						onClick={ () => setOpenModal(true) }
						disabled={ isNew || disabled || !!log?.invoice?.number }
						fullWidth
					>
						Löschen
					</Button>
				</Grid>
				<Grid item xs={ 12 } md={ 6 }>
					<Button
						variant="contained"
						startIcon={ <Email /> }
						color="info"
						onClick={ handleSendAnamnesisLink }
						disabled={ disabled || isUploading || isNew }
						fullWidth
					>
						Anamneseanfrage senden
					</Button>
				</Grid>
				<Grid item xs={ 12 } md={ 6 }>
					<Button
						variant="contained"
						startIcon={ <CopyAll /> }
						onClick={ () => copyToClipboard(publicAnamnesisURL, true) }
						disabled={ disabled || isUploading || isNew }
						fullWidth
					>
						Link (MNA Abfrage)
					</Button>
				</Grid>
			</Grid>
			<Modal
				open={ openModal }
				openModal={ openModal }
				setOpenModal={ setOpenModal }
				handleDelete={ removeLog }
				message={ `Wollen Sie die Anwendung vom ${formatDateTimeString(log?.date, 'dd.MM.yyyy')} löschen?` }
			/>
		</>
	);
};

export { LogFormular };
