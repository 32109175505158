/** ********************************* C U S T O M E R ********************************* */

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      nameOwner
      type
      address
      zip
      city
      country
      phone
      email
      birthdate
      weight
      vaccination
      disease
      treatment
      notice
      avatar
      consent
      approvalEnlightenmentHumanApprovedAt
      approvalEnlightenmentHumanText
      approvalEnlightenmentHumanSignature
      approvalEnlightenmentAnimalApprovedAt
      approvalEnlightenmentAnimalText
      approvalEnlightenmentAnimalSignature
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameOwner
        type
      }
      nextToken
    }
  }
`;

/** ********************************* L O G ********************************* */

export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      subject
      date
      notice
      anamnesis
      images
      customerLogsId
      logInvoiceId
      logInvoiceNumber
      logMicroNutrientAnalysisId
      invoice {
          id
          submittedAt
          dueDate
          paid
        }
      closed
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        date
        closed
      }
      nextToken
    }
  }
`;

/** ********************************* S U P P L E M E N T S ********************************* */

export const listSupplements = /* GraphQL */ `
  query ListSupplements(
    $filter: ModelSupplementFilterInput
    $limit: Int
  ) {
    listSupplements(filter: $filter, limit: $limit) {
      items {
        id
        names
        dosages {
          base
          mgDayKg100
          pcn
        }
        active
        noProductAvailable
        description
      }
    }
  }
`;

export const listSupplementProducts = /* GraphQL */ `
  query ListSupplementProducts(
    $filter: ModelSupplementProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplementProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pcn
        type
        quantity
        mgCore
        notice
        active
        recommendedPrice
        createdAt
      }
    }
  }
`;

/** ********************************* I N V O I C E ********************************* */

export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $id: ID
    $number: ModelStringKeyConditionInput
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvoices(
      id: $id
      number: $number
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        number
        date
        dueDate
        paid
        paymentMethod
      }
      nextToken
    }
  }
`;

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!, $number: String!) {
    getInvoice(id: $id, number: $number) {
      id
      number
      date
      dueDate
      submittedAt
      positions {
        quantity
        description
        price
        unit
      }
      customer{
        id
        name
        nameOwner
        type
        address
        zip
        city
        country
        phone
        email
      }
      bottomNotice
      pdf
      invoiceCustomerId
      paid
      paymentMethod
    }
  }
`;

/** ************************** M I C R O   N U T R I E N T   A N A L Y S I S **************************** */

export const listMicroNutrientAnalyses = /* GraphQL */ `
  query ListMicroNutrientAnalyses(
    $filter: ModelMicroNutrientAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMicroNutrientAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateOfLastSubmission
      }
      nextToken
    }
  }
`;

export const listMicroNutrientAnalysisPositionItems = /* GraphQL */ `
  query ListMicroNutrientAnalysisPositionItems(
    $filter: ModelMicroNutrientAnalysisPositionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMicroNutrientAnalysisPositionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getMicroNutrientAnalysis = /* GraphQL */ `
  query GetMicroNutrientAnalysis($id: ID!) {
    getMicroNutrientAnalysis(id: $id) {
      id
      intakeDaysRecommendation
      dateOfLastSubmission
      positions {
        items {
          id
          microNutrientAnalysisPositionItemSupplementId
          supplement {
            id
            names
            dosages {
              base
              mgDayKg100
              pcn
            }
            active
            noProductAvailable
            description
          }
          base
          requirement
        }
      }
      pharmaOfferRequests {
        items {
          id
          dateOfRequest
          offerSubmittedToCustomer
          offerCommissionedByCustomer
        }
      }
      notesCustomer
      notesPharma
    }
  }
`;

export const getMicroNutrientAnalysisPharmOfferRequest = /* GraphQL */ `
  query GetMicroNutrientAnalysis($id: ID!) {
    getMicroNutrientAnalysis(id: $id) {
      id
      intakeDaysRecommendation
      positions {
        items {
          id
          microNutrientAnalysisPositionItemSupplementId
          supplement {
            id
            names
            dosages {
              base
              mgDayKg100
              pcn
            }
            active
            noProductAvailable
            description
          }
          base
          requirement
        }
      }
      pharmaOfferRequests {
        items {
          id
          dateOfRequest
          offerSubmittedToCustomer
          offerCommissionedByCustomer
        }
      }
      log {
        id
        date
        anamnesis
        customer {
          id
          name
          nameOwner
          type
          weight
          consent
        }
      }
      notesCustomer
    }
  }
`;

export const getPublicMicroNutrientAnalysisPharmOfferRequest = /* GraphQL */ `
  query GetPublicMicroNutrientAnalysis($id: ID!) {
    getMicroNutrientAnalysis(id: $id) {
      id
      intakeDaysRecommendation
      positions {
        items {
          id
          microNutrientAnalysisPositionItemSupplementId
          supplement {
            id
            names
            dosages {
              base
              mgDayKg100
              pcn
            }
            active
            noProductAvailable
            description
          }
          base
          requirement
        }
      }
      log {
        id
        customer {
          id
          name
          nameOwner
          type
          weight
          consent
        }
      }
      notesCustomer
    }
  }
`;

export const getPharmaOfferRequest = /* GraphQL */ `
  query GetPharmaOfferRequest($id: ID!) {
    getPharmaOfferRequest(id: $id) {
      id
      dateOfRequest
      intakeDaysRecommendation
      positions {
        items {
          id
          pharmaOfferRequestPositionItemSupplementId
          base
          requirement
          supplement {
            names
          }
        }
      }
      weight
      notes
      offerSubmittedToCustomer
      offerCommissionedByCustomer
    }
  }
`;
