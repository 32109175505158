/* eslint-disable max-len */
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsExports from 'aws-exports';
import { Send as SendIcon } from '@mui/icons-material';
import {
	Alert, Box, Button,
} from '@mui/material';
import { CustomerFormular } from 'pages/customer/CustomerFormular';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from 'styles/customer.module.scss';
import { submitCustomerInformation } from 'graphql/mutations';
import { useMessage } from 'hooks/useMessage';
import { Messages } from 'messages/Messages';
import { Exceptions } from 'messages/Exceptions';
import { NotFound } from 'pages/NotFound';
import { PublicAnamnesisFormular } from 'pages/log/microNutrientAnalysis/PublicAnamnesisFormular';

/** @type {import('types/customer').ICustomer} */
const initial = {};
const CUSTOMER_URL_PARAMS = [
	'id', 'name', 'nameOwner', 'type',
	'address', 'zip', 'city', 'country',
	'phone', 'email', 'birthdate', 'weight',
	'vaccination', 'disease', 'treatment',
];

/**
 * @returns {React.ReactComponentElement} component to be shown
 */
const PublicCustomerFormular = () => {
	const [currentFormData, setCurrentFormData] = useState(initial);
	const [isLoading, setIsLoading] = useState(false);
	const [sent, setSent] = useState(false);
	// getting the URL parameters
	const [searchParams] = useSearchParams();
	const { enqueueMessage } = useMessage();
	const navigate = useNavigate();

	const handleChangeFormular = (data) => {
		setCurrentFormData((curr) => ({ ...curr, ...data }));
	};

	const anamnesisRequest = useMemo(() => searchParams.get('anamnesisRequest'), [searchParams]);

	const handleSubmit = async () => {
		// replace empty values with nulls
		const preparedCustomer = _.mapValues(currentFormData, (v) => {
			if (v) return v;
			return null;
		});

		try {
			setIsLoading(true);
			const ret = await API.graphql(graphqlOperation(submitCustomerInformation, { input: preparedCustomer }));
			if (`${ret?.data?.submitCustomerInformation}`.length === 36) {
				enqueueMessage('submit_customer_information', Messages.DATA_SUBMITTED_SUCCESSFULLY);
				if (preparedCustomer.id === '00000000-0000-0000-0000-000000000000') {
					const newId = ret?.data?.submitCustomerInformation;
					preparedCustomer.id = newId;
					setCurrentFormData((current) => ({ ...current, id: newId }));
					navigate(`/public/customer?id=${newId}&anamnesisRequest=${anamnesisRequest}`);
				}
				setSent(true);
			}
		} catch (err) {
			enqueueMessage('submit_customer_information', Exceptions.GENERAL);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		// remove cognito to allow api key
		Amplify.configure({ ..._.pickBy(awsExports, (v, key) => !key.startsWith('aws_cognito')), aws_appsync_authenticationType: 'API_KEY' });
	}, []);

	useEffect(() => {
		const newCustomer = currentFormData;
		if (currentFormData === initial) {
			_.forEach(CUSTOMER_URL_PARAMS, (key) => {
				const value = searchParams.get(key);
				if (value) {
					newCustomer[key] = value;
				}
			});
		}
		handleChangeFormular(newCustomer);
	}, [searchParams, initial]);

	const formularReady = useMemo(() => currentFormData?.name && currentFormData?.weight
	&& currentFormData.type
    && (currentFormData.type === 'human' || currentFormData.nameOwner)
    && currentFormData?.address && currentFormData?.zip
    && currentFormData?.city && currentFormData?.country
    && (currentFormData?.phone || currentFormData?.email)
    && !!currentFormData?.consent, [currentFormData]);

	return (
		<>
			{ sent && (
				<>
					<Alert severity="success" action={ <Button onClick={ () => setSent(false) }>Formular weiter bearbeiten</Button> }>
						Die Kundeninformationen wurden übermittelt
						{ (anamnesisRequest === '1')
                        && <p>Bitte füllen Sie das Anamneseformular zur Bestellung einer Mikronährstoffanalyse aus</p> }
					</Alert>
					<br />
					{ (anamnesisRequest === '1')
                        && <PublicAnamnesisFormular id="00000000-0000-0000-0000-000000000000" customerLogsId={ currentFormData.id } /> }
				</>
			) }
			{ (!sent && currentFormData.id) && (
				<>
					<h2 style={{ marginTop: '2rem' }}>Kundendaten</h2>
					<Box className={ styles.form } style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
						<CustomerFormular
							currentCustomer={ currentFormData }
							handleChangeCustomer={ handleChangeFormular }
							askConsent
						/>
						<Button variant="contained" onClick={ handleSubmit } startIcon={ <SendIcon /> } disabled={ isLoading || !formularReady }>Kundendaten speichern</Button>
					</Box>
				</>
			) }
			{ !currentFormData.id && <NotFound /> }
		</>
	);
};

export { PublicCustomerFormular, CUSTOMER_URL_PARAMS };
