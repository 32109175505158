import React, {
	useState, useEffect, useMemo, useCallback, FC,
} from 'react';
import {
	Alert,
	Autocomplete,
	Button, Grid, List, ListItem, ListItemIcon, ListItemText, Skeleton, TextField, Typography,
} from '@mui/material';
import {
	DataGrid, GridActionsCellItem, GridColDef, GridEditInputCell, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, GridValidRowModel,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import _ from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Add, Edit, MarkEmailReadOutlined, MarkEmailUnreadOutlined, MedicationLiquidOutlined, Send, WarningOutlined,
} from '@mui/icons-material';

import {
	submitMicroNutrientAnalysis, updateMicroNutrientAnalysis, createMicroNutrientAnalysis, createMicroNutrientAnalysisPositionItem, updateMicroNutrientAnalysisPositionItem, deleteMicroNutrientAnalysisPositionItem,
} from 'graphql/customMutations';
import { getMicroNutrientAnalysis, listSupplements } from 'graphql/customQueries';

import { formatDateTimeString } from 'helper/formatDate';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';
import { Exceptions } from 'messages/Exceptions';
import { Messages } from 'messages/Messages';

import { useMessage } from 'hooks/useMessage';
import { useBreakpoints } from 'hooks/useBreakpoints';

import { IMicroNutrientAnalysis, IMicroNutrientAnalysisDataGridEditToolbarProps, IMicroNutrientAnalysisFormularProps, IMicroNutrientAnalysisPositionItem, ISupplement } from 'types/microNutrientAnalysis';
import { generatePath } from 'react-router-dom';

import style from 'pages/log/microNutrientAnalysis/microNutrientAnalysisFormular.module.scss';

const EditToolbar: FC<IMicroNutrientAnalysisDataGridEditToolbarProps> = ({
	setRows, setRowModesModel, disabled, base, firstSupplementId,
}) => {
	const handleClick = (template?: Partial<IMicroNutrientAnalysisPositionItem>, autoSave?: boolean) => {
		const rowId = randomId();
		setRows((oldRows: GridRowsProp) => [...oldRows, {
			rowId, order: _.size(oldRows) + 1, microNutrientAnalysisPositionItemSupplementId: firstSupplementId, base, requirement: 50, isNew: true, ...(template || {}),
		}]);
		setRowModesModel((oldModel: GridRowModesModel) => ({
			...oldModel,
			[rowId]: { mode: autoSave ? GridRowModes.View : GridRowModes.Edit, fieldToFocus: 'microNutrientAnalysisPositionItemSupplementId' },
		}));
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={ <Add /> } onClick={ () => handleClick() } disabled={ disabled }>
				Bedarf hinzufügen
			</Button>
		</GridToolbarContainer>
	);
};

const microutrientAnalysisColors = {
	notActive: '#80817E',
	notAvailable: '#A106A6',
	noProductAvailable: '#A106A6',
	notFound: '#AB2306',
	notRequired: '#1A6600',
};

const MicroNutrientAnalysisFormular: FC<IMicroNutrientAnalysisFormularProps> = ({
	currentLog, customer, saveLog, disabled,
}) => {
	// const { user } = useAuthenticator((context) => [context.user]);

	const initialMicroNutrientAnalysis: IMicroNutrientAnalysis = useMemo(() => ({
		intakeDaysRecommendation: 30,
		microNutrientAnalysisLogId: currentLog?.id ?? '',
	}), [currentLog]);

	const [supplements, setSupplements] = useState<ISupplement[]>();
	const [microNutrientAnalysis, setMicroNutrientAnalysis] = useState<IMicroNutrientAnalysis>(initialMicroNutrientAnalysis);
	const [microNutrientAnalysisBackup, setMicroNutrientAnalysisBackup] = useState<IMicroNutrientAnalysis>(initialMicroNutrientAnalysis);

	const hasChanges = useMemo(() => !_.isEqual(microNutrientAnalysis, microNutrientAnalysisBackup), [microNutrientAnalysis, microNutrientAnalysisBackup]);

	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [loaded, setIsLoaded] = useState({ microNutrientAnalysis: false, supplements: false });
	const [rows, setRows] = useState<GridRowsProp>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const { enqueueMessage } = useMessage();

	const { isDesktop } = useBreakpoints();

	const { getItem, editItem, listItems } = AWSAppSyncProvider();

	const positionOpen = useMemo(
		() => _.some(rowModesModel, (r) => r?.mode === GridRowModes.Edit),
		[rowModesModel],
	);
	const customerInformationProvided = useMemo(() => (customer?.name || customer?.nameOwner) && customer?.email
    && customer?.address && customer?.zip && customer?.city && customer?.country && customer?.consent, [customer]);

	const supplementOptions = useMemo(() => _.map(supplements, (supplement) => ({
		value: supplement.id,
		label: _.join(supplement.names, ', '),
		supplement,
	})), [supplements]);

	const initializePositions = useCallback((positions: IMicroNutrientAnalysisPositionItem[]) => {
		// console.log('initializePositions', positions);

		setRows(_.map(positions, (position, i) => ({
			...position,
			rowId: `${i}-${Math.floor(Math.random() * 1000)}`,
			order: i + 1,
		})));
	}, [setRows]);

	const load = useCallback(async () => {
		setIsLoading(true);
		try {
			const micro: IMicroNutrientAnalysis = await getItem(getMicroNutrientAnalysis, { id: currentLog.logMicroNutrientAnalysisId });
			setMicroNutrientAnalysis(micro);
			setMicroNutrientAnalysisBackup(micro);
			// console.log(pharmO);
			if (micro.positions) {
				initializePositions(micro.positions);
			}
		} catch (err) {
			enqueueMessage(`MicroNutrientAnalysis${currentLog.logMicroNutrientAnalysisId}`, Exceptions.API_LOAD_ERROR);
			setMicroNutrientAnalysis(initialMicroNutrientAnalysis);
			setMicroNutrientAnalysisBackup(initialMicroNutrientAnalysis);
		} finally {
			setIsLoading(false);
			setIsLoaded((current) => ({ ...current, microNutrientAnalysis: true }));
		}
	}, [getMicroNutrientAnalysis, currentLog.logMicroNutrientAnalysisId, initialMicroNutrientAnalysis, Exceptions.API_LOAD_ERROR,
		getItem, setIsLoading, setIsLoaded, setMicroNutrientAnalysis, setMicroNutrientAnalysisBackup, initializePositions, enqueueMessage]);

	// load MicroNutrientAnalysis if necessary
	useEffect(() => {
		if (!loaded.microNutrientAnalysis && !isLoading && currentLog?.logMicroNutrientAnalysisId) {
			load();
		} else {
			if (!currentLog?.logMicroNutrientAnalysisId && microNutrientAnalysis?.id) {
				setMicroNutrientAnalysis(initialMicroNutrientAnalysis);
				setMicroNutrientAnalysisBackup(initialMicroNutrientAnalysis);
				setRows([]);
				setRowModesModel({});
			}
			if (currentLog?.logMicroNutrientAnalysisId && (currentLog?.logMicroNutrientAnalysisId !== microNutrientAnalysis?.id) && loaded.microNutrientAnalysis) {
				setIsLoaded((current) => ({ ...current, microNutrientAnalysis: false }));
			}
		}
	}, [microNutrientAnalysis?.id, currentLog?.logMicroNutrientAnalysisId, initialMicroNutrientAnalysis, loaded.microNutrientAnalysis,
		setIsLoaded, setMicroNutrientAnalysis, setMicroNutrientAnalysisBackup, load]);

	// load supplements
	useEffect(() => {
		const loadSupplements = async () => {
			setIsLoading(true);
			try {
				setSupplements(await listItems(listSupplements));
			} catch (err) {
				enqueueMessage(`MicroNutrientAnalysis_Supplements${currentLog.logMicroNutrientAnalysisId}`, Exceptions.API_LOAD_ERROR);
				setSupplements([]);
			} finally {
				setIsLoading(false);
				setIsLoaded((current) => ({ ...current, supplements: true }));
			}
		};
		if (!isLoading && !loaded.supplements && (loaded.microNutrientAnalysis || !currentLog.logMicroNutrientAnalysisId)) {
			loadSupplements();
		}
	}, [isLoading, loaded, setIsLoaded, currentLog.logMicroNutrientAnalysisId,
		setSupplements, setIsLoading, enqueueMessage, Exceptions.API_LOAD_ERROR]);

	const _openPublicPharmaOfferRequestPage = useCallback(() => {
		if (microNutrientAnalysis?.id) window.open(`/public/por/${microNutrientAnalysis?.id}`, '_blank');
	}, [microNutrientAnalysis?.id]);

	const _openPharmaOfferRequestPage = useCallback((id: string | undefined) => {
		if (!id || !customer?.id || !currentLog?.id) return;
		window.open(`${generatePath('/customer/:id?/:logId?/por/:pharmaOfferRequestId', { id: customer.id, logId: currentLog.id, pharmaOfferRequestId: id })}`, '_blank');
	}, [customer?.id, currentLog?.id]);

	const _handleChange = useCallback((newValue: Partial<IMicroNutrientAnalysis>, includeBackup?: boolean) => {
		setMicroNutrientAnalysis((current) => ({
			...initialMicroNutrientAnalysis, // necessary props
			...current, // current state
			...newValue, // new partial state
		}));
		if (includeBackup) {
			setMicroNutrientAnalysisBackup((current) => ({
				...initialMicroNutrientAnalysis, // necessary props
				...current, // current state
				...newValue, // new partial state
			}));
		}
	}, [initialMicroNutrientAnalysis, setMicroNutrientAnalysis]);

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleRowEditStop = (params: any, event: any) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			// eslint-disable-next-line no-param-reassign
			event.defaultMuiPrevented = true;
		}
	};
	const handleEditClick = (rowId: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [rowId]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (rowId: GridRowId) => () => {
		setRowModesModel((current) => ({ ...current, [rowId]: { mode: GridRowModes.View } }));
	};

	const handleDeleteClick = (rowId: GridRowId) => () => {
		setRows((current) => current.filter((row) => row.rowId !== rowId));
	};

	const handleCancelClick = (rowId: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[rowId]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.rowId === rowId);
		if (editedRow?.isNew) {
			setRows((current) => current.filter((row) => row.rowId !== rowId));
		}
	};

	const processRowUpdate = React.useCallback((newRow: GridValidRowModel) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows((current) => current.map((row) => (row.rowId === newRow.rowId ? updatedRow : row)));
		return updatedRow;
	}, []);

	const handleSubmitMicroNutrientAnalysis = useCallback(async () => {
		if (!microNutrientAnalysis?.id || !microNutrientAnalysis.positions?.length) {
			return;
		}
		setIsSaving(true);
		try {
			const micro: IMicroNutrientAnalysis = await editItem(submitMicroNutrientAnalysis, { input: { id: microNutrientAnalysis.id } });

			_handleChange(micro, true);
			enqueueMessage(`MicroNutrientAnalysis${microNutrientAnalysis.id}`, Messages.DATA_SUBMITTED_SUCCESSFULLY);
		} catch (err) {
			enqueueMessage(`MicroNutrientAnalysis${microNutrientAnalysis.id}`, Exceptions.GENERAL);
		} finally {
			setIsSaving(false);
		}
	}, [microNutrientAnalysis?.id, microNutrientAnalysis?.positions?.length, Messages.DATA_SUBMITTED_SUCCESSFULLY, Exceptions.GENERAL, submitMicroNutrientAnalysis, setIsSaving, enqueueMessage, _handleChange]);

	const _handleSave = useCallback(async () => {
		if (isSaving || !microNutrientAnalysis || !currentLog?.id) {
			return;
		}

		const prepareItem: IMicroNutrientAnalysis = _.pick(
			microNutrientAnalysis,
			'id',
			'intakeDaysRecommendation',
			'dateOfLastSubmission',
			'notesPharma',
			'notesCustomer',
			'microNutrientAnalysisLogId',
		);
		if (!prepareItem.microNutrientAnalysisLogId) {
			prepareItem.microNutrientAnalysisLogId = currentLog?.id;
		}

		setIsSaving(true);

		const putMicroNutrientAnalysis = async (item: IMicroNutrientAnalysis) => {
			if (item.id) { // update
				const micro: IMicroNutrientAnalysis = await editItem(updateMicroNutrientAnalysis, { input: item });
				return micro;
			} // create
			const micro: IMicroNutrientAnalysis = await editItem(createMicroNutrientAnalysis, { input: _.omit(item, 'id') });
			return micro;
		};

		const putMicroNutrientAnalysisPositionItem = async (item: IMicroNutrientAnalysisPositionItem) => {
			if (item.id) { // update
				const microPI: IMicroNutrientAnalysisPositionItem = await editItem(updateMicroNutrientAnalysisPositionItem, { input: item });
				return microPI;
			} // create
			const microPI: IMicroNutrientAnalysisPositionItem = await editItem(createMicroNutrientAnalysisPositionItem, { input: _.omit(item, 'id') });
			return microPI;
		};

		const removeMicroNutrientAnalysisPositionItem = async (item: IMicroNutrientAnalysisPositionItem) => editItem(deleteMicroNutrientAnalysisPositionItem, { input: { id: item.id } });

		try {
			const micro = await putMicroNutrientAnalysis(prepareItem);
			if (!micro.id) {
				throw new Error('Save failed');
			}
			const logSave = saveLog({ ...currentLog, logMicroNutrientAnalysisId: micro.id });

			const newPositions: IMicroNutrientAnalysisPositionItem[] = _.map(rows, (r) => ({
				..._.pick(r, 'id', 'base', 'requirement', 'microNutrientAnalysisPositionItemSupplementId'),
				microNutrientAnalysisPositionsId: micro.id ?? '',
			}));

			const removedPositions = _.filter(microNutrientAnalysis.positions, (pos) => !_.some(newPositions, (nPos) => nPos.id === pos.id));
			const putPositions = _.filter(newPositions, (nPos) => !nPos.id || _.some(microNutrientAnalysis.positions, (pos) => nPos.id === pos.id));

			const [putPos] = await Promise.all([
				Promise.all(_.map(putPositions, (p) => putMicroNutrientAnalysisPositionItem(p))),
				Promise.all(_.map(removedPositions, (p) => removeMicroNutrientAnalysisPositionItem(p))),
				logSave,
			]);

			micro.positions = putPos;
			initializePositions(putPos);
			setMicroNutrientAnalysis(micro);
			setMicroNutrientAnalysisBackup(micro);
		} catch (err) {
			enqueueMessage('MicroNutrientAnalysisFormular', Exceptions.API_SAVE_ERROR);
		} finally {
			setIsSaving(false);
		}
	}, [isSaving, microNutrientAnalysis, microNutrientAnalysisBackup, currentLog, rows,
		createMicroNutrientAnalysis, updateMicroNutrientAnalysis, createMicroNutrientAnalysisPositionItem, updateMicroNutrientAnalysisPositionItem, deleteMicroNutrientAnalysisPositionItem,
		Messages.API_SAVE_SUCCESSFUL, Exceptions.API_SAVE_ERROR,
		load, initializePositions, enqueueMessage, setMicroNutrientAnalysis, setMicroNutrientAnalysisBackup, setIsSaving]);

	const columns: GridColDef[] = useMemo(() => [
		{
			field: 'microNutrientAnalysisPositionItemSupplementId',
			headerName: 'Supplement',
			minWidth: 300,
			flex: 1,
			editable: !disabled,
			renderCell: (params) => {
				const rowBase = params.row?.base;
				const option = _.find(supplementOptions, (o) => o.value === params.formattedValue);
				if (!option) {
					return (
						<p style={{ color: microutrientAnalysisColors.noProductAvailable, fontStyle: 'italic' }}>
							Fehler - Supplement nicht gefunden
						</p>
					);
				}
				const dosage = _.find(option?.supplement?.dosages, (d) => d.base === rowBase);

				let color = 'inherit';
				let fontStyle = 'inherit';
				if (!option?.supplement?.active) {
					color = microutrientAnalysisColors.notActive;
					fontStyle = 'italic';
				} else if (option?.supplement?.noProductAvailable) {
					color = microutrientAnalysisColors.notAvailable;
					fontStyle = 'italic';
				} else if (!dosage) {
					color = microutrientAnalysisColors.notFound;
					fontStyle = 'italic';
				}

				if ((params.row?.requirement ?? 0) < 70) {
					color = microutrientAnalysisColors.notRequired;
					fontStyle = 'italic';
				}

				return (
					<p style={{ color, fontStyle }}>
						{ option?.label }
					</p>
				);
			},
			renderEditCell: (params) => (
				<Autocomplete
					// disablePortal
					options={ _.filter(supplementOptions, ({ supplement }) => {
						if (supplement.id === params.formattedValue) return true;
						if (!supplement.active) return false;
						return true;
					}) }
					isOptionEqualToValue={ (option, value) => option?.value === value?.value }
					onChange={ (event, selected) => params.api.setEditCellValue({ id: params.id, field: params.field, value: selected?.value }) }
					// set value to null to avoid "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."
					value={ _.find(supplementOptions, (o) => o.value === params.formattedValue) ?? null }
					getOptionLabel={ (option) => option.label }
					renderInput={ (par) => (
						<TextField
							{ ...par }
							label="Supplement*"
							variant="standard"
						/>
					) }
					style={{ maxWidth: 'unset' }}
					disabled={ disabled }
					fullWidth
				/>
			),
		},
		{
			field: 'requirement',
			headerName: 'Bedarf',
			type: 'number',
			width: 80,
			align: 'center',
			headerAlign: 'center',
			editable: !disabled,
			renderEditCell: (params) => (
				<GridEditInputCell
					{ ...params }
					inputProps={{
						max: 100,
						min: 1,
					}}
				/>
			),
		},
		{
			field: 'base',
			headerName: 'Basis',
			width: 220,
			// flex: 1,
			align: 'center',
			headerAlign: 'center',
			editable: !disabled,
			type: 'singleSelect',
			valueOptions: [
				{ value: 'human', label: 'Mensch' },
				{ value: 'dog', label: 'Hund' },
				{ value: 'horse', label: 'Pferd' },
			],
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			width: 100,
			getActions: ({ id }) => {
				if (disabled) {
					return [];
				}
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							key={ id }
							icon={ <SaveIcon /> }
							label="Save"
							sx={{
								color: 'primary.main',
							}}
							onClick={ handleSaveClick(id) }
						/>,
						<GridActionsCellItem
							key={ id }
							icon={ <CancelIcon /> }
							label="Cancel"
							className="textPrimary"
							onClick={ handleCancelClick(id) }
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						key={ id }
						icon={ <Edit /> }
						label="Edit"
						className="textPrimary"
						onClick={ handleEditClick(id) }
						color="inherit"
					/>,
					<GridActionsCellItem
						key={ id }
						icon={ <DeleteIcon /> }
						label="Delete"
						onClick={ handleDeleteClick(id) }
						color="inherit"
					/>,
				];
			},
		},
	], [disabled, rowModesModel, handleDeleteClick, supplementOptions]);

	if (currentLog?.logMicroNutrientAnalysisId && !loaded.microNutrientAnalysis) {
		return (
			<>
				<Skeleton variant="rectangular" height="3rem" width="100%" style={{ marginBottom: '1rem' }} />
				<Skeleton variant="rectangular" height="8rem" width="100%" style={{ marginBottom: '1rem' }} />
				<Skeleton variant="rectangular" height="3rem" width="100%" />
			</>
		);
	}

	const pharmaOfferRequestsList = (
		<List dense>
			{ _.map(_.orderBy(microNutrientAnalysis?.pharmaOfferRequests, 'dateOfRequest', 'desc'), (pharmaOfferRequest) => {
				let secondary = '';
				let icon = <WarningOutlined />;
				if (pharmaOfferRequest.offerSubmittedToCustomer) {
					secondary = 'Angebot wurde an den Kunden versendet';
					icon = <MarkEmailUnreadOutlined />;
				} else {
					secondary = 'Kein Angebot wurde an den Kunden versendet';
				}
				if (pharmaOfferRequest.offerCommissionedByCustomer) {
					secondary = 'Angebot wurde vom Kunden angenommen';
					icon = <MarkEmailReadOutlined />;
				}
				return (
					<ListItem
						key={ pharmaOfferRequest.id }
						className={ style.List }
						onClick={ () => _openPharmaOfferRequestPage(pharmaOfferRequest.id) }
					>
						<ListItemIcon>{ icon }</ListItemIcon>
						<ListItemText
							primary={ formatDateTimeString(pharmaOfferRequest.dateOfRequest) }
							secondary={ secondary }
						/>
					</ListItem>
				);
			}) }
		</List>
	);

	return (
		<Grid container spacing={ 2 }>
			<Grid item xs={ 12 } sm={ 6 }>
				<TextField
					variant="standard"
					type="number"
					value={ microNutrientAnalysis?.intakeDaysRecommendation ?? '' }
					onChange={ (event) => _handleChange({ intakeDaysRecommendation: Number(event.target.value) }) }
					disabled={ disabled }
					helperText="Einnahmeempfehlung (Tage) *"
					fullWidth
				/>
			</Grid>
			<Grid item xs={ 12 } sm={ 6 }>
				<TextField
					helperText="Analyse zuletzt gesendet"
					variant="standard"
					type="datetime-local"
					value={ _.replace(formatDateTimeString(microNutrientAnalysis?.dateOfLastSubmission, 'yyyy-MM-dd#HH:mm'), '#', 'T') ?? '' }
					disabled
					fullWidth
				/>
			</Grid>
			<Grid item xs={ 12 } width="100%">
				{ !loaded.supplements && <Skeleton variant="rectangular" height="8rem" width="100%" /> }
				{ !!(loaded.supplements && supplementOptions.length) && (
					<>
						<DataGrid
							rows={ rows }
							columns={ columns }
							editMode="row"
							getRowId={ (row) => row.rowId }
							rowModesModel={ rowModesModel }
							onRowModesModelChange={ handleRowModesModelChange }
							onRowEditStop={ handleRowEditStop }
							processRowUpdate={ processRowUpdate }
							slots={{
								toolbar: EditToolbar,
							}}
							slotProps={{
								toolbar: {
									setRows, setRowModesModel, disabled, customer, firstSupplementId: supplementOptions[0].value, base: customer.type,
								} }}
							hideFooter
							autoHeight
							style={{ maxWidth: isDesktop ? 'calc(100vw - 350px - 6rem)' : 'calc(100vw - 4rem)', color: '' }}
						/>
						<Typography fontSize="0.8rem" color={ microutrientAnalysisColors.notActive } style={{ float: 'left' }} marginRight="1rem">*Nicht aktiv</Typography>
						<Typography fontSize="0.8rem" color={ microutrientAnalysisColors.notAvailable } style={{ float: 'left' }} marginRight="1rem">*Nicht verfügbar</Typography>
						<Typography fontSize="0.8rem" color={ microutrientAnalysisColors.notFound } style={{ float: 'left' }} marginRight="1rem">*Keine Dosierung verfügbar</Typography>
						<Typography fontSize="0.8rem" color={ microutrientAnalysisColors.notRequired } style={{ float: 'left' }} marginRight="1rem">*Mindestbedarf nicht erreicht</Typography>
					</>
				) }
				{ (loaded.supplements && !supplements?.length) && <>Keine Supplemente gefunden</> }
			</Grid>
			<Grid item xs={ 12 }>
				<TextField
					label="Nachricht an den Kunden"
					variant="standard"
					multiline
					fullWidth
					value={ microNutrientAnalysis?.notesCustomer ?? '' }
					onChange={ (event) => _handleChange({ notesCustomer: event.target.value }) }
					disabled={ disabled }
					style={{ marginBottom: '1rem' }}
				/>
			</Grid>
			<Grid item xs={ 12 }>
				<TextField
					label="Nachricht an die Apotheke"
					variant="standard"
					multiline
					fullWidth
					value={ microNutrientAnalysis?.notesPharma ?? '' }
					onChange={ (event) => _handleChange({ notesPharma: event.target.value }) }
					disabled={ disabled }
					style={{ marginBottom: '1rem' }}
				/>
			</Grid>
			{ !!pharmaOfferRequestsList
			&& (
				<Grid item xs={ 12 }>
					<Typography fontWeight="bold">Angebotsanforderungen</Typography>
					{ pharmaOfferRequestsList }
				</Grid>
			) }
			{ !customerInformationProvided && (
				<Grid item xs={ 12 }>
					<Alert severity="warning">
						Der Kunde hat NICHT akzeptiert oder die Kundendaten sind nicht vollständig. Das Einholen eines Angebots ist somit nicht möglich.
					</Alert>
				</Grid>
			) }
			<Grid item xs={ 12 } md={ 4 }>
				<Button
					variant="contained"
					startIcon={ <SaveIcon /> }
					onClick={ _handleSave }
					disabled={ disabled || isSaving || positionOpen || !hasChanges }
					fullWidth
				>
					Speichern
				</Button>
			</Grid>
			<Grid item xs={ 12 } md={ 4 }>
				<Button
					variant="contained"
					disabled={ disabled || !customerInformationProvided || isSaving || positionOpen || hasChanges }
					onClick={ handleSubmitMicroNutrientAnalysis }
					startIcon={ <Send /> }
					color="info"
					fullWidth
				>
					{ !!microNutrientAnalysis?.dateOfLastSubmission && <>Analyse erneut senden</> }
					{ !microNutrientAnalysis?.dateOfLastSubmission && <>Analyse senden</> }
				</Button>
			</Grid>
			<Grid item xs={ 12 } md={ 4 }>
				<Button
					variant="contained"
					startIcon={ <MedicationLiquidOutlined /> }
					onClick={ _openPublicPharmaOfferRequestPage }
					disabled={ !customerInformationProvided || isSaving || positionOpen || hasChanges }
					fullWidth
				>
					Angebot einholen
				</Button>
			</Grid>
		</Grid>
	);
};

export { MicroNutrientAnalysisFormular, microutrientAnalysisColors };
