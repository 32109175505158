import _ from 'lodash';
import {
	Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {
	Add,
	ArrowRight,
	Close,
	Menu,
} from '@mui/icons-material';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { ProductFormular } from 'pages/administration/ProductFormular';
import { graphqlOperation } from 'aws-amplify';
import { useMessage } from 'hooks/useMessage';
import { Messages } from 'messages/Messages';
import { Exceptions } from 'messages/Exceptions';
import { createSupplementProduct, updateSupplementProduct } from 'graphql/customMutations';
import { listSupplementProducts } from 'graphql/customQueries';
import { ISupplementProduct } from 'types/microNutrientAnalysis';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';

const colors = {
	notActive: '#80817E',
	notAvailable: '#A106A6',
	notFound: '#AB2306',
	notRequired: '#1A6600',
};

const ProductsAdministrationFormular: FC = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [products, setProducts] = useState<ISupplementProduct[]>();
	const [productsFiltered, setProductsFiltered] = useState<ISupplementProduct[]>();
	const [currentProduct, setCurrentProduct] = useState<ISupplementProduct>();

	const { listItems, editItem } = AWSAppSyncProvider();
	const { enqueueMessage } = useMessage();

	const iOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleSaveProduct = useCallback(async (product: ISupplementProduct) => {
		if (!product || !product.pcn || !product.quantity || !product.mgCore || !product.type) {
			enqueueMessage('ProductsAdministrationFormular', Exceptions.MISSING_INFORMATION);
			return;
		}

		// replace empty values with nulls
		const preparedProduct: Partial<ISupplementProduct> = _.pick(product, 'pcn', 'type', 'quantity', 'mgCore', 'notice', 'active', 'recommendedPrice');

		setIsLoading(true);
		try {
			const prod: ISupplementProduct = await editItem(product.createdAt ? updateSupplementProduct : createSupplementProduct, { input: preparedProduct });

			if (product.createdAt) {
				setProducts((current) => _.map(current, (c) => {
					if (c.pcn === product.pcn) {
						return { ...c, ...prod };
					}
					return c;
				}));
			} else {
				setProducts((current) => [...current ?? [], prod]);
			}
			setCurrentProduct((current) => ({ ...current, ...prod }));

			enqueueMessage(`Product_${preparedProduct.pcn}`, Messages.API_SAVE_SUCCESSFUL);
		} catch {
			enqueueMessage(`Product_${preparedProduct.pcn}`, Exceptions.API_SAVE_ERROR);
		} finally {
			setIsLoading(false);
		}
	}, [updateSupplementProduct, createSupplementProduct, products, currentProduct,
		Messages.API_SAVE_SUCCESSFUL, Exceptions.API_SAVE_ERROR, Exceptions.DUPLICATE_ENTRY, Exceptions.MISSING_INFORMATION,
		setIsLoading, enqueueMessage]);

	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			try {
				const prods: ISupplementProduct[] = _.orderBy(await listItems(listSupplementProducts, {}, true), ['pcn'], ['asc']);
				setProducts(prods);
				setProductsFiltered(prods);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				enqueueMessage('ProductsAdministrationFormular', Exceptions.API_LOAD_ERROR);
			} finally {
				setIsLoading(false);
			}
		};
		load();
	}, [listSupplementProducts, enqueueMessage, graphqlOperation, setIsLoading, setProducts]);

	const _filterProducts = useCallback((filter: string) => {
		if (!filter) {
			setProducts(products);
		}
		const filterString = filter.toLocaleLowerCase();
		const filtered: ISupplementProduct[] = _.filter(products, (product) => {
			if (product.pcn?.toLocaleLowerCase().includes(filterString)) {
				return true;
			}
			if (product.notice?.toLocaleLowerCase().includes(filterString)) {
				return true;
			}
			return false;
		});

		setProductsFiltered(filtered);
	}, [products]);

	const _filter = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
		_filterProducts(event.target.value);
	}, 200);

	const productsList = (
		<Box>
			<TextField
				label="Suche / Filter"
				variant="standard"
				onChange={ _filter }
				fullWidth
			/>
			<List
				dense
				style={{ minWidth: '350px',
					width: isMobile ? '100%' : '350px',
					maxHeight: isDesktop ? 'calc(100vH - 15rem)' : 'inerhit',
					overflow: 'scroll',
				}}
			>
				<ListItem key="new" disablePadding>
					<ListItemButton
						onClick={ () => {
							setCurrentProduct({
								pcn: '', type: 'kps', quantity: 0, mgCore: 0, active: true,
							}); setDrawerOpen(false);
						} }
						sx={{ backgroundColor: (currentProduct?.pcn === '') ? 'primary.main' : 'unset' }}
					>
						<ListItemText primary="Neues Produkt anlegen" />
						<ListItemIcon>
							<Add />
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
				{ productsFiltered?.map((product) => (
					<ListItem key={ product.pcn } disablePadding>
						<ListItemButton
							onClick={ () => { setCurrentProduct(product); setDrawerOpen(false); } }
							sx={{
								color: !product.active ? colors.notActive : 'inherit',
								backgroundColor: (product.pcn === currentProduct?.pcn) ? 'primary.main' : 'unset',
							}}
						>
							<ListItemText primary={ product.pcn } />
							<ListItemIcon>
								<ArrowRight />
							</ListItemIcon>
						</ListItemButton>
					</ListItem>
				)) }
			</List>
		</Box>
	);

	return (
		<Box>
			{ (!isDesktop) && (
				<Button
					onClick={ () => setDrawerOpen(true) }
					style={{ marginBottom: '2rem' }}
					startIcon={ <Menu color="primary" /> }
				>
					Liste öffnen
				</Button>
			) }
			<Box style={{
				minHeight: '50vH', display: 'flex',
			}}
			>

				{ (!isDesktop && drawerOpen) && (
					<SwipeableDrawer
						anchor="left"
						open={ drawerOpen }
						onClose={ () => setDrawerOpen(false) }
						onOpen={ () => setDrawerOpen(true) }
						disableBackdropTransition={ !iOS }
						disableDiscovery={ iOS }
						PaperProps={{ sx: { width: isMobile ? '100%' : 'inerhit' } }}
					>
						<Box style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
							<Button onClick={ () => setDrawerOpen(false) } startIcon={ <Close /> }>Liste schließen</Button>
							{ productsList }
						</Box>
					</SwipeableDrawer>
				) }
				{ isDesktop && productsList }
				<Box style={{
					flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '2rem', paddingLeft: isDesktop ? '2rem' : '0rem',
				}}
				>
					{ !currentProduct && (
						<Typography component="h2">Bitte ein Produkt aus der Liste auswählen oder ein neues anlegen</Typography>
					) }
					{ currentProduct && <ProductFormular currentProduct={ currentProduct } handleSaveProduct={ handleSaveProduct } disabled={ loading } /> }
				</Box>
			</Box>
		</Box>
	);
};

export { ProductsAdministrationFormular };
