import React from 'react';
import { Box } from '@mui/material';
import style from 'templates/layout/contentContainer.module.scss';

/**
 * The Wrapper for the LayoutContainer component(s).
 *
 *
 * @memberof Frontend.Theme
 * @param {import('@mui/material').BoxProps} props - props for the component.
 * @returns {React.ReactElement} The LayoutContainer component.
 */
const ContentContainer = ({ children, ...rest }) => (
	<Box { ...rest } className={ style.container }>
		{ children }
	</Box>
);

export { ContentContainer };
