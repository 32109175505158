import {
	Box, Button, Grid, ImageList, ImageListItem,
} from '@mui/material';
import styles from 'styles/customer.module.scss';

import imgHorseGundula from 'images/horses_gundula_1.jpg';
// import imgHorseEllen from 'images/horses_ellen_1.jpg';
import imgDogsEllen from 'images/dogs_ellen.jpg';
import logoEllen from 'images/logo_tdth.png';
import logoGundula from 'images/logo_equinopathie.png';
import { Email } from '@mui/icons-material';
import { EqologyPage } from 'pages/log/microNutrientAnalysis/EqologyPage';

/** @type {import('react').CSSProperties} */
const pStyle = { marginTop: '1rem' };

/**
 * @returns {React.ReactComponentElement} component to be shown
 */
const HairAnalysisInformationPage = () => (
	<>
		<h2>Mikronährstoffanalyse</h2>
		<h3>mittels Bioresonanz 3000</h3>
		<ImageList
			style={{ marginTop: '1rem' }}
			rowHeight={ 350 }
			variant="masonry"
			cols={ 2 }
			gap={ 8 }
		>
			<ImageListItem><img src={ imgDogsEllen } alt="ellen with dogs" /></ImageListItem>
			<ImageListItem><img src={ imgHorseGundula } alt="horses" /></ImageListItem>
		</ImageList>
		<Box className={ styles.form }>
			<Grid container spacing={ 2 } style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
				<Grid item xs={ 12 } sm={ 6 } textAlign="center">
					<a href="https://the-horses.at" target="_blank" rel="noreferrer">
						<img src={ logoEllen } alt="ellen fuhrmann" height={ 40 } />
					</a>
				</Grid>
				<Grid item xs={ 12 } sm={ 6 } textAlign="center">
					<a href="https://www.equinopathie.at" target="_blank" rel="noreferrer">
						<img src={ logoGundula } alt="gundula" height={ 80 } />
					</a>
				</Grid>
				<Grid item xs={ 12 }>
					<h3>
						Nährstoffmängel auf die Spur kommen!
					</h3>
					<h4>
						Individuellen Verbrauch  kennen - individuell unterstützen!
					</h4>
				</Grid>
				<Grid item xs={ 12 } md={ 6 }>
					<p style={ pStyle }>
						Mittels Bioresonanzmethode wird der individuelle Bedarf von Mineralstoffen, Aminosäuren, Vitaminen ermittelt und erkannt, wo ein Bedarf entsteht.
					</p>
					<p style={ pStyle }>
						So kann die Ernährung optimiert bzw  der Organismus mit individuell abgestimmten Supplementen unterstützt werden.
						Denn der Körper kann nur so gut funktionieren, wie man ihn versorgt.
					</p>
					<p style={ pStyle }>
						<b>Mineralstoffe:&nbsp;</b>
						dazu zählen u.a. Calcium, Kalium, Magnesium, Chlor, Phosphor, Natrium und Schwefel, da diese
						der Organismus nicht selbst herstellen kann. Daher müssen sie mit der Nahrung oder über Nahrungsergänzungsmittel zugeführt werden.
					</p>
					<p style={ pStyle }>
						<b>Spurenelemente:&nbsp;</b>
						u.a. Chrom, Eisen, Jod, Kupfer sowie Mangan, Selen und Zink. Fehlen sie, könnte das Mangelerscheinungen, Stoffwechselstörungen hervorrufen.
					</p>
					<p style={ pStyle }>
						<b>Aminosäuren:&nbsp;</b>
						sind wichtige Bausteine der Proteine und spielen eine entscheidende Rolle in allen Prozessen des Körpers.
						Es gibt essentielle, d.h. die können nicht vom Körper produziert werden und müssen zugeführt werden, und solche die der Körper selbst zusammenbauen kann.
					</p>
				</Grid>
				<Grid item xs={ 12 } md={ 6 }>
					<p style={ pStyle }>
						<b>Vitamine:&nbsp;</b>
						sind wichtige Nährstoffe die eine entscheidende Rolle für die Gesundheit und das Wohlbefinden spielen und unterstützen lebenswichtige Funktionen.
					</p>
					<b>
						Diese Methode ersetzt nicht den Weg zum Arzt.
						Vorab sollte ein Tierarzt/Arzt konsultiert werden um den Gesundheitszustand abzuklären.
						Bei Einnahmen von Medikamenten sowie eines Krankheitsbildes ist es wichtig die Dosierung der Mikronährstoffen mit dem behandelten Tierarzt/ Arzt abzuklären.
					</b>
					<p style={ pStyle }>
						Zusätzlich wird die Einnahme von Omega 3 Fettsäuren empfohlen.
						Sie sind Bausteine der Zellmembran und ermöglichen, dass die Nährstoffe in die Zelle gelangen können.
					</p>
					<p style={ pStyle }>
						Die Fettsäuren sind sehr wichtig für die Zellen bzw. Instandhaltung und Aufbau der Zellwand.
						Kontaktieren Sie bitte Gundula Lorenz um das beste Öl für Sie / Ihr Tier zu bekommen.
					</p>
					<Button
						startIcon={ <Email /> }
						href="mailto:office@equinopathie.at"
					>
						Kontakt zu Gundula Lorenz
					</Button>
				</Grid>
			</Grid>
		</Box>
		<Box sx={{ marginY: '2rem' }}>
			<EqologyPage />
		</Box>
	</>
);

export { HairAnalysisInformationPage };
