import { useState } from 'react';
import {
	Box, Tab, Tabs, useMediaQuery, useTheme,
} from '@mui/material';
import { Divider } from 'components/Divider/Divider';
import {
	Biotech, Receipt, Science,
} from '@mui/icons-material';
import { ContentContainer } from 'templates/layout/ContentContainer';
import { TabPanel } from 'templates/components/Tabs';
import { SupplementPage } from 'pages/administration/SupplementPage';
import { ProductsPage } from 'pages/administration/ProductsPage';
import { InvoicePage } from 'pages/administration/InvoicePage';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { hasAccess } from 'helper/rightsManagement';

const AdministrationPage = () => {
	const [currentSettingsTab, setCurrentSettingsTab] = useState('supplements');
	const { user } = useAuthenticator((context) => [context.user]);

	const theme = useTheme();
	// const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Divider />
			<Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={ currentSettingsTab }
					onChange={ (event, newValue) => setCurrentSettingsTab(newValue) }
					indicatorColor="secondary"
					textColor="inherit"
					variant="fullWidth"
				>
					<Tab label={ !isMobile ? 'Supplemente' : '' } value="supplements" icon={ <Science /> } iconPosition="start" />
					<Tab
						label={ !isMobile ? 'Produkte' : '' }
						value="products"
						icon={ <Biotech /> }
						iconPosition="start"
						disabled={ !hasAccess(user?.attributes?.email, 'pharma') }
					/>
					<Tab
						label={ !isMobile ? 'Rechnungen' : '' }
						value="invoices"
						icon={ <Receipt /> }
						iconPosition="start"
						disabled={ !hasAccess(user?.attributes?.email, 'invoice') }
					/>
				</Tabs>
			</Box>
			<ContentContainer>
				<TabPanel value="supplements" index={ currentSettingsTab } lazyLoad>
					<SupplementPage />
				</TabPanel>
				<TabPanel value="products" index={ currentSettingsTab } lazyLoad>
					<ProductsPage />
				</TabPanel>
				<TabPanel value="invoices" index={ currentSettingsTab } lazyLoad>
					<InvoicePage />
				</TabPanel>
			</ContentContainer>
		</>
	);
};

export { AdministrationPage };
