/* eslint-disable function-paren-newline */
import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Box, Divider, Tab, Tabs, useMediaQuery, useTheme,
} from '@mui/material';
import { TabPanel } from 'templates/components/Tabs';
import { MedicationLiquid, Receipt, Spa } from '@mui/icons-material';
import { InvoiceFormular } from 'pages/invoice/InvoiceFormular';
import { LogFormular } from 'pages/log/LogFormular';
import { API, graphqlOperation } from 'aws-amplify';
import { getLog } from 'graphql/customQueries';
import { useMessage } from 'hooks/useMessage';
import { Exceptions } from 'messages/Exceptions';
import { Messages } from 'messages/Messages';
import _ from 'lodash';
import { createLog, updateLog } from 'graphql/customMutations';
import { deleteLog } from 'graphql/mutations';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { MicroNutrientAnalysisFormular } from 'pages/log/microNutrientAnalysis/MicroNutrientAnalysisFormular';
import { ILog, LogPageProps } from 'types/log';
import { AWSAppSyncProvider } from 'helper/bb-graphql-provider';
import { hasAccess } from 'helper/rightsManagement';

const LogPage: FC<LogPageProps> = ({
	id, customer, putLog, removeLog,
}) => {
	const [currentSettingsTab, setCurrentSettingsTab] = useState('log');
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [currentLog, setCurrentLog] = useState<ILog>();
	const { enqueueMessage } = useMessage();
	const { user } = useAuthenticator((context) => [context.user]);

	const { getItem, editItem } = AWSAppSyncProvider();

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	const handleSave = useCallback(async (newLog: ILog) => {
		if (isSaving) {
			return;
		}

		// replace empty values with nulls
		let prepareLog: Partial<ILog> = _.mapValues(_.pick(newLog, 'id', 'subject', 'customerLogsId', 'date', 'notice', 'anamnesis', 'logInvoiceId', 'logInvoiceNumber', 'logMicroNutrientAnalysisId'), (v) => {
			if (v) return v;
			return undefined;
		});
		prepareLog.closed = newLog.closed ?? false;
		prepareLog.images = newLog.images || undefined;

		if (prepareLog.id === '00000000-0000-0000-0000-000000000000') {
			prepareLog = _.omit(prepareLog, 'id');
		}

		if (!prepareLog.customerLogsId) {
			enqueueMessage(`LogPage${prepareLog.id}`, Exceptions.API_SAVE_ERROR);
			return;
		}

		setIsSaving(true);
		try {
			const log: ILog = await editItem(prepareLog.id ? updateLog : createLog, { input: prepareLog });
			setCurrentLog(log);
			putLog(log);
			enqueueMessage(`LogPage${prepareLog.id}`, Messages.API_SAVE_SUCCESSFUL);
		} catch (err) {
			enqueueMessage(`LogPage${prepareLog.id}`, Exceptions.API_SAVE_ERROR);
		} finally {
			setIsSaving(false);
		}
	}, [isSaving, createLog, updateLog, Messages.API_SAVE_SUCCESSFUL, Exceptions.API_SAVE_ERROR, enqueueMessage, setCurrentLog, setIsSaving]);

	const handleRemove = useCallback(async () => {
		if (isSaving || !currentLog?.id) return;

		setIsSaving(true);
		try {
			await API.graphql(graphqlOperation(deleteLog, { input: { id: currentLog.id } }));
			enqueueMessage(`LogPage${currentLog.id}`, Messages.API_DELETE_SUCCESSFUL);
			removeLog(currentLog.id);
		} catch (err) {
			enqueueMessage(`LogPage${currentLog.id}`, Exceptions.API_DELETE_ERROR);
		} finally {
			setIsSaving(false);
		}
	}, [currentLog?.id, isSaving, deleteLog, Messages.API_DELETE_SUCCESSFUL, Exceptions.API_DELETE_ERROR, removeLog, enqueueMessage, setIsSaving]);

	const load = useCallback(async () => {
		setIsLoading(true);
		try {
			setCurrentLog(await getItem(getLog, { id }));
		} catch (err) {
			enqueueMessage(`LogPage_${id}`, Exceptions.API_LOAD_ERROR);
			setCurrentLog(undefined);
		} finally {
			setIsLoading(false);
		}
	}, [id, getLog, Exceptions.API_LOAD_ERROR, setIsLoading, enqueueMessage]);

	/** @type {"disabled" | "error" | "success" | "warning" | "info" | "action" | "inherit" | "primary" | "secondary"} */
	const invoiceStateColor = useMemo(() => {
		if (!!currentLog?.invoice?.dueDate && new Date(currentLog?.invoice?.dueDate) < new Date() && !currentLog?.invoice?.paid) {
			return 'error';
		}
		if (!!currentLog?.invoice?.submittedAt && !currentLog?.invoice?.paid) {
			return 'warning';
		}
		if (currentLog?.invoice?.paid) {
			return 'success';
		}
		return 'inherit';
	}, [currentLog]);

	useEffect(() => {
		if (!customer.id) return;
		if (id !== '00000000-0000-0000-0000-000000000000') {
			load();
		} else {
			setCurrentLog({
				id: '00000000-0000-0000-0000-000000000000',
				customerLogsId: customer.id,
				date: new Date().toISOString(),
			});
		}
		setCurrentSettingsTab('log');
	}, [id, customer.id, setCurrentLog, load]);

	return (
		<>
			<Divider />
			<Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={ currentSettingsTab }
					onChange={ (event, newValue) => setCurrentSettingsTab(newValue) }
					indicatorColor="secondary"
					textColor="inherit"
					variant="fullWidth"
				>
					<Tab label={ isDesktop ? 'Anwendung' : '' } value="log" icon={ <Spa /> } iconPosition="start" />
					<Tab label={ isDesktop ? 'Mikronährstoffanalyse' : '' } value="microNutrientAnalysis" icon={ <MedicationLiquid /> } iconPosition="start" disabled={ currentLog?.id === '00000000-0000-0000-0000-000000000000' } />
					<Tab
						label={ isDesktop ? 'Rechnung' : '' }
						value="invoice"
						icon={ <Receipt color={ invoiceStateColor } /> }
						iconPosition="start"
						disabled={ currentLog?.id === '00000000-0000-0000-0000-000000000000' || !hasAccess(user?.attributes?.email, 'invoice') }
					/>
				</Tabs>
			</Box>
			<TabPanel value="log" index={ currentSettingsTab } lazyLoad>
				{ currentLog?.id && (
					<LogFormular
						customer={ customer }
						currentLog={ currentLog }
						saveLog={ handleSave }
						removeLog={ handleRemove }
						disabled={ isLoading || isSaving }
					/>
				) }
			</TabPanel>
			{ !!currentLog && (
				<>
					<TabPanel value="microNutrientAnalysis" index={ currentSettingsTab } lazyLoad>
						<MicroNutrientAnalysisFormular
							customer={ customer }
							currentLog={ currentLog }
							saveLog={ handleSave }
							disabled={ isLoading || isSaving }
						/>
					</TabPanel>
					<TabPanel value="invoice" index={ currentSettingsTab } lazyLoad>
						<InvoiceFormular
							customer={ customer }
							currentLog={ currentLog }
							saveLog={ handleSave }
							disabled={ isLoading || isSaving }
						/>
					</TabPanel>
				</>
			) }
		</>
	);
};

export { LogPage };
