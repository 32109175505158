import { createRoot } from 'react-dom/client';
import { Authenticator } from '@aws-amplify/ui-react';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from 'react-router-dom';
import { Suspense } from 'react';
import { TemplateProvider } from 'templates';
import { App } from 'App';
import { PublicAnamnesisPage } from 'pages/log/microNutrientAnalysis/PublicAnamnesisPage';
import { MessageProvider } from 'templates/layout/MessageProvider';
import { PublicCustomerPage } from 'pages/customer/PublicCustomerPage';
import { PublicPharmaOfferRequestPage } from 'pages/log/microNutrientAnalysis/PublicPharmaOfferRequestPage';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const router = createBrowserRouter([
	{
		path: '/anamnesis', // Kompatibilität
		element: <Navigate to="/public/customer?id=00000000-0000-0000-0000-000000000000&anamnesisRequest=1" replace />,
	},
	{
		path: '/public/anamnesis',
		element: <Suspense fallback={ <div>Loading...</div> }><TemplateProvider><PublicAnamnesisPage /></TemplateProvider></Suspense>,
	},
	{
		path: '/public/customer',
		element: <Suspense fallback={ <div>Loading...</div> }><TemplateProvider><PublicCustomerPage /></TemplateProvider></Suspense>,
	},
	{
		path: '/public/por/:mnsId',
		element: <Suspense fallback={ <div>Loading...</div> }><TemplateProvider><PublicPharmaOfferRequestPage /></TemplateProvider></Suspense>,
	},
	{
		path: '/*',
		element: <Suspense fallback={ <div>Loading...</div> }><TemplateProvider><App /></TemplateProvider></Suspense>,
	},
]);

root.render(
	<Authenticator.Provider>
		<MessageProvider>
			<RouterProvider router={ router } />
		</MessageProvider>
	</Authenticator.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
