import _ from 'lodash';

const allowedPharmaAdmins = [
	'katharina.schwartz@hotmail.com',
	'info@beyond-it-solutions.at',
	'test@beyond-it-solutions.at',
];

const allowedInvoiceAdmins = [
	'office@the-dogs.at',
	'info@beyond-it-solutions.at',
	'test@beyond-it-solutions.at',
];

const allowedAdmins = [
	'office@the-dogs.at',
	'katharina.schwartz@hotmail.com',
	'info@beyond-it-solutions.at',
	'test@beyond-it-solutions.at',
];

export const hasAccess = (email: string | undefined, area: 'administration' | 'invoice' | 'pharma') => {
	if (!email || !area) return false;
	switch (area) {
		case 'administration': return _.includes(allowedAdmins, email);
		case 'invoice': return _.includes(allowedInvoiceAdmins, email);
		case 'pharma': return _.includes(allowedPharmaAdmins, email);

		default: return false;
	}
};
