import {
	Box, Button, Fade, Modal as MuiModal,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

const Modal = ({
	setOpenModal, openModal, handleDelete, message, open,
}) => (
	<MuiModal
		open={ open }
		aria-labelledby="transition-modal-title"
		aria-describedby="transition-modal-description"
		onClose={ () => setOpenModal(false) }
		closeAfterTransition
		slotProps={{
			backdrop: {
				timeout: 500,
			},
		}}
	>
		<Fade in={ openModal } className="fade">
			<div>
				<h2>{ message }</h2>
				<Box style={{ display: 'flex', gap: '2rem' }}>
					<Button variant="contained" onClick={ handleDelete } startIcon={ <DeleteIcon /> }>Löschen</Button>
					<Button variant="contained" onClick={ () => setOpenModal(false) } startIcon={ <CancelIcon /> }>Abbrechen</Button>
				</Box>
			</div>
		</Fade>
	</MuiModal>
);

export { Modal };
