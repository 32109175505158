import React from 'react';
import { Box } from '@mui/material';
import { Header } from 'templates/layout/Header';
import { Outlet } from 'react-router-dom';

/**
 * The Wrapper for the LayoutContainer component(s).
 *
 *
 * @memberof Frontend.Theme
 * @param {object} props - props for the component.
 * @param {()=>void} props.signOut - Header component
 * @param {JSX.Element} [props.additionalHeaderComponent] - additional component to be rendered
 * @returns {React.ReactElement} The LayoutContainer component.
 */
const LayoutContainer = ({ signOut, additionalHeaderComponent, ...rest }) => (
	<Box style={{
		minHeight: '100vH', display: 'flex', flexDirection: 'column',
	}}
	>
		<Header signOut={ signOut } additionalHeaderComponent={ additionalHeaderComponent } />
		<Box
			component="main"
			style={{ flexGrow: 1 }}
			{ ...rest }
		>
			<Outlet />
		</Box>
	</Box>
);

export { LayoutContainer };
